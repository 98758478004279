import { LinkItem, ListItemWithCollapsible } from "components/common";
import { UrlCreator } from "library/url-creator";
import { action } from "mobx";
import { observer } from "mobx-react";
import { ICity, ICityProfileCount } from "models";
import { Component } from "react";
import * as React from "react";
import { Helmet } from "react-helmet";
import { mainStore } from "store";
import loader from "library/loader";
import { OverviewProfileCount } from "./OverviewProfileCount";

interface IOverviewPageProps {
  location?: { query: { search_term: string } };
  params?: { type: string };
}

@observer
class OverviewPage extends Component<IOverviewPageProps, any> {
  // tslint:disable-next-line:prefer-function-over-method
  componentDidMount() {
    const { count_data, loginStore } = mainStore;
    if (loginStore.is_logged_in) {
      if (!count_data) {
        mainStore.getCountData();
      }
      loader.stopProfileLoad();
      $(window).scrollTop(0);
    }
  }

  @action handleSpiderClick = (spider_id: number, page_type: string) => {
    mainStore.setSpiderId(spider_id, page_type);
    mainStore.isOpeningNewSite = true;
  };

  @action handleCityClick = (city: ICity) => {
    mainStore.setCityId(city);
    mainStore.isOpeningNewSite = true;
  };

  render() {
    const { misc_data } = mainStore;

    return (
      <div className="container outer">
        <div className="row">
          <div className="col-lg-offset-3 col-lg-6">
            <Helmet>
              <title>Callgirl index - Overblik</title>
            </Helmet>
            <h3 className="header">Countries and cities</h3>
            <div className="list-group">
              {misc_data &&
                misc_data.countries.map((country, countryIndex) => {
                  const cities = misc_data.cities.filter(city => city.country.id == country.id);

                  return (
                    <ListItemWithCollapsible key={countryIndex} listItemText={country.country_name}>
                      <div className="list-group" style={{ paddingLeft: 15, paddingRight: 15 }}>
                        {cities.map((city, cityIndex) => {
                          const cityLink = UrlCreator.getToCityLink(city);
                          return (
                            <LinkItem
                              key={cityIndex}
                              onClick={() => this.handleCityClick(city)}
                              to_link={`${cityLink}/like`}
                              className={"list-group-item"}
                            >
                              <OverviewProfileCount city_id={city.id} />
                              {city.city_name}
                            </LinkItem>
                          );
                        })}
                      </div>
                    </ListItemWithCollapsible>
                  );
                })}
              <LinkItem onClick={() => {}} to_link={`/twitter/`} className={"list-group-item"}>
                Twitter
              </LinkItem>
            </div>
            <h3 className="header">Sites</h3>

            <ul className="list-group">
              {misc_data &&
                misc_data.websites.map((website, index) => {
                  const websiteToLink = UrlCreator.getToWebsiteLink(website);
                  return (
                    <li className="list-group-item" style={{ padding: 0 }} key={index}>
                      <LinkItem
                        onClick={() => this.handleSpiderClick(website.id, "like")}
                        to_link={`${websiteToLink}/like`}
                        className={"list-group-item-element"}
                        style={{ width: "calc(100% - 100px)" }}
                      >
                        <OverviewProfileCount website_id={website.id} />
                        {website.spider_name}
                      </LinkItem>

                      <LinkItem
                        onClick={() => this.handleSpiderClick(website.id, "")}
                        to_link={`${websiteToLink}/`}
                        className={"list-group-item-element"}
                        style={{ width: 100, borderLeft: "1px solid #ddd" }}
                      >
                        All
                      </LinkItem>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default OverviewPage;
