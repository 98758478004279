import { observer } from "mobx-react";
import { Component } from "react";
import * as React from "react";
import { mainStore } from "store";
import { computed } from "mobx";

interface IButtonListItemProps {
  handleClick: (entity_id: number) => void;
  entity_id: number;
  isInView?: (element: HTMLElement) => boolean;
  scrollToActiveItem?: (topOffset: number) => void;
}

@observer
class ButtonListItem extends Component<IButtonListItemProps, any> {
  buttonElement: HTMLButtonElement;
  previousIsActive = false;

  handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const { entity_id } = this.props;
    this.props.handleClick(entity_id);
  };

  @computed get isActive() {
    const { twitterStore } = mainStore;
    const { twitter_video_store } = twitterStore;
    const { current_active_video } = twitter_video_store;
    const { entity_id } = this.props;
    return current_active_video && current_active_video.id == entity_id;
  }

  componentDidUpdate() {
    const { scrollToActiveItem, isInView } = this.props;
    if (this.isActive && isInView && !this.props.isInView(this.buttonElement) && !this.previousIsActive) {
      scrollToActiveItem(this.buttonElement.offsetTop);
    }

    this.setPreviousIsActive();

    if (!this.isActive && this.previousIsActive) {
      this.previousIsActive = false;
    }
  }

  setPreviousIsActive = () => {
    if (this.isActive && !this.previousIsActive) {
      this.previousIsActive = true;
    }
  };

  componentDidMount() {
    this.setPreviousIsActive();
  }

  render() {
    let className = "list-group-item";
    if (this.isActive) {
      className += " active";
    }
    return (
      <button ref={element => (this.buttonElement = element)} className={className} onClick={this.handleClick}>
        {this.props.children}
      </button>
    );
  }
}

export { ButtonListItem };
