import * as React from "react";

class TextOverflowWrapper extends React.Component {
  render() {
    const { children } = this.props;
    const child = React.Children.only(children) as any;
    const props = child.props;
    const childStyle = props ? props.style : {};
    const newStyle = Object.assign({}, childStyle, { whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" });
    return React.cloneElement(child, { style: newStyle });
  }
}

export { TextOverflowWrapper };
