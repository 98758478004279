import { observer } from "mobx-react";
import * as React from "react";
import { Component } from "react";
import { createPortal } from "react-dom";
import { Panel } from "components/common";

export type ModalPosition = "left" | "below-center";

interface IModalProps {
  buttonOffsetTop: number;
  buttonOffsetLeft: number;
  buttonHeight: number;
  buttonWidth: number;
  isOpen: boolean;
  position: ModalPosition;
  width: number;
  modalTopExtra: number;
}

@observer
class Modal extends Component<IModalProps> {
  body: HTMLBodyElement;
  portal: HTMLDivElement;

  constructor(props: any) {
    super(props);

    this.body = document.getElementsByTagName("body")[0];
  }

  // tslint:disable-next-line:prefer-function-over-method
  render() {
    const { buttonOffsetTop, buttonOffsetLeft, isOpen, position, buttonHeight, width, buttonWidth, modalTopExtra } = this.props;
    const displayStyle = isOpen ? { display: "block" } : { display: "none" };

    let top = buttonOffsetTop;
    let left = buttonOffsetLeft;
    let portalWidth = 0;

    if (this.portal) {
      portalWidth = $(this.portal).width();
    }

    if (position == "left") {
      top = top;
      left = left - portalWidth;
      if (left < 0) {
        left = buttonOffsetLeft + buttonWidth;
      }
    } else if (position == "below-center") {
      top = top + buttonHeight;
      left = left + buttonWidth / 2 - portalWidth / 2;
    }
    if (modalTopExtra) {
      top += modalTopExtra;
    }

    const modalStyle = Object.assign({}, displayStyle, {
      zIndex: 1000,
      position: "absolute",
      top,
      left
    });
    const panelStyle = { width };

    return createPortal(
      <div style={modalStyle} ref={element => (this.portal = element)}>
        <Panel>
          <div style={panelStyle}>{this.props.children}</div>
        </Panel>
      </div>,
      this.body
    );
  }
}

export { Modal };
