import { action } from "mobx";
import { Profile } from "models/Profile";
import { mainStore } from "store";
import * as Swiper from "swiper";

class ProfilePictureModalStore {
  modalSwiper?: Swiper;

  imageModal: HTMLDivElement;

  setModalSwiper: () => void;

  @action handleCloseModal = () => {
    $(this.imageModal).removeClass("in");
    $(".container-fluid.outer").removeClass("modal-active");
    $(".modal-backdrop.in").removeClass("active");
    const { profileStore } = mainStore;
    profileStore.setModalProfile();
    setTimeout(function() {
      $(".extra.modal-backdrop.in").removeClass("active");
    }, 300);
  };

  handleDoubleTab = () => {
    this.handleCloseModal();
  };

  // tslint:disable-next-line:no-unused
  handleKeyPress = (swiper: Swiper, keyCode: number) => {
    const escapeKeyCode = 27;
    if (keyCode === escapeKeyCode) {
      this.handleCloseModal();
    }
  };

  @action setModalProfile = (profile: Profile, pictureId: number) => {
    const outer = $(".container-fluid.outer");

    const clickedIndex = profile.profileData.filteredPictures.findIndex(item => item.id === pictureId);

    outer.addClass("modal-active");
    $(this.imageModal).addClass("in");
    const { profileStore } = mainStore;
    profileStore.setModalProfile(profile);
    this.setModalSwiper = () => {
      if (this.modalSwiper != undefined) {
        this.modalSwiper.destroy(true, true);
      }
      this.modalSwiper = new Swiper(".modal-body.swiper-container", {
        initialSlide: clickedIndex,
        loop: true,
        grabCursor: true,
        keyboardControl: true,
        pagination: ".swiper-pagination.label",
        paginationType: "fraction",
        onDoubleTap: this.handleDoubleTab,
        speed: 0,
        onKeyPress: this.handleKeyPress
      });
    };

    $(".modal-backdrop.in").toggleClass("active");
    $(".extra.modal-backdrop.in").toggleClass("active");
  };
}

export { ProfilePictureModalStore };
