import { LinkItem, Repeller } from "components/common";
import { Cluster } from "components/common/Cluster";
import { NumberFormatter } from "library/numeral";
import { debounce } from "lodash";
import { inject, observer } from "mobx-react";
import { ITwitterProfile } from "models/twitter";
import * as React from "react";
import { Component } from "react";
import { mainStore } from "store";
import { ModalStore } from "store/ModalStore";

import { AttributeLabel } from "./AttributeLabel";
import { ProfileIsMerged } from "./ProfileIsMerged";
import { ProfileIsSuspended } from "./ProfileIsSuspended";
import { getProfileTypeText } from "./TwitterProfileType";
import { ProfileHasMoreVideos } from "./ProfileHasMoreVideos";
import { MomentDate } from "library";

interface ITwitterProfileListProps {
  modalStore?: ModalStore;
}

interface ITwitterProfileListState {
  listTopOffset: number;
}

@inject((stores: { modalStore: ModalStore }, nextProps: ITwitterProfileListProps) => (nextProps.modalStore = stores.modalStore))
@observer
class TwitterProfileList extends Component<ITwitterProfileListProps, ITwitterProfileListState> {
  listContainer: HTMLDivElement;

  constructor(props: ITwitterProfileListProps) {
    super(props);
    this.state = {
      listTopOffset: 0
    };
  }

  componentDidMount() {
    const top = this.listContainer.getBoundingClientRect().top;
    this.setState({
      listTopOffset: top
    });

    this.listContainer.addEventListener("scroll", this.debouncedHandleScroll);
  }

  handleScroll = () => {
    const height = $(this.listContainer).height();
    const { scrollHeight, scrollTop } = this.listContainer;
    const maximumScrollPosition = scrollHeight - height;
    if (Math.max(maximumScrollPosition / 2, maximumScrollPosition - 1000) < scrollTop) {
      mainStore.twitterStore.twitter_profile_store.profilePageIndex += 1;
    }
  };

  debouncedHandleScroll = debounce(this.handleScroll, 250, { maxWait: 1000 });

  handleProfileItemClick = () => {
    const modalStore = this.props.modalStore;
    if (modalStore) {
      modalStore.handleCloseModal();
    }
    return;
  };

  // tslint:disable-next-line:prefer-function-over-method
  render() {
    const { twitterStore, misc_data } = mainStore;
    if (!misc_data) {
      return null;
    }

    const { profile_types } = misc_data;
    const { sortedProfiles } = twitterStore.twitter_profile_store;
    return (
      <div style={{ maxHeight: 520 }}>
        <div
          ref={element => (this.listContainer = element)}
          className="list-group"
          style={{ overflowY: "auto", height: 420, marginBottom: 0 }}
        >
          {sortedProfiles.items.map((profile: ITwitterProfile, index) => {
            const {
              name,
              screen_name,
              followers_count,
              video_count,
              watched_video_count,
              deleted_video_count,
              is_favorite,
              is_suspended,
              type,
              id,
              is_merged,
              location,
              last_activity,
              api_has_more_videos,
              twint_has_more_videos
            } = profile;
            const hasAtleastOneAttribute = is_favorite || is_suspended || is_merged || api_has_more_videos || twint_has_more_videos;
            const cutOff = 40;
            let name_text = name;
            if (cutOff < name.length) {
              name_text = name.slice(0, cutOff) + "...";
            }
            const name_text_split = name_text.split(" ");
            const name_text_split_normalized = name_text_split.map(text_split => {
              if (text_split.length > 0 && text_split == text_split.toUpperCase()) {
                return text_split[0].toUpperCase() + text_split.slice(1, 999999).toLowerCase();
              }
              return text_split;
            });
            name_text = name_text_split_normalized.join(" ");

            const profile_type_text = getProfileTypeText(type, profile_types);
            let last_activity_text = "";

            if (last_activity) {
              last_activity_text = MomentDate.getIsoDate(last_activity);
            }

            return (
              <LinkItem key={index} onClick={this.handleProfileItemClick} to_link={`/twitter/${id}`} className={"list-group-item"}>
                <Repeller>
                  <div>{name_text}</div>
                  {hasAtleastOneAttribute && (
                    <Cluster>
                      {is_favorite && (
                        <span>
                          <i className="fa fa-star fa-lg" />
                        </span>
                      )}
                      <ProfileIsMerged is_merged={is_merged} />
                      <ProfileIsSuspended is_suspended={is_suspended} />
                      <ProfileHasMoreVideos api_has_more_videos={api_has_more_videos} twint_has_more_videos={twint_has_more_videos} />
                    </Cluster>
                  )}
                </Repeller>

                <Repeller>
                  <div>
                    {last_activity_text && <span>{last_activity_text}</span>}
                    {profile_type_text && last_activity_text && <span>&nbsp;-&nbsp;</span>}
                    <span>{profile_type_text}</span>
                  </div>
                  <div>{location && <span>Location: {location}</span>}</div>
                </Repeller>
                <div className="row">
                  <div className="col-lg-12">
                    <Repeller>
                      <div>
                        <span>{`@${screen_name}`}</span>
                      </div>

                      <div>
                        <AttributeLabel
                          style={{ marginRight: 5 }}
                          text={`Followers: ${NumberFormatter.getPrettyNumberByTenThousand(followers_count)}`}
                        />

                        <AttributeLabel text={`Videos: ${watched_video_count} / ${video_count} / ${video_count + deleted_video_count}`} />
                      </div>
                    </Repeller>
                  </div>
                </div>
              </LinkItem>
            );
          })}
        </div>
      </div>
    );
  }
}

export { TwitterProfileList };
