import { observer } from "mobx-react";
import { ITwitterVideo } from "models/twitter";
import { Component } from "react";
import * as React from "react";
import { mainStore } from "store";

import { ButtonListItem } from "./ButtonListItem";
import { TwitterVideoListItemHeader } from "./TwitterVideoListItemHeader";
import { TwitterVideoListThumbnail } from "./TwitterVideoListThumbnail";

interface ITwitterVideoListItemsProps {
  isButtonInView: (element: HTMLElement) => boolean;
  scrollToActiveItem: (topOffset: number) => void;
}

@observer
class TwitterVideoListItems extends Component<ITwitterVideoListItemsProps> {
  render() {
    const { twitterStore } = mainStore;
    const { isButtonInView, scrollToActiveItem } = this.props;
    const { setActiveVideoFromId, hasVideos, twitter_video_store } = twitterStore;
    const { current_active_videos } = twitter_video_store;

    let active_videos_list = [];
    if (hasVideos) {
      active_videos_list = current_active_videos.items;
    }

    return (
      <>
        {active_videos_list.map((video: ITwitterVideo, index) => {
          const {
            id,
            is_upload_source_self,
            duration_miliseconds,
            tweet_id,
            favorite_count,
            media_id,
            found_with_initial,
            is_watched,
            profile
          } = video;

          const watched = is_watched;

          return (
            <ButtonListItem
              key={index}
              entity_id={video.id}
              handleClick={setActiveVideoFromId}
              isInView={isButtonInView}
              scrollToActiveItem={scrollToActiveItem}
            >
              <div>
                <input hidden={true} value={id} readOnly={true} />
                <TwitterVideoListItemHeader
                  found_with={found_with_initial}
                  favorite_count={favorite_count}
                  index={index}
                  duration_miliseconds={duration_miliseconds}
                  is_watched={watched}
                  is_upload_source_self={is_upload_source_self}
                />
                <TwitterVideoListThumbnail tweet_id={tweet_id} media_id={media_id} profile={profile} />
              </div>
            </ButtonListItem>
          );
        })}
      </>
    );
  }
}

export { TwitterVideoListItems };
