import { observer } from "mobx-react";
import * as React from "react";

interface IProfilePhoneNumberProps {
  phone_number: string;
  hasPhoneNumber: boolean;
  phoneNumberCountryCode: string;
}

@observer
class ProfilePhoneNumberButton extends React.Component<IProfilePhoneNumberProps, any> {
  render() {
    const { phone_number, hasPhoneNumber, phoneNumberCountryCode } = this.props;

    if (!hasPhoneNumber) {
      return null;
    }
    let phoneNumberText = phone_number;
    const isDanishNumber = phone_number.length == 8 || phone_number.startsWith("+45");
    if (isDanishNumber) {
      phoneNumberText = phone_number
        .slice(phone_number.length - 8, 99)
        .replace(/(.{2})/g, "$1 ")
        .trim();
    }

    let whatsappPhoneNumber = phone_number;
    if (!whatsappPhoneNumber.startsWith("+" + phoneNumberCountryCode) && !whatsappPhoneNumber.startsWith("00" + phoneNumberCountryCode)) {
      whatsappPhoneNumber = phoneNumberCountryCode + whatsappPhoneNumber;
    }
    whatsappPhoneNumber = whatsappPhoneNumber.replace(/[\( \)\-+]/g, "").replace(/^[0]+/g, "");

    return (
      <div className="btn-group">
        <button type="button" className="btn btn-info dropdown-toggle" data-toggle="dropdown">
          {phoneNumberText}&nbsp;
          <span className="caret" />
        </button>
        <ul className="dropdown-menu dropdown-menu-right">
          <li>
            <a href={"sms:+45" + phone_number}>Send en SMS</a>
          </li>
          <li role="separator" className="divider" />
          <li>
            <a href={"tel:+45" + phone_number}>Ring op</a>
          </li>
          <li role="separator" className="divider" />
          <li>
            <a href={"https://wa.me/" + whatsappPhoneNumber} target="_BLANK">
              WhatsApp
            </a>
          </li>
        </ul>
      </div>
    );
  }
}

export default ProfilePhoneNumberButton;
