import { observer } from "mobx-react";
import { Component } from "react";
import * as React from "react";
import { mainStore } from "store";

@observer
export default class TryOutButton extends Component<any, any> {
  tryOut = (event: React.SyntheticEvent<any>) => {
    event.preventDefault();

    const { loginStore, profileStore } = mainStore;
    loginStore.TryOut().then(isSuccessful => {
      if (isSuccessful) {
        profileStore.resetItems(true);
        window.scrollTo(0, 0);
        this.props.history.push("/profiles/new/");
      }
    });
  };

  render() {
    return (
      <button className="btn btn-primary btn-lg" onClick={this.tryOut}>
        Prøv af først
      </button>
    );
  }
}
