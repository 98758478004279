import { DarkGreenBadge, Cluster, OrderingButton, Repeller } from "components/common";
import { observer } from "mobx-react";
import { ITwitterProfile } from "models/twitter";
import { Component } from "react";
import * as React from "react";
import { mainStore } from "store";

interface ITwitterVideoListHeaderProps {
  active_profile: ITwitterProfile;
}

@observer
class TwitterVideoListHeader extends Component<ITwitterVideoListHeaderProps> {
  render() {
    const { active_profile } = this.props;
    const { twitterStore } = mainStore;
    const { toggleVideoIsUnratedFilter, toggleNewVideoFilter, twitter_video_store } = twitterStore;
    const { current_active_videos } = twitter_video_store;
    const { count } = current_active_videos;

    const {
      toggleDurationVideoFilter,
      sortVideoByDuration,
      newVideoFilterIsActive,
      hasOverviewVideos,
      hasNewVideos,
      hasUnratedVideos,
      videoIsUnratedFilter
    } = twitter_video_store;

    let label = `${count}`;
    if (active_profile && !hasOverviewVideos) {
      const { watched_video_count, video_count, deleted_video_count } = active_profile;
      label = `${watched_video_count} / ${video_count} / ${video_count + deleted_video_count}`;
    }

    return (
      <Repeller style={{ marginBottom: 5 }}>
        <div>
          <span>Videos</span>
          <DarkGreenBadge label={label} />
        </div>

        <Cluster size="small">
          <OrderingButton
            buttonSize="small"
            atleastOne={true}
            toggle={toggleDurationVideoFilter}
            isActive={sortVideoByDuration}
            label={"Duration"}
          />
          <OrderingButton
            buttonSize="small"
            atleastOne={hasNewVideos || newVideoFilterIsActive}
            toggle={toggleNewVideoFilter}
            isActive={newVideoFilterIsActive}
            label={"New"}
          />
          {hasOverviewVideos && (
            <OrderingButton
              atleastOne={hasUnratedVideos}
              buttonSize="small"
              toggle={toggleVideoIsUnratedFilter}
              isActive={videoIsUnratedFilter}
              label={"Unrated"}
            />
          )}
        </Cluster>
        {active_profile && <span style={{ display: "none" }} />}
      </Repeller>
    );
  }
}

export { TwitterVideoListHeader };
