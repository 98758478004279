import { action } from "mobx";

import FormStore from "./FormStore";
import { LoginStore } from "./LoginStore";
import { ValidatedInput } from "library";
import { ILoginFormData } from "models";

export default class LoginFormStore extends FormStore<ILoginFormData> {
  loginStore: LoginStore;
  password: ValidatedInput;
  username: ValidatedInput;

  constructor(loginStore: LoginStore) {
    super();
    this.loginStore = loginStore;
    this.username = new ValidatedInput("username", "Brugernavn");
    this.password = new ValidatedInput("password", "Kodeord");
    this.validatedInputs = [this.username, this.password];
  }

  @action
  resetState() {
    this.password.value = "";
    this.saving = false;
  }

  @action
  loginUser = () => {
    this.resetErrors();
    this.saving = true;

    this.validate(4);

    if (this.hasErrors) {
      this.resetState();
      return Promise.resolve(false);
    }

    return this.loginStore.Login(this.data).then((isSuccessful: boolean) => {
      this.resetState();
      return isSuccessful;
    });
  };
}
