import * as React from "react";
import { Component } from "react";

interface IOpenSearchButtonProps {
  cssClass: string;
  onClick: () => void;
}

class OpenSearchButton extends Component<IOpenSearchButtonProps, any> {
  handleClick = (event: React.SyntheticEvent<any>) => {
    event.preventDefault();
    this.props.onClick();
  };

  render() {
    return (
      <button type="submit" className={"search-btn btn-link " + this.props.cssClass} onClick={this.props.onClick}>
        <i className="fa fa-search" />
      </button>
    );
  }
}

export { OpenSearchButton };
