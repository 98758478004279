import { action, computed } from "mobx";

import ValidatedInput from "./ValidatedInput";

export default class ValidatedForm {
  validatedInputs: Array<ValidatedInput>;
  constructor(validatedInputs: Array<ValidatedInput>) {
    this.validatedInputs = validatedInputs;
  }

  validate = (minimumLength: number) => {
    this.validatedInputs.forEach(validatedInput => {
      validatedInput.validate(minimumLength);
    });
  };

  @computed get hasErrors() {
    let hasError = false;

    this.validatedInputs.forEach(validatedInput => {
      hasError = hasError && Boolean(validatedInput.error);
    });
    return hasError;
  }

  @action resetErrors() {
    this.validatedInputs.forEach(validatedInput => {
      validatedInput.error = "";
    });
  }

  data = () => {
    const postData = {};
    this.validatedInputs.forEach(validatedInput => {
      postData[validatedInput.name] = validatedInput.value;
    });
    return postData;
  };
}
