import { observer } from "mobx-react";
import { Component } from "react";
import * as React from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { mainStore } from "store";

import ProfileListRow from "./ProfileListRow";
import { ProfileListWithDateDivider } from "./ProfileListWithDateDivider";

@observer
class ProfileList extends Component {
  // tslint:disable-next-line:prefer-function-over-method
  render() {
    const { profileStore } = mainStore;
    const statuses = profileStore.current.statuses;
    const page_list_type = profileStore.profile_type;
    const shouldAddDateDivider = page_list_type == "comeback" || page_list_type == "updated";
    return (
      <div className="profile-list">
        <CSSTransitionGroup
          component="ul"
          className="list-group"
          transitionName="plr"
          transitionAppear={true}
          transitionAppearTimeout={500}
          transitionEnterTimeout={500}
          transitionLeaveTimeout={500}
        >
          {shouldAddDateDivider && <ProfileListWithDateDivider />}
          {!shouldAddDateDivider &&
            statuses.map(status => {
              if (!status.profile.profileData.hasPictures && !status.profile.profileData.shouldShowRemovedPictures) {
                return null;
              }
              return (
                <ProfileListRow key={status.id} status={status} page_list_type={page_list_type} profileData={status.profile.profileData} />
              );
            })}
        </CSSTransitionGroup>
      </div>
    );
  }
}

export default ProfileList;
