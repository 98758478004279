import { observer } from "mobx-react";
import * as React from "react";
import { Component } from "react";

interface IProfileIsSuspendedProps {
  is_suspended: boolean;
}

@observer
class ProfileIsSuspended extends Component<IProfileIsSuspendedProps> {
  // tslint:disable-next-line:prefer-function-over-method
  render() {
    const { is_suspended } = this.props;

    return (
      <>
        {is_suspended && (
          <span>
            <i className="fa fa-minus-circle fa-lg" />
          </span>
        )}
      </>
    );
  }
}

export { ProfileIsSuspended };
