import { App } from "components/App";
import * as React from "react";
import { Route, Switch } from "react-router";

const RouterSwitch = () => (
  <Switch>
    <Route path="/" component={App} />
  </Switch>
);

export { RouterSwitch };
