import { action, observable } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";

interface IListItemWithCollapsibleProps {
  listItemText: string;
  // onClick: () => void;
}

@observer
class ListItemWithCollapsible extends React.Component<IListItemWithCollapsibleProps, any> {
  @observable isOpen = false;

  @action handleClick = (event: React.SyntheticEvent<any>) => {
    event.preventDefault();
    this.isOpen = !this.isOpen;
  };

  render() {
    const { listItemText } = this.props;
    const { isOpen, handleClick } = this;

    const collapsibleStyle = isOpen ? undefined : { display: "none" };
    return (
      <>
        <a href="#" className="list-group-item" onClick={handleClick}>
          {listItemText}
        </a>
        <div className="panel-body" style={collapsibleStyle}>
          {this.props.children}
        </div>
      </>
    );
  }
}

export { ListItemWithCollapsible };
