import profileApi from "api/profileApi";
import { strings } from "library/localization";
import { UrlCreator } from "library/url-creator";
import { action, computed, observable, when } from "mobx";
import { ICity, ICountData, IMiscData } from "models";
import { ProfileStore } from "store/ProfileStore";

import { LoginStore } from "./LoginStore";
import { PictureSettingStore } from "./PictureSettingStore";
import { ProfilePictureModalStore } from "./ProfilePictureModalStore";
import { TwitterStore } from "./TwitterStore";

class MainStore {
  profileStore: ProfileStore;
  twitterStore: TwitterStore;
  loginStore: LoginStore;
  profilePictureModalStore: ProfilePictureModalStore;
  pictureSettingStore: PictureSettingStore;
  @observable static_prefix: string;
  @observable misc_data: IMiscData;
  @observable count_data: ICountData;
  @observable isOpeningNewSite = false;

  constructor() {
    this.twitterStore = new TwitterStore();
    this.profileStore = new ProfileStore();
    this.loginStore = new LoginStore();
    this.profilePictureModalStore = new ProfilePictureModalStore();
    this.pictureSettingStore = new PictureSettingStore();
    this.profileStore.spider_id = "1";
    this.static_prefix = "";
  }

  @action getMiscData = () => {
    return profileApi.getMiscData().then(this.setMiscData);
  };

  @action setMiscData = (response: any) => {
    this.misc_data = response.misc_data;
    this.profileStore.setWebsites(this.misc_data.websites);
  };

  @action getCountData = () => {
    return profileApi.getCountData().then(this.setCountData);
  };

  @action setCountData = (response: any) => {
    this.count_data = response.count_data;
  };

  @action setSpiderId = (spider_id: number, page_type?: string) => {
    this.profileStore.setSpiderId(spider_id.toString());
    if (page_type) {
      this.profileStore.resetItems(true);
    } else {
      this.profileStore.setAllStoreItems();
    }
    when(
      () => this.misc_data && this.misc_data.websites && this.misc_data.websites.length > 0,
      () => {
        const website = this.misc_data.websites.find(x => x.id == spider_id);
        strings.setLanguage(website.city.country.language_code);
      }
    );
  };

  @action setCityId = (city: ICity) => {
    this.profileStore.setCityId(city.id);
    this.profileStore.resetItems(true);
    strings.setLanguage(city.country.language_code);
  };

  setPrintMode = () => {
    this.profileStore.setPrintMode();
  };

  setStaticPrefix = () => {
    this.static_prefix = (document.getElementsByName("static")[0] as HTMLInputElement).value;
  };

  @computed get metaDescription() {
    if (this.loginStore.is_logged_in) {
      return "Find piger i København der tilbyder service i form af escort eller massage.";
    } else {
      return "IndexLabs";
    }
  }

  @computed get faviconHref() {
    if (this.loginStore.is_logged_in) {
      return this.static_prefix + "favicon.png?v=10";
    } else {
      return this.static_prefix + "white.png?v=1";
    }
  }

  @computed get brandName() {
    if (this.loginStore.is_logged_in) {
      return "Callgirl index";
    } else {
      return "Indexlabs";
    }
  }

  getWebsite = (spider_id: string) => {
    if (this.misc_data && this.misc_data.websites) {
      return this.misc_data.websites.find(x => x.id == Number(spider_id));
    } else {
      return undefined;
    }
  };

  @computed get toLink() {
    const { profileStore } = this;
    let to_link = "";
    if (profileStore.spider_id) {
      const website = this.getWebsite(profileStore.spider_id);
      to_link = UrlCreator.getToWebsiteLink(website, Number(profileStore.spider_id));
    } else if (profileStore.city_id) {
      const city = this.misc_data.cities.find(item => item.id == profileStore.city_id);
      to_link = UrlCreator.getToCityLink(city);
    }
    return to_link;
  }
}

const mainStore = new MainStore();

export { mainStore };
