import constants from "constants/constants";
import loader from "library/loader";
import { observer } from "mobx-react";
import * as React from "react";
import { mainStore } from "store";

import { LinkItem } from "./LinkItem";

interface IMenuItem {
  profile_type: string;
  onClick: () => void;
  to_link: string;
}

@observer
class MenuItem extends React.Component<IMenuItem, any> {
  render() {
    const { profile_type, onClick, to_link } = this.props;
    const link_text = constants.page_types_labels[profile_type];
    let badge_count = 0;
    const profileStore = mainStore.profileStore;

    const count = profileStore.count(profile_type);
    if (count) {
      badge_count = count;
    }

    const handleClick = () => {
      if (profile_type != profileStore.profile_type) {
        loader.startProfileLoad();
      }
      onClick();
    };

    return (
      <LinkItem to_link={to_link} onClick={handleClick}>
        <span className="text">{link_text}</span>
        &nbsp;
        <span className="badge">{badge_count}</span>
      </LinkItem>
    );
  }
}

export { MenuItem };
