import { Component } from "react";
import * as React from "react";
import { FeatureItem } from "components/common";
import { mainStore } from "store";

class Description extends Component {
  // tslint:disable-next-line:prefer-function-over-method
  render() {
    const { static_prefix } = mainStore;
    return (
      <section id="read_more" className="read-more">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 intro-text col-sm-12">
              <h2>Velkommen</h2>
            </div>

            <div className="col-lg-2 intro-mobile col-sm-3">
              <div className="about_us_thumb">
                Mobil enhed
              </div>
            </div>

            <div className="col-lg-6 col-sm-9">
              <div className="about_us_thumb">
                Desktop PC
              </div>
            </div>
          </div>

          <div className="features">
            <h2 id="features">Features</h2>

            <div className="row">
              <div className="col-sm-6 col-md-3">
                
              </div>
              <div className="col-sm-6 col-md-3">
                
              </div>
              <div className="col-sm-6 col-md-3">
                
              </div>
              <div className="col-sm-6 col-md-3">
                
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export { Description };
