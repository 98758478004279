import { hashIdManager } from "library/hashids";
import { UrlCreator } from "library/url-creator";
import { observer } from "mobx-react";
import { Status } from "models/Profile";
import * as React from "react";
import { NavLink } from "react-router-dom";
import { mainStore } from "store";

import { ProfileCollapseButton } from "./ProfileCollapseButton";
import ProfileType from "./ProfileType";

interface IProfileHeaderProps {
  status: Status;
  page_list_type: string;
  handleCollapseClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

@observer
class ProfileHeader extends React.Component<IProfileHeaderProps, any> {
  render() {
    const { status, page_list_type, handleCollapseClick } = this.props;
    const { loginStore, profileStore } = mainStore;
    const { hasExtraProfileTypeLabel } = profileStore;
    const innerProfile = status.profile;
    const guessed_type = status.guessed_type;
    const guessed_profile_type = status.profile.guessed_profile_type;

    const { is_favorite } = innerProfile;

    const website = mainStore.getWebsite(innerProfile.website_id.toString());
    const websiteProfileLink = UrlCreator.getToWebsiteProfileLink(website);
    const profileHash = hashIdManager.getHashFromProfileId(innerProfile.id);
    let profileLink = `${websiteProfileLink}/${profileHash}`;
    if (innerProfile.description_slug) {
      profileLink += `-${innerProfile.description_slug}`;
    }

    const protocol = website.is_https ? "https://" : "http://";
    const profile_url = website.profile_url.replace("{{profile_id_here}}", innerProfile.profile_url_id);

    return (
      <div className="profile-header" style={{ paddingTop: 3, paddingBottom: 7, display: "flex" }}>
        <div className="title" style={{ flexGrow: 1 }}>
          {loginStore.is_staff && (
            <div className="profile-link">
              <a href={protocol + website.base_url + profile_url} target="_blank">
                Link
              </a>
              &nbsp; | &nbsp;
            </div>
          )}

          <h5
            style={{
              WebkitBoxOrient: "vertical"
            }}
            title={innerProfile.title}
          >
            {is_favorite && (
              <div style={{ display: "inline-block", marginRight: 5 }}>
                <span>
                  <i className="fa fa-star fa-lg" />
                </span>
              </div>
            )}
            <NavLink to={profileLink}>{innerProfile.title}</NavLink>
          </h5>

          <ProfileType
            cssClass="title-type"
            page_list_type={page_list_type}
            profileType={status.type}
            is_staff={loginStore.is_staff}
            guessed_type={guessed_type}
            guessed_profile_type={guessed_profile_type}
            hasExtraProfileTypeLabel={hasExtraProfileTypeLabel}
          />
        </div>

        {hasExtraProfileTypeLabel && (
          <div className="extra-label">
            <ProfileType
              cssClass="label-type"
              page_list_type={status.type}
              profileType={status.type}
              hasExtraProfileTypeLabel={hasExtraProfileTypeLabel}
            />
          </div>
        )}

        <div style={{ display: "inline-block" }}>
          <ProfileCollapseButton handleCollapseClick={handleCollapseClick} />
        </div>
      </div>
    );
  }
}

export default ProfileHeader;
