import { DarkGreenBadge } from "components/common";
import { OrderingButton } from "components/common/OrderingButton";
import { NumberFormatter } from "library/numeral";
import { observer } from "mobx-react";
import { Component } from "react";
import * as React from "react";
import { colors } from "styles";
import { AttributeLabel } from "./AttributeLabel";

const getMinutesAndSeconds = (duration: number) => {
  const minuteInSeconds = 60;
  const video_minutes = Math.floor(duration / minuteInSeconds);
  const video_seconds = duration % minuteInSeconds;
  return { video_seconds, video_minutes };
};

const getVideoDurationText = (video_duration: number) => {
  const { video_seconds, video_minutes } = getMinutesAndSeconds(video_duration);

  return `${video_minutes}:${NumberFormatter.getNumberWithNoDecimals(video_seconds)}`;
};

interface ITwitterVideoListItemHeaderProps {
  duration_miliseconds: number;
  index: number;
  favorite_count: number;
  found_with: string;
  is_watched: boolean;
  is_upload_source_self: boolean;
}

@observer
class TwitterVideoListItemHeader extends Component<ITwitterVideoListItemHeaderProps> {
  render() {
    const { duration_miliseconds, index, favorite_count, found_with, is_watched, is_upload_source_self } = this.props;

    const duration = duration_miliseconds / 1000;
    const duration_text = getVideoDurationText(duration);
    return (
      <div style={{ marginBottom: 3 }}>
        <div style={{ display: "inline-block" }}>
          <span>{`Video #${index + 1}`} </span>
          <AttributeLabel
            text={found_with}
            style={{
              marginRight: 5,
              position: "relative",
              top: -1,
              padding: ".3em .6em .3em"
            }}
          />
          {!is_upload_source_self && (
            <AttributeLabel
              text={"Other"}
              style={{
                marginRight: 5,
                position: "relative",
                top: -1,
                padding: ".3em .6em .3em"
              }}
            />
          )}

          {is_watched && <DarkGreenBadge label="W" />}
        </div>
        <div style={{ float: "right", marginTop: -3 }}>
          <div
            className={"label label-default"}
            title="Favorites count"
            style={{ color: colors.TextBlack, marginRight: 5, paddingTop: ".4em", backgroundColor: OrderingButton.tagBaseColor }}
          >
            <i className="fa fa-star fa-lg" />
            {`: ${NumberFormatter.getPrettyNumberByTenThousand(favorite_count)}`}
          </div>
          <div
            className={"label label-default"}
            title="Video duration"
            style={{ color: colors.TextBlack, paddingTop: ".4em", backgroundColor: OrderingButton.tagBaseColor }}
          >
            <i className="fa fa-clock-o fa-lg" />
            {`: ${duration_text}`}
          </div>
        </div>
      </div>
    );
  }
}

export { TwitterVideoListItemHeader };
