import { action, computed, observable } from "mobx";
import { ValidatedInput } from "library";

export default class FormStore<TFormObject> {
  @observable saving = false;
  @observable validatedInputs: Array<ValidatedInput>;

  constructor() {
    this.validatedInputs = [];
  }

  @action
  validate(minimumLength: number) {
    this.validatedInputs.forEach(validatedInput => {
      validatedInput.validate(minimumLength);
    });
  }

  @action
  resetErrors() {
    this.validatedInputs.forEach(validatedInput => {
      validatedInput.error = "";
    });
  }

  @computed
  get hasErrors() {
    let hasError = false;
    this.validatedInputs.forEach(validatedInput => {
      hasError = hasError || Boolean(validatedInput.error);
    });

    return hasError;
  }

  @computed
  get data(): TFormObject {
    const postData: any = {};
    this.validatedInputs.forEach(validatedInput => {
      postData[validatedInput.name] = validatedInput.value;
    });
    return postData as TFormObject;
  }
}
