import { ToastrManager } from "components/common";
import { observer } from "mobx-react";
import { Component } from "react";
import * as React from "react";
import { mainStore } from "store";

@observer
export default class LogoutButtonSection extends Component {
  logoutUser = (event: React.SyntheticEvent<any>) => {
    event.preventDefault();
    const { loginStore } = mainStore;
    loginStore.Logout().then((isSuccessful: boolean) => {
      if (isSuccessful) {
        ToastrManager.success("Logud gennemført");
      } else {
        ToastrManager.error("Logout fejlede af ukendte årsager.");
      }
    });
  };

  render() {
    const { loginStore } = mainStore;
    const logged_in = loginStore.is_logged_in;
    return (
      <div className={logged_in ? "three" : ""}>
        <button className="btn btn-primary btn-lg" onClick={this.logoutUser}>
          Log ud
        </button>
      </div>
    );
  }
}
