import { Button } from "components/common";
import { observer } from "mobx-react";
import { DeleteReason } from "models/twitter";
import * as React from "react";

interface IVideoRemoveButtonButtonProps {
  buttonText: string;
  moveType: DeleteReason;
  is_saving: boolean;
  handleMoveClick: (moveType: DeleteReason) => void;
}

@observer
class VideoRemoveButton extends React.Component<IVideoRemoveButtonButtonProps> {
  moveVideo = () => {
    const { handleMoveClick, moveType } = this.props;
    handleMoveClick(moveType);
  };

  render() {
    const { buttonText, is_saving } = this.props;
    return (
      <Button className="btn btn-primary" handleClick={this.moveVideo} disabled={is_saving}>
        {buttonText}
      </Button>
    );
  }
}

export { VideoRemoveButton };
