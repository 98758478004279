import { DropdownWithAction, ToastrManager, Cluster } from "components/common";
import { observer } from "mobx-react";
import * as React from "react";
import { mainStore } from "store";
import { ITwitterProfile } from "models/twitter";
import { TextOverflowWrapper } from "./TextOverflowWrapper";
import { ProfileIsSuspended } from "./ProfileIsSuspended";
import { ProfileIsMerged } from "./ProfileIsMerged";

interface IProfileSetterComponentProps {
  video_id: number;
  pauseVideo: () => void;
}

@observer
class ProfileSetterComponent extends React.Component<IProfileSetterComponentProps> {
  setProfileOnVideo = (selectedProfile: ITwitterProfile) => {
    const { pauseVideo, video_id } = this.props;
    const { twitterStore } = mainStore;
    const { removeAndSetActiveVideo, twitter_profile_store } = twitterStore;
    const { active_profile } = twitter_profile_store;

    this.setState({ is_saving: true });
    pauseVideo();
    return twitterStore.twitter_profile_store.moveVideoToProfile(video_id, selectedProfile.id).then(() => {
      active_profile.video_count -= 1;

      const video = removeAndSetActiveVideo(video_id);

      if (video.is_watched) {
        selectedProfile.watched_video_count += 1;
      }
      selectedProfile.video_count += 1;
      ToastrManager.successRight("New profile set on the video");
    });
  };

  render() {
    const { video_id } = this.props;
    return (
      <DropdownWithAction
        templateAttributes={(profile: ITwitterProfile) => {
          const { is_suspended, screen_name, is_merged } = profile;

          return (
            <>
              <TextOverflowWrapper>
                <div
                  style={{
                    marginRight: 5,
                    display: "inline-block",
                    maxWidth: 145
                  }}
                  title={screen_name}
                >
                  {screen_name}
                </div>
              </TextOverflowWrapper>
              <div className="pull-right">
                <Cluster size={"small"}>
                  <ProfileIsSuspended is_suspended={is_suspended} />
                  <ProfileIsMerged is_merged={is_merged} />
                </Cluster>
              </div>
            </>
          );
        }}
        setOnVideoAction={this.setProfileOnVideo}
        buttonText={"Set profile"}
        entity_id={video_id}
      />
    );
  }
}

export { ProfileSetterComponent };
