import { observer } from "mobx-react";
import { ICityProfileCount } from "models";
import { Component } from "react";
import * as React from "react";
import { mainStore } from "store";

interface IOverviewProfileCountProps {
  city_id?: number;
  website_id?: number;
}

@observer
class OverviewProfileCount extends Component<IOverviewProfileCountProps, any> {
  // tslint:disable-next-line:prefer-function-over-method
  render() {
    const { count_data } = mainStore;
    const { city_id, website_id } = this.props;
    let profile_count: ICityProfileCount = undefined;
    if (count_data && city_id) {
      profile_count = count_data.cities[city_id];
    }
    if (count_data && website_id) {
      profile_count = count_data.websites[website_id];
    }
    if (!profile_count) {
      return null;
    }
    return (
      <div style={{ float: "right" }}>
        <span className="badge">
          <span style={{ marginRight: 5 }}>
            <i className="fa fa-star fa-lg" />
          </span>
          {profile_count.favorite_profile_count}
        </span>
        <span className="badge">{profile_count.profile_count}</span>
      </div>
    );
  }
}

export { OverviewProfileCount };
