import ScrollToAnchor from "components/common/ScrollToAnchor";
import { LoginForm } from "components/login/LoginForm";
import SignUpForm from "components/login/SignUpForm";
import { observer } from "mobx-react";
import * as React from "react";
import { Component } from "react";
import { mainStore } from "store";
import { Helmet } from "react-helmet";
import { Description, WelcomePane } from "./Subsection";
import { Panel } from "components/common";

@observer
export default class HomePage extends Component {
  constructor(props: any) {
    super(props);
    const { profileStore, loginStore } = mainStore;
    if (!profileStore.isLoaded && loginStore.is_logged_in) {
      // TODO: find way to load last viewed data
      // tslint:disable-next-line:no-commented-out-code
      // profileStore.resetItems();
    }
  }

  // tslint:disable-next-line:prefer-function-over-method
  componentDidMount() {
    window.scrollTo(0, 0);
    $(document).scroll(function() {
      if ($(document).scrollTop() > 500 && !$("#scrollUp").hasClass("active")) {
        $("#scrollUp").toggleClass("active");
      } else if ($(document).scrollTop() < 500 && $("#scrollUp").hasClass("active")) {
        $("#scrollUp").toggleClass("active");
      }
    });
  }

  // tslint:disable-next-line:prefer-function-over-method
  render() {
    const { loginStore } = mainStore;
    const logged_in = loginStore.is_logged_in;
    const pageTitleText = logged_in ? "Callgirl index - Find escort og massage i København" : "Indexlabs";
    return (
      <div className="front-page" id="top">
        <Helmet>
          <title>{pageTitleText}</title>
        </Helmet>
        {logged_in && <WelcomePane />}
        <ScrollToAnchor id="scrollUp" href="top">
          <i className="fa fa-long-arrow-up" />
        </ScrollToAnchor>

        {logged_in && <Description />}

        {!logged_in && (
          <div>
            <section id="login" className="full grey screen-page">
              <div className="container">
                <Panel>
                  <LoginForm />
                </Panel>
              </div>
            </section>
          </div>
        )}
        {logged_in && (
          <div>
            <section id="signup" className="full screen-page">
              <div className="container">
                <Panel>
                  <SignUpForm />
                </Panel>
              </div>
            </section>
          </div>
        )}
      </div>
    );
  }
}
