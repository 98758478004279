import { ToastrManager, Repeller } from "components/common";
import { observer } from "mobx-react";
import * as React from "react";
import { mainStore } from "store";

import { IsFollowedButton } from "./IsFollowedButton";

interface ITwitterProfileFollowedState {
  is_saving: boolean;
}

@observer
class TwitterProfileFollowed extends React.Component<any, ITwitterProfileFollowedState> {
  state = {
    is_saving: false
  };

  setIsFollowed = (is_followed: boolean) => {
    const { twitterStore } = mainStore;
    const { twitter_profile_store } = twitterStore;
    const { active_profile } = twitter_profile_store;
    const { id } = active_profile;

    this.setState({ is_saving: true });

    twitterStore.twitter_profile_store
      .setIsFollowed(id, is_followed)
      .then(() => {
        twitterStore.twitter_profile_store.updateProfileIsFollowed(id, is_followed);
        ToastrManager.successRight("Profile was updated");
      })
      .catch(errorData => {
        let message = errorData.Message;
        if (!errorData.Message) {
          message = errorData.message;
        }
        if (!message) {
          message = errorData;
        }
        ToastrManager.error(message);
      })
      .finally(() => {
        this.setState({ is_saving: false });
      });
  };

  render() {
    const { is_saving } = this.state;
    const { twitterStore } = mainStore;
    const { twitter_profile_store } = twitterStore;
    const { active_profile } = twitter_profile_store;
    if (!active_profile) {
      return null;
    }

    const current_is_followed = active_profile.is_followed;

    return (
      <Repeller style={{ marginBottom: 10 }}>
        <IsFollowedButton
          is_saving={is_saving}
          currently_is_followed={current_is_followed}
          buttonText="Mark as Followed"
          is_followed={true}
          handleIsFollowedClick={this.setIsFollowed}
        />
        <IsFollowedButton
          is_saving={is_saving}
          currently_is_followed={current_is_followed}
          buttonText="Remove Followed mark"
          is_followed={false}
          handleIsFollowedClick={this.setIsFollowed}
        />
      </Repeller>
    );
  }
}

export { TwitterProfileFollowed };
