import CommonRestApi from "./CommonRestApi";

class PictureApi extends CommonRestApi {
  constructor() {
    super("pictures");
  }

  excludePicture = (pictureId: number) => {
    return this.postUrl(`${this.list_action}${pictureId}/exclude/`);
  };
}

export { PictureApi };
