import { observer } from "mobx-react";
import * as React from "react";

interface IProfileCollapseButtonProps {
  handleCollapseClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

@observer
class ProfileCollapseButton extends React.Component<IProfileCollapseButtonProps, any> {
  static MiddleGreen = "#438181";

  render() {
    const { handleCollapseClick } = this.props;

    return (
      <button className={"btn btn-default btn-collapser full"} type="button" onClick={handleCollapseClick}>
        <span className="fa fa-chevron-down" style={{ color: ProfileCollapseButton.MiddleGreen }} />
      </button>
    );
  }
}

export { ProfileCollapseButton };
