import * as React from "react";

import constants from "constants/constants";

interface IProfileTypeProps {
  profileType: string;
  page_list_type: string;
  is_staff?: boolean;
  cssClass?: string;
  guessed_type?: string;
  guessed_profile_type?: string;
  hasExtraProfileTypeLabel: boolean;
}

class ProfileType extends React.Component<IProfileTypeProps, any> {
  render() {
    const { page_list_type, profileType, guessed_profile_type, guessed_type, is_staff, cssClass, hasExtraProfileTypeLabel } = this.props;

    const renderLabel = hasExtraProfileTypeLabel;
    if (!renderLabel) {
      return null;
    }

    let text = constants.page_types_labels[profileType];
    if (page_list_type === "new" && (guessed_type || guessed_profile_type) && is_staff) {
      const guessed_texts = [];

      if (guessed_profile_type) {
        guessed_texts.push(guessed_profile_type);
      }
      if (guessed_type) {
        guessed_texts.push(constants.page_types_labels[guessed_type]);
      }
      const guessed_text = guessed_texts.join(" / ");
      text = "Gættet: " + guessed_text;
    } else if (page_list_type === "new") {
      return null;
    }

    return (
      <div className={"label label-default " + cssClass} style={{ top: -2, position: "relative", backgroundColor: "#f2a1a1" }}>
        {text}
      </div>
    );
  }
}

export default ProfileType;
