import { OrderingButton } from "components/common/OrderingButton";
import { observer } from "mobx-react";
import * as React from "react";
import { mainStore } from "store";

interface IProfileOrderingTagsProps {
  atleastOneProfileHasVideo: boolean;
  atleastOneProfileDoesOutcall: boolean;
  atleastOneProfileWorksAfterMidnight: boolean;
  atleastOneProfileWhatsApp: boolean;
  atleastOneProfileBrazilian: boolean;
  atleastOneProfileIndependent: boolean;
  atleastOneProfileDoesAnal: boolean;
  atleastOneProfileHasReviews: boolean;
  atleastOneProfileHasViews: boolean;
  atleastOneProfileIsFavorite: boolean;
  atleastOneProfileHasAge: boolean;
}

@observer
class ProfileOrderingTags extends React.Component<IProfileOrderingTagsProps, any> {
  render() {
    const {
      atleastOneProfileHasVideo,
      atleastOneProfileDoesOutcall,
      atleastOneProfileWorksAfterMidnight,
      atleastOneProfileWhatsApp,
      atleastOneProfileBrazilian,
      atleastOneProfileIndependent,
      atleastOneProfileDoesAnal,
      atleastOneProfileHasReviews,
      atleastOneProfileHasViews,
      atleastOneProfileIsFavorite,
      atleastOneProfileHasAge
    } = this.props;
    if (
      !atleastOneProfileHasVideo &&
      !atleastOneProfileDoesOutcall &&
      !atleastOneProfileWorksAfterMidnight &&
      !atleastOneProfileWhatsApp &&
      !atleastOneProfileBrazilian &&
      !atleastOneProfileIndependent &&
      !atleastOneProfileDoesAnal &&
      !atleastOneProfileHasReviews &&
      !atleastOneProfileHasViews &&
      !atleastOneProfileIsFavorite &&
      !atleastOneProfileHasAge
    ) {
      return null;
    }
    const {
      has_video,
      does_outcall,
      works_after_midnight,
      has_whatsapp,
      is_brazilian,
      is_independent,
      does_anal,
      has_reviews,
      has_views,
      is_favorite,
      sort_by_age
    } = mainStore.profileStore.current;
    const {
      toggleOrderByHasVideo,
      toggleOrderByDoesOutcall,
      toggleOrderByWorksAfterMidnight,
      toggleOrderByWhatsApp,
      toggleOrderByBrazilian,
      toggleOrderByDoesAnal,
      toggleOrderByHasReviews,
      toggleOrderByIndependent,
      toggleOrderByHasViews,
      toggleOrderByIsFavorite,
      toggleOrderBySortByAge
    } = mainStore.profileStore.current;

    return (
      <div className="btn-group btn-group-sm ordering-tags" role="group" style={{ marginLeft: 10 }}>
        <OrderingButton label="Favorite" isActive={is_favorite} atleastOne={atleastOneProfileIsFavorite} toggle={toggleOrderByIsFavorite} />
        <OrderingButton label="Video" isActive={has_video} atleastOne={atleastOneProfileHasVideo} toggle={toggleOrderByHasVideo} />
        <OrderingButton
          label="Outcall"
          isActive={does_outcall}
          atleastOne={atleastOneProfileDoesOutcall}
          toggle={toggleOrderByDoesOutcall}
        />
        <OrderingButton
          label="After midnight"
          isActive={works_after_midnight}
          atleastOne={atleastOneProfileWorksAfterMidnight}
          toggle={toggleOrderByWorksAfterMidnight}
        />
        <OrderingButton label="WhatsApp" isActive={has_whatsapp} atleastOne={atleastOneProfileBrazilian} toggle={toggleOrderByWhatsApp} />
        <OrderingButton label="Brazilian" isActive={is_brazilian} atleastOne={atleastOneProfileWhatsApp} toggle={toggleOrderByBrazilian} />
        <OrderingButton
          label="Independent"
          isActive={is_independent}
          atleastOne={atleastOneProfileIndependent}
          toggle={toggleOrderByIndependent}
        />
        <OrderingButton label="Greek" isActive={does_anal} atleastOne={atleastOneProfileDoesAnal} toggle={toggleOrderByDoesAnal} />
        <OrderingButton label="Reviewed" isActive={has_reviews} atleastOne={atleastOneProfileHasReviews} toggle={toggleOrderByHasReviews} />
        <OrderingButton label="Views count" isActive={has_views} atleastOne={atleastOneProfileHasViews} toggle={toggleOrderByHasViews} />
        <OrderingButton label="Sort by age" isActive={sort_by_age} atleastOne={atleastOneProfileHasAge} toggle={toggleOrderBySortByAge} />
      </div>
    );
  }
}

export { ProfileOrderingTags };
