import LabeledInput from "./LabeledInput";
import * as React from "react";
import { ValidatedInput } from "library";

interface ITextInput {
  validatedInput: ValidatedInput;
}

class TextInput extends React.Component<ITextInput, any> {
  render() {
    const { validatedInput } = this.props;
    return <LabeledInput input_type="text" validatedInput={validatedInput} />;
  }
}

export default TextInput;
