import { observer } from "mobx-react";
import * as React from "react";
import { NavLink, withRouter, RouteComponentProps } from "react-router-dom";

interface ILinkItem {
  onClick: () => void;
  to_link: string;
  className?: string;
  style?: React.CSSProperties;
  history?: any;
}

@observer
class LinkItem extends React.Component<ILinkItem & RouteComponentProps<any>, any> {
  render() {
    const { onClick, to_link, className, style } = this.props;

    const handleClick = (event: React.SyntheticEvent<any>) => {
      event.preventDefault();

      onClick();
      setTimeout(() => {
        this.props.history.push(to_link);
      }, 100);
    };

    return (
      <NavLink to={to_link} onClick={handleClick} className={className} style={style}>
        {this.props.children}
      </NavLink>
    );
  }
}

const LinkItemWithRouter = withRouter<ILinkItem & RouteComponentProps<any>>(LinkItem);
export { LinkItemWithRouter as LinkItem };
