import { ToastrManager } from "components/common";
import loader from "library/loader";
import { observer } from "mobx-react";
import { Component } from "react";
import * as React from "react";
import { mainStore } from "store";

import ProfilePicture from "./ProfilePicture";
import ProfilePictureListPagination from "./ProfilePictureListPagination";
import { PictureManager } from "library/picture";

@observer
class ProfileModal extends Component<any, any> {
  // tslint:disable-next-line:prefer-function-over-method
  componentDidUpdate() {
    const { profileStore, profilePictureModalStore } = mainStore;
    const { modalProfile } = profileStore;
    const { setModalSwiper } = profilePictureModalStore;
    if (modalProfile) {
      setModalSwiper();
    }
  }

  // tslint:disable-next-line:prefer-function-over-method
  render() {
    const { profileStore, static_prefix, profilePictureModalStore } = mainStore;
    const { handleCloseModal } = profilePictureModalStore;
    const profile = profileStore.modalProfile;

    const offsetsMap: Map<number, number> = new Map();
    if (profileStore.modalProfile) {
      const website = mainStore.getWebsite(profile.website_id.toString());
      let total_offset = 0;
      profile.profileData.pictures.forEach(picture => {
        offsetsMap.set(picture.id, total_offset);
        total_offset += picture.width;
      });

      offsetsMap.forEach((offset, key) => {
        const adjustedValue = (offset / (total_offset - profile.profileData.pictures.find(item => item.id == key).width)) * 100;
        offsetsMap.set(key, Math.min(adjustedValue, 99.98));
      });

      const url =
        PictureManager.baseContentUrl(static_prefix) +
        website.site_name +
        "/" +
        profile.profile_id +
        "/sprite/full.jpg?v=" +
        profile.version;

      loader.startPageLoad();

      $("<img/>")
        .attr("src", url)
        .on("load", function(element: JQueryEventObject) {
          $(element).remove();
          $(".modal-body div.full-picture").css("background-image", `url(${url})`);
          loader.stopPageLoad();
        })
        .on("error", function() {
          ToastrManager.error("Kunne ikke hente billeder pga. ukendt fejl, eller manglende internet forbindelse.");
          loader.stopPageLoad();
        });
    }

    return (
      <div className="modal-body large swiper-container">
        <div className="swiper-wrapper">
          {profileStore.modalProfile &&
            profile.profileData.filteredPictures.map((picture, index) => {
              const offset = offsetsMap.get(picture.id);
              return (
                <ProfilePicture key={index} index={index} cssClass={"swiper-slide"} profile={profile} picture={picture} offset={offset} />
              );
            })}
        </div>

        <div className="close-modal" data-dismiss="modal" onClick={handleCloseModal}>
          <span className="fa fa-remove" />
        </div>
        <div className="close-modal backdrop" onClick={handleCloseModal} />

        <div className="swiper-pagination label label-default" style={{ backgroundColor: ProfilePictureListPagination.DarkGreen }} />
      </div>
    );
  }
}

export default ProfileModal;
