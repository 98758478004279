import { Repeller, ToastrManager } from "components/common";
import { observer } from "mobx-react";
import * as React from "react";
import { mainStore } from "store";

import { SetAttributeButton } from "./SetAttributeButton";

interface ITwitterVideoTwitterVideoIsCheckedState {
  is_saving: boolean;
}

@observer
class TwitterVideoIsChecked extends React.Component<any, ITwitterVideoTwitterVideoIsCheckedState> {
  state = {
    is_saving: false
  };

  setIsChecked = (checked_select: number) => {
    const { twitterStore } = mainStore;
    const { twitter_video_store } = twitterStore;
    const { current_active_video } = twitter_video_store;

    const { id } = current_active_video;

    this.setState({ is_saving: true });

    twitterStore.twitter_video_store
      .setVideoIsChecked(id, checked_select)
      .then(() => {
        ToastrManager.successRight("Video was updated");
      })
      .catch(errorData => {
        let message = errorData.Message;
        if (!errorData.Message) {
          message = errorData.message;
        }
        if (!message) {
          message = errorData;
        }
        ToastrManager.error(message);
      })
      .finally(() => {
        this.setState({ is_saving: false });
      });
  };

  render() {
    const { is_saving } = this.state;
    const { twitterStore, misc_data } = mainStore;
    const { twitter_video_store } = twitterStore;
    const { current_active_video } = twitter_video_store;

    if (!current_active_video) {
      return null;
    }

    const current_is_checked = false;

    const { checked_select } = current_active_video;
    const { checked_selects } = misc_data;
    return (
      <Repeller style={{ marginBottom: 10 }}>
        <SetAttributeButton
          is_saving={is_saving}
          current_attribute_value={current_is_checked}
          current_attribute_number={checked_select}
          buttonText="Check"
          button_attribute_value={true}
          button_attribute_number={checked_selects.checked}
          handleClick={() => {
            return;
          }}
          handleClickSelect={this.setIsChecked}
        />
        <SetAttributeButton
          is_saving={is_saving}
          current_attribute_value={current_is_checked}
          current_attribute_number={checked_select}
          buttonText="Uncheck"
          button_attribute_value={false}
          button_attribute_number={checked_selects.not_checked}
          handleClick={() => {
            return;
          }}
          handleClickSelect={this.setIsChecked}
        />
      </Repeller>
    );
  }
}

export { TwitterVideoIsChecked };
