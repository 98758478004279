const constants = {
  DELETE_FAILED: "DELETE_FAILED",
  page_types_labels: {
    like: "Synes godt om",
    new: "Nye",
    updated: "Opdaterede",
    comeback: "Tilbagekomne",
    maybe: "Måske",
    exclude: "Udelukkede",
    search: "Søgning"
  },
  profiles_types: {
    like: "like",
    new: "new",
    updated: "updated",
    comeback: "comeback",
    maybe: "maybe",
    exclude: "exclude",
    search: "search",
    thai: "thai",
    mature: "mature",
    improper: "improper"
  }
};

export default constants;
