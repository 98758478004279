import { observer } from "mobx-react";
import { Component } from "react";
import * as React from "react";
import { Helmet } from "react-helmet";
import { mainStore } from "store";
import { hashIdManager } from "library/hashids";
import { Profile } from "models/Profile";
import ProfilePictureList from "./ProfilePictureList";
import { ProfilePictureModal } from "./ProfilePictureModal";
import ProfileDisplay from "./ProfileDisplay";
import { match } from "react-router";
import profileApi from "api/profileApi";
import { IVideo } from "models";

interface ISingleProfilePageProps {
  match: match<{ spider_hash: string; profile_hash: string }>;
}

interface ISingleProfilePageState {
  profile?: Profile;
  videos: Array<IVideo>;
}

@observer
class SingleProfilePage extends Component<ISingleProfilePageProps, ISingleProfilePageState> {
  state: ISingleProfilePageState = {
    profile: undefined,
    videos: []
  };

  componentDidMount() {
    const { match: query_match } = this.props;
    const { profileStore } = mainStore;
    const profileId = hashIdManager.getProfileIdFromHash(query_match.params.profile_hash);
    profileStore.getProfile(profileId).then(profile => {
      if (profile) {
        this.setState({ profile });

        if (profile.has_video) {
          profileApi.getVideos(profile.id).then(videos => {
            this.setState({ videos });
          });
        }
      }
    });
    window.scrollTo(0, 0);
  }

  render() {
    const { profile, videos } = this.state;
    if (!profile) {
      return null;
    }

    const website = mainStore.getWebsite(profile.website_id.toString());
    return (
      <div className="container outer">
        <Helmet>
          <title>{profile.title}</title>
        </Helmet>
        <h3 className="header" style={{ marginBottom: 20 }}>
          {profile.title}
        </h3>
        <div className="profile">{profile.picture_relations.length !== 0 && <ProfilePictureList profile={profile} />}</div>

        <ProfileDisplay
          profileData={profile.profileData}
          videos={videos}
          spider_name={website.spider_name}
          phoneNumberCountryCode={website.city.country.phone_number_code}
        />
        <ProfilePictureModal />
      </div>
    );
  }
}

export { SingleProfilePage };
