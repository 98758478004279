import { observer } from "mobx-react";
import * as React from "react";
import { Component } from "react";

interface IProfileIsMergedProps {
  is_merged: boolean;
}

@observer
class ProfileIsMerged extends Component<IProfileIsMergedProps> {
  // tslint:disable-next-line:prefer-function-over-method
  render() {
    const { is_merged } = this.props;

    return (
      <>
        {is_merged && (
          <span style={{ lineHeight: 1.45 }} className="label label-default">
            M
          </span>
        )}
      </>
    );
  }
}

export { ProfileIsMerged };
