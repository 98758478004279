import * as _ from "lodash";
import { observer } from "mobx-react";
import * as React from "react";

interface ISearcherState {
  inputText: string;
}

interface ISearcherProps {
  handleChange: (value: string) => void;
  width?: number;
  searchText: string;
}

@observer
class Searcher extends React.Component<ISearcherProps, ISearcherState> {
  inputElement: HTMLInputElement;

  state: ISearcherState = {
    inputText: ""
  };

  handleChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
    event.preventDefault();
    const newInputText = (event.target as any).value;
    this.setState({ inputText: newInputText });

    this.debouncedHandleChange(newInputText);
  };

  componentDidMount() {
    this.setState({ inputText: this.props.searchText || "" });
  }

  debouncedHandleChange = _.debounce(this.props.handleChange, 250);

  render() {
    const { inputText } = this.state;

    const width = !this.props.width ? "inherit" : this.props.width;

    return (
      <input
        ref={element => (this.inputElement = element)}
        style={{ width }}
        type="text"
        className="form-control"
        value={inputText}
        onChange={this.handleChange}
      />
    );
  }
}

export { Searcher };
