import * as moment from "moment";

moment.locale("da");

class MomentDate {
  public static getDateAndTime = (date: string) => {
    return moment(date).format("YYYY-MM-DD HH:mm");
  };

  public static getDate = (date: string) => {
    return moment(date).format("DD-MM-YYYY");
  };

  public static getDateAndTimePretty = (date: string) => {
    return moment(date).format("D. MMMM YYYY, HH:mm");
  };

  public static getDatePretty = (date: string) => {
    return moment(date).format("D. MMMM YYYY");
  };

  public static getIsoDate = (date: string) => {
    return moment(date).format("YYYY-MM-DD");
  };

  public static isOlderThanMonths(date: string, months: number) {
    return moment().diff(date, 'months') >= months;
  }

}

export { MomentDate };
