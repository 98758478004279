import { observer } from "mobx-react";
import { Component } from "react";
import * as React from "react";
import { colors } from "styles";
import { OrderingButton } from "components/common/OrderingButton";

interface IAttributeLabelProps {
  text: string;
  count?: number | string;
  style?: React.CSSProperties;
}

@observer
class AttributeLabel extends Component<IAttributeLabelProps, any> {
  render() {
    const { text, count, style } = this.props;

    let label = text;
    if (count || count == 0) {
      label = `${text}: ${count}`;
    }
    const divStyle = Object.assign({}, style, { color: colors.TextBlack, backgroundColor: OrderingButton.tagBaseColor });
    return (
      <div className={"label label-default"} style={divStyle}>
        {label}
      </div>
    );
  }
}

export { AttributeLabel };
