import { observer } from "mobx-react";
import * as React from "react";
import { ProfileCollapseButton } from "./ProfileCollapseButton";

interface IMoveButtonsCollapseButtonProps {
  handleCollapseClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

@observer
class MoveButtonsCollapseButton extends React.Component<IMoveButtonsCollapseButtonProps, any> {
  render() {
    const { handleCollapseClick } = this.props;

    return (
      <button className={"btn btn-default move-button-collapse-button"} type="button" onClick={handleCollapseClick}>
        Flyt profil &nbsp;
        <span className="fa fa-chevron-down" style={{ color: ProfileCollapseButton.MiddleGreen }} />
      </button>
    );
  }
}

export { MoveButtonsCollapseButton };
