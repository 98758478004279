import { Button, Cluster } from "components/common";
import { NumberFormatter } from "library/numeral";
import { observer } from "mobx-react";
import { ITwitterProfile } from "models/twitter";
import * as React from "react";
import { Component } from "react";
import { mainStore } from "store";

import ProfilePictureListPagination from "../ProfilePictureListPagination";
import { ProfileSetterComponent } from "./ProfileSetterComponent";
import { TwitterVideoTopTags } from "./TwitterVideoTopTags";
import { Video } from "./Video";
import { VideoRemoveButtonsComponent } from "./VideoRemoveButtonsComponent";
import { VideoTypeButtonsComponent } from "./VideoTypeButtonsComponent";

interface ITwitterVideoProps {
  active_profile: ITwitterProfile;
}

const getMinutesAndSeconds = (duration: number) => {
  const minuteInSeconds = 60;
  const video_minutes = Math.floor(duration / minuteInSeconds);
  const video_seconds = duration % minuteInSeconds;
  return { video_seconds, video_minutes };
};

const getVideoDurationText = (video_position: number, video_duration: number) => {
  const { video_seconds, video_minutes } = getMinutesAndSeconds(video_duration);

  const position_durations = getMinutesAndSeconds(video_position);
  const position_seconds = position_durations.video_seconds;
  const position_minutes = position_durations.video_minutes;

  return `${position_minutes}:${NumberFormatter.getNumberWithNoDecimals(
    position_seconds
  )} / ${video_minutes}:${NumberFormatter.getNumberWithNoDecimals(video_seconds)}`;
};

interface ITwitterVideoState {
  video_duration: number;
  video_position: number;
  is_playing: boolean;
  has_error: boolean;
  previous_current_time: number;
  watched_time: number;
}

@observer
class TwitterVideo extends Component<ITwitterVideoProps, ITwitterVideoState> {
  videoComponent: Video;
  progressDiv: HTMLDivElement;
  progressCircle: HTMLSpanElement;
  containerDiv: HTMLDivElement;
  pagination: ProfilePictureListPagination;

  constructor(props: ITwitterVideoProps) {
    super(props);

    this.state = {
      video_duration: 0,
      video_position: 0,
      is_playing: false,
      has_error: false,
      previous_current_time: 0,
      watched_time: 0
    };
  }

  handleNextVideo = (event: React.SyntheticEvent<any>) => {
    event.preventDefault();
    this.setNextVideo();
  };

  isTargetAnInput = (event: KeyboardEvent) => {
    const target = event.target as HTMLElement;
    return target.tagName && target.tagName.toLowerCase() === "input";
  };

  handleKeyPressNextVideo = (event: KeyboardEvent) => {
    event.preventDefault();

    const rightArrowKeyCode = 39;
    if (event.which == rightArrowKeyCode && !this.isTargetAnInput(event)) {
      this.setNextVideo();
    }
  };

  handleKeyPressPreviousVideo = (event: KeyboardEvent) => {
    event.preventDefault();
    const leftArrowKeyCode = 37;
    if (event.which == leftArrowKeyCode && !this.isTargetAnInput(event)) {
      this.setPreviousVideo();
    }
  };

  handleKeyPressPausePlayVideo = (event: KeyboardEvent) => {
    event.preventDefault();
    const spaceKeyCode = 32;
    if (event.which == spaceKeyCode && !this.isTargetAnInput(event)) {
      this.handlePlayClick();
    }
  };

  setNextVideo = () => {
    const { twitterStore } = mainStore;
    const { twitter_video_store } = twitterStore;
    const { current_active_videos, activeVideoIndex } = twitter_video_store;
    let firstIndex = activeVideoIndex + 1;
    if (firstIndex === current_active_videos.count) {
      firstIndex = 0;
    }
    this.setVideoWithIndex(firstIndex);
  };

  handlePreviousVideo = (event: React.SyntheticEvent<any>) => {
    event.preventDefault();
    this.setPreviousVideo();
  };

  private setVideoWithIndex = (videoIndex: number) => {
    const { twitterStore } = mainStore;
    const { twitter_video_store } = twitterStore;
    const { current_active_videos } = twitter_video_store;
    const video = current_active_videos.items[videoIndex];
    twitterStore.setActiveVideo(video);
    this.setState({ has_error: false, video_duration: 0 });
  };

  setPreviousVideo = () => {
    const { twitterStore } = mainStore;
    const { twitter_video_store } = twitterStore;
    const { current_active_videos, activeVideoIndex } = twitter_video_store;
    let firstIndex = activeVideoIndex - 1;
    if (firstIndex < 0) {
      firstIndex = current_active_videos.count - 1;
    }
    this.setVideoWithIndex(firstIndex);
  };

  handleCanPlay = (event: Event) => {
    if (this.videoComponent && this.videoComponent.video) {
      this.setState({
        video_duration: this.videoComponent.video.duration,
        video_position: this.videoComponent.video.currentTime,
        has_error: false
      });
    }
  };

  handleTimeUpdate = (event: Event) => {
    if (this.videoComponent && this.videoComponent.video) {
      const previous_current_time = this.state.video_position;
      if (previous_current_time < 0.5) {
        this.setState({ watched_time: 0 });
      }
      this.setState({ video_position: this.videoComponent.video.currentTime, previous_current_time });
      if (previous_current_time > 0) {
        const watched_time_update = this.videoComponent.video.currentTime - previous_current_time;
        if (watched_time_update < 0.5 && previous_current_time < this.videoComponent.video.currentTime) {
          const new_watched_time = this.state.watched_time + watched_time_update;
          this.setState({ watched_time: new_watched_time });
          if (new_watched_time > 3) {
            const { twitterStore } = mainStore;
            const { twitter_video_store, setIsWatched } = twitterStore;
            const { current_active_video } = twitter_video_store;
            setIsWatched(current_active_video.id);
          }
        }
      }
    }
  };

  handlePlay = (event: Event) => {
    if (this.videoComponent && this.videoComponent.video) {
      this.setState({ video_position: this.videoComponent.video.currentTime, is_playing: true });
    }
  };

  handlePause = (event: Event) => {
    this.setState({ is_playing: false });
  };

  handleLinkClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    this.pauseVideo();
    this.setState({ is_playing: false });
  };

  handleEnded = (event: Event) => {
    this.setNextVideo();
  };

  handleError = (event: Event) => {
    this.setState({ has_error: true });
  };

  componentDidMount() {
    this.videoComponent.video.addEventListener("canplay", this.handleCanPlay);
    this.videoComponent.video.addEventListener("timeupdate", this.handleTimeUpdate);
    this.videoComponent.video.addEventListener("play", this.handlePlay);
    this.videoComponent.video.addEventListener("pause", this.handlePause);
    this.videoComponent.video.addEventListener("ended", this.handleEnded);
    this.videoComponent.video.addEventListener("error", this.handleError);

    document.addEventListener("keyup", this.handleKeyPressNextVideo);
    document.addEventListener("keyup", this.handleKeyPressPreviousVideo);
    document.addEventListener("keyup", this.handleKeyPressPausePlayVideo);
  }

  componentWillUnmount() {
    this.videoComponent.video.removeEventListener("canplay", this.handleCanPlay);
    this.videoComponent.video.removeEventListener("timeupdate", this.handleTimeUpdate);
    this.videoComponent.video.removeEventListener("play", this.handlePlay);
    this.videoComponent.video.removeEventListener("pause", this.handlePause);
    this.videoComponent.video.removeEventListener("ended", this.handleEnded);
    this.videoComponent.video.removeEventListener("error", this.handleError);
    document.removeEventListener("keyup", this.handleKeyPressNextVideo);
    document.removeEventListener("keyup", this.handleKeyPressPreviousVideo);
    document.removeEventListener("keyup", this.handleKeyPressPausePlayVideo);
  }

  handlePlayClick = () => {
    const { is_playing } = this.state;
    if (is_playing) {
      this.pauseVideo();
    } else {
      this.videoComponent.video.play();
    }
  };

  handleVideoContainerClick = (event: React.SyntheticEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (this.pagination && this.pagination.containerDiv && !this.pagination.containerDiv.contains(event.target as HTMLElement)) {
      this.handlePlayClick();
    }
  };

  pauseVideo = () => {
    this.videoComponent.video.pause();
  };

  handleProgressBarClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault();
    const { video_duration } = this.state;

    if (this.progressCircle.contains(event.target as HTMLElement)) {
      return;
    }
    const offset = $(event.target).offset();
    const width = $(this.progressDiv).outerWidth();
    const { pageX } = event;

    const percent = (pageX - offset.left) / width;
    const new_time = percent * video_duration;
    this.videoComponent.video.currentTime = new_time;
  };

  render() {
    const { active_profile } = this.props;
    const { video_duration, video_position, is_playing, has_error } = this.state;
    const { twitterStore } = mainStore;
    const { twitter_video_store } = twitterStore;
    const { current_active_video, current_active_videos, activeVideoIndex } = twitter_video_store;

    const addButtons = current_active_videos.count > 1;
    const backdropStyle = {
      position: "absolute",
      top: -132,
      width: 40,
      height: 40,
      background: "#D0E3E3",
      zIndex: 5,
      opacity: 0.7,
      borderRadius: 30
    };

    const backdropRightStyle = Object.assign({}, backdropStyle, { left: "calc(100% - 36px)" });

    const backdropLeftStyle = Object.assign({}, backdropStyle, { left: "calc(0% - 4px)" });
    const cameraIconStyle = Object.assign({}, backdropStyle, { left: "calc(50% - 60px)", background: "none", top: -275 });

    const video = current_active_video;
    const video_id = video ? video.id : 0;
    const tweet_id = video ? video.tweet_id : 0;
    const video_type = video ? video.video_type : 0;

    let video_progress_percent = 0;
    if (video_duration > 0) {
      video_progress_percent = video_position / video_duration;
    }

    const playPauseIconClass = is_playing ? "fa-pause" : "fa-play";
    return (
      <div ref={element => (this.containerDiv = element)} tabIndex={-1} style={{ outline: "none" }}>
        <TwitterVideoTopTags handleLinkClick={this.handleLinkClick} />

        <div
          onClick={this.handleVideoContainerClick}
          className="slider-container"
          style={{
            position: "relative",
            cursor: "pointer",
            height: 420,
            marginTop: 10,
            width: "100%",
            backgroundColor: "#000",
            borderRadius: 4
          }}
        >
          <div className="swiper-wrapper " style={{ width: "max-content", margin: "auto", maxWidth: "100%" }}>
            <div className="swiper-slide" style={{ width: "calc(100% - 8px)", margin: "auto" }}>
              <Video ref={element => (this.videoComponent = element)} handleClick={this.handlePlayClick} />
            </div>
          </div>

          <ProfilePictureListPagination
            ref={element => (this.pagination = element)}
            index={activeVideoIndex}
            totalCount={current_active_videos.count}
            style={{ bottom: -60, cursor: "default" }}
          />
          {addButtons && (
            <div className="swiper-button-group">
              <div className={" swiper-custom-button-next"} onClick={this.handleNextVideo}>
                <i className="fa fa-chevron-right fa-2x" />
              </div>
              <div className="swiper-custom-button-next-backdrop" style={backdropRightStyle} />
              <div className={" swiper-custom-button-prev"} onClick={this.handlePreviousVideo}>
                <i className="fa fa-chevron-left fa-2x" />
              </div>
              <div className="swiper-custom-button-prev-backdrop" style={backdropLeftStyle} />
              {has_error && (
                <div style={cameraIconStyle}>
                  <span className="fa-stack fa-5x">
                    <i className="fa fa-video-camera fa-stack-1x fa-inverse" />
                    <i className="fa fa-ban fa-stack-2x text-danger" />
                  </span>
                </div>
              )}
            </div>
          )}
        </div>
        <div style={{ display: "flex", marginBottom: 15 }}>
          <div style={{ flexGrow: 1 }}>
            <Button buttonType="default" buttonSize="small" handleClick={this.handlePlayClick}>
              <i className={"fa " + playPauseIconClass} aria-hidden="true" />
            </Button>
          </div>

          <div
            ref={element => (this.progressDiv = element)}
            style={{ flexGrow: 8, paddingTop: 13, cursor: "pointer" }}
            onClick={this.handleProgressBarClick}
          >
            <div className="progress" style={{ height: 7, marginBottom: 0 }}>
              <div className="progress-bar" role="progressbar" style={{ width: `${video_progress_percent * 100}%`, transition: "none" }} />
            </div>
            <div style={{ width: `${video_progress_percent * 100}%` }}>
              <span style={{ position: "relative", float: "right" }}>
                <span ref={element => (this.progressCircle = element)} style={{ position: "absolute", right: -10, top: -14 }}>
                  <i className="fa fa-circle fa-lg alert-danger" style={{ background: "transparent" }} />
                </span>
              </span>
            </div>
          </div>

          <div style={{ flexGrow: 1, marginLeft: 15, marginTop: 7 }}>{getVideoDurationText(video_position, video_duration)}</div>
        </div>

        <div className="row">
          <div className="col-lg-6">
            <Cluster style={{ marginBottom: 15 }} size="small" alignment="center">
              <VideoRemoveButtonsComponent video_id={video_id} pauseVideo={this.pauseVideo} />
              <div>
                <a
                  href={`https://twitter.com/${active_profile.screen_name}/status/${tweet_id}`}
                  onClick={this.handleLinkClick}
                  target="_blank"
                >
                  Link <i className="fa fa-external-link" />
                </a>
              </div>
            </Cluster>
          </div>
          <div className="col-lg-6">
            <div style={{ width: 300, marginBottom: 15, float: "right" }}>
              <ProfileSetterComponent video_id={video_id} pauseVideo={this.pauseVideo} />
            </div>
          </div>
        </div>

        <VideoTypeButtonsComponent
          pauseVideo={this.pauseVideo}
          setNextVideo={this.setNextVideo}
          video_id={video_id}
          video_type={video_type}
        />
      </div>
    );
  }
}

export { TwitterVideo };
