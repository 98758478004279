import { observer } from "mobx-react";
import { Profile } from "models/Profile";
import { Component } from "react";
import * as React from "react";
import { mainStore } from "store";

import { ProfileAttributeList } from "./ProfileAttributeList";
import { ProfileCoreProfileAttribute } from "./ProfileCoreProfileAttribute";
import { ProfileMissingPictureLabels } from "./ProfileMissingPictureLabels";
import ProfilePicture from "./ProfilePicture";
import ProfilePictureListPagination from "./ProfilePictureListPagination";

interface IProfilePictureListProps {
  profile: Profile;
}

@observer
class ProfilePictureList extends Component<IProfilePictureListProps, any> {
  constructor(props: IProfilePictureListProps, context: any) {
    super(props, context);

    const ordering = {};
    this.props.profile.profileData.filteredPictures.forEach((item, index) => {
      ordering[item.id] = index;
    });

    this.state = {
      swipeClass: "profile-swipe-" + this.props.profile.id,
      firstIndex: 0,
      ordering: ordering
    };
  }

  handleDoubleTab = (event: React.SyntheticEvent<any>) => {
    const $clickedElement = $(event.target);

    const picture = $clickedElement.closest(".img-container")[0];
    if (picture) {
      const clickedSwipe = $clickedElement.closest(".swiper-slide").data("swiper-slide-index");
      const { profilePictureModalStore } = mainStore;
      profilePictureModalStore.setModalProfile(this.props.profile, clickedSwipe);
    }
  };

  handleNextPicture = (event: React.SyntheticEvent<any>) => {
    event.preventDefault();
    let firstIndex = this.state.firstIndex + 1;
    if (firstIndex === this.props.profile.profileData.filteredPictures.length) {
      firstIndex = 0;
    }
    this.setState({ firstIndex: firstIndex });
  };

  handlePreviousPicture = (event: React.SyntheticEvent<any>) => {
    event.preventDefault();
    let firstIndex = this.state.firstIndex - 1;
    if (firstIndex < 0) {
      firstIndex = this.props.profile.profileData.filteredPictures.length - 1;
    }
    this.setState({ firstIndex: firstIndex });
  };

  handleOpenRemovedPicturesClick = (event: React.SyntheticEvent<any>) => {
    event.preventDefault();
    const profile = this.props.profile;
    const { profileData } = profile;
    profileData.shouldShowRemovedPictures = !profileData.shouldShowRemovedPictures;
    this.setState({ firstIndex: 0 });
  };

  render() {
    const profile = this.props.profile;

    const addButtons = profile.profileData.filteredPictures.length > 1;
    const orderedPictureList = [
      ...profile.profileData.filteredPictures.slice(this.state.firstIndex, 99),
      ...profile.profileData.filteredPictures.slice(0, this.state.firstIndex)
    ];

    const { profilePictureModalStore } = mainStore;
    const backdropStyle = {
      position: "absolute",
      top: -132,
      width: 40,
      height: 40,
      background: "#D0E3E3",
      zIndex: 5,
      opacity: 0.7,
      borderRadius: 30
    };

    const backdropRightStyle = Object.assign({}, backdropStyle, { left: "calc(100% - 36px)" });

    const backdropLeftStyle = Object.assign({}, backdropStyle, { left: "calc(0% - 4px)" });

    return (
      <div>
        <ProfileCoreProfileAttribute profile={profile} />

        <ProfileMissingPictureLabels profile={profile} />

        <div className="slider-container">
          <div className={"profile-images " + this.state.swipeClass}>
            <div className="swiper-wrapper ">
              {orderedPictureList.map((picture, index) => (
                <ProfilePicture
                  key={index}
                  index={this.state.ordering[picture.id]}
                  cssClass={"swiper-slide"}
                  profile={profile}
                  picture={picture}
                  isThumb={true}
                  setModalProfile={profilePictureModalStore.setModalProfile}
                />
              ))}
            </div>
          </div>

          <ProfilePictureListPagination index={this.state.firstIndex} totalCount={profile.profileData.filteredPictures.length} />
          {addButtons && (
            <div className="swiper-button-group">
              <div className={" swiper-custom-button-next"} onClick={this.handleNextPicture}>
                <i className="fa fa-chevron-right fa-2x" />
              </div>
              <div className="swiper-custom-button-next-backdrop" style={backdropRightStyle} />
              <div className={" swiper-custom-button-prev"} onClick={this.handlePreviousPicture}>
                <i className="fa fa-chevron-left fa-2x" />
              </div>
              <div className="swiper-custom-button-prev-backdrop" style={backdropLeftStyle} />
            </div>
          )}
        </div>

        <ProfileAttributeList profile={profile} />
      </div>
    );
  }
}

export default ProfilePictureList;
