import { observer } from "mobx-react";
import { Component } from "react";
import * as React from "react";

interface ICheckboxProps {
  labelText: string;
  handleClick: () => void;
  value: boolean;

  isSaving: boolean;
}

@observer
class Checkbox extends Component<ICheckboxProps, any> {
  MyHandleClick = (event: React.SyntheticEvent<HTMLInputElement>) => {
    event.preventDefault();

    this.props.handleClick();
  };

  render() {
    const { labelText, value, isSaving } = this.props;

    let outerDivClass = "checkbox";
    if (isSaving) {
      outerDivClass += " disabled";
    }

    return (
      <div className={outerDivClass}>
        <label>
          <input type="checkbox" onChange={this.MyHandleClick} checked={value} disabled={isSaving} /> {labelText}
        </label>
      </div>
    );
  }
}

export { Checkbox };
