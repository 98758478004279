import { action, observable } from "mobx";

export default class ValidatedInput {
  @observable error = "";
  @observable value = "";

  constructor(public name: string, public label: string) {}

  validate = (minimumLength: number) => {
    if (this.value.length < minimumLength) {
      this.error = this.label + " skal have mindst " + minimumLength + " tegn.";
    }
  };

  @action onChange = (event: React.SyntheticEvent<any>) => {
    this.value = (event.target as HTMLInputElement).value;
  };
}
