import { IWebsite, ICity } from "models";
import { hashIdManager } from "library/hashids";
import { routeNames } from "router/routes";

class UrlCreator {
  static getBasicToLink = (websiteId: number) => {
    const websiteIdHash = hashIdManager.getHashFromSiteId(websiteId);
    let baseRoute = routeNames.danishProfileList;
    if (websiteId > 2) {
      baseRoute = routeNames.spanishProfileList;
    }
    return `/${baseRoute}/${websiteIdHash}`;
  };

  static getToWebsiteLink = (website?: IWebsite, websiteId?: number) => {
    if (website) {
      let toLink = UrlCreator.getBasicToLink(website.id);
      const slug = website.description_slug;
      if (slug) {
        toLink += `-${slug}`;
      }
      return toLink;
    } else if (websiteId) {
      return UrlCreator.getBasicToLink(websiteId);
    }
    return "";
  };

  static getCityFromLink = (cities: Array<ICity>, spider_hash: string) => {
    return cities.find(item => item.city_name.toLowerCase() == spider_hash.toLowerCase().replace(/-/gi, " "));
  };

  static getToCityLink = (city: ICity) => {
    return `/profiler/${city.city_name.toLowerCase().replace(/ /gi, "-")}`;
  };

  static getToWebsiteProfileLink = (website: IWebsite) => {
    const websiteIdHash = hashIdManager.getHashFromSiteId(website.id);
    const slug = website.description_slug;
    let baseRoute = routeNames.danishSingleProfile;
    if (website.id > 2) {
      baseRoute = routeNames.spanishSingleProfile;
    }
    let toLink = `/${baseRoute}/${websiteIdHash}`;
    if (slug) {
      toLink += `-${slug}`;
    }
    return toLink;
  };
}

export { UrlCreator };
