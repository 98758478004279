import { observer } from "mobx-react";
import * as React from "react";
import { Component } from "react";
import { ButtonSize } from "./ButtonSize";

type ButtonType = "default";

interface IButtonProps {
  handleClick: () => void;
  buttonType?: ButtonType;
  buttonSize?: ButtonSize;
  style?: React.CSSProperties;
  disabled?: boolean;
  className?: string;
  badge?: string | number | JSX.Element;
}

@observer
class Button extends Component<IButtonProps, any> {
  button: HTMLButtonElement;

  handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const { handleClick } = this.props;
    handleClick();
  };

  render() {
    const buttonType = this.props.buttonType || "default";
    const buttonSize = this.props.buttonSize || "";

    const { style, disabled, className, badge } = this.props;

    let btnClass = "btn";

    if (buttonType == "default") {
      btnClass += " btn-default";
    }

    if (buttonSize) {
      if (buttonSize == "small") {
        btnClass += " btn-sm";
      }
    }
    if (className) {
      btnClass += " " + className;
    }

    const isBadgeReactElement = React.isValidElement(badge);

    return (
      <button
        ref={element => (this.button = element)}
        type="button"
        className={btnClass}
        style={style}
        onClick={this.handleClick}
        disabled={disabled}
      >
        {this.props.children}
        &nbsp;
        {badge && !isBadgeReactElement && <span className="badge">{badge}</span>}
        {isBadgeReactElement && badge}
      </button>
    );
  }
}

export { Button };
