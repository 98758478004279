import { Component } from "react";
import * as React from "react";

interface IRepellerProps {
  style?: React.CSSProperties;
}

class Repeller extends Component<IRepellerProps> {
  render() {
    const style = Object.assign({}, this.props.style, { display: "flex", justifyContent: "space-between", alignItems: "center" });
    return <div style={style}>{this.props.children}</div>;
  }
}

export { Repeller };
