import { Button } from "components/common";
import { observer } from "mobx-react";
import * as React from "react";

interface IVideoTypeButtonProps {
  buttonText: string;
  handleVideoTypeClick: (video_type: number) => void;
  is_saving: boolean;
  video_type: number;
  set_as_video_type: number;
}

@observer
class VideoTypeButton extends React.Component<IVideoTypeButtonProps> {
  handleClick = () => {
    const { handleVideoTypeClick, set_as_video_type } = this.props;
    handleVideoTypeClick(set_as_video_type);
  };

  render() {
    const { buttonText, is_saving, video_type, set_as_video_type } = this.props;
    const disabled = video_type == set_as_video_type;
    return (
      <Button className="btn btn-primary" handleClick={this.handleClick} disabled={is_saving || disabled}>
        {buttonText}
      </Button>
    );
  }
}

export { VideoTypeButton };
