import { observer } from "mobx-react";
import { Component } from "react";
import * as React from "react";

import ProfilePictureListPagination from "./ProfilePictureListPagination";

interface IProfileAttributeLabelProps {
  is_visible: boolean;
  labelText: string;
  title?: string;
  top?: number;
}

@observer
class ProfileLabel extends Component<IProfileAttributeLabelProps, any> {
  render() {
    const { is_visible, labelText, title } = this.props;

    if (!is_visible) {
      return null;
    }

    const labelStyle: React.CSSProperties = { backgroundColor: ProfilePictureListPagination.DarkGreen };

    return (
      <div className={"label label-default"} style={labelStyle} title={title}>
        {labelText}
      </div>
    );
  }
}

export { ProfileLabel };
