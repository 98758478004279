import { getVideoAddress } from "library/picture";
import { observer } from "mobx-react";
import { Component } from "react";
import * as React from "react";
import { mainStore } from "store";

interface IVideoProps {
  handleClick: () => void;
}

@observer
class Video extends Component<IVideoProps> {
  video: HTMLVideoElement;

  handleClick = (event: React.SyntheticEvent<HTMLVideoElement>) => {
    this.props.handleClick();
  };

  render() {
    const { twitterStore, static_prefix } = mainStore;
    const { twitter_profile_store, twitter_video_store } = twitterStore;
    const { active_profile } = twitter_profile_store;

    const { current_active_video } = twitter_video_store;

    let video_path = "";
    if (current_active_video) {
      video_path = getVideoAddress(static_prefix, active_profile.screen_name, current_active_video.file_name);
    }
    return (
      <video
        onClick={this.handleClick}
        ref={element => (this.video = element)}
        style={{ width: "100%", height: "100%", backgroundColor: "#000", outline: 0, cursor: "pointer" }}
        autoPlay={true}
        preload="auto"
        src={video_path}
        playsInline={true}
      >
        <source type="video/mp4" />
      </video>
    );
  }
}

export { Video };
