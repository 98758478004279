import { ToastrManager, Repeller } from "components/common";
import { observer } from "mobx-react";
import * as React from "react";
import { mainStore } from "store";

import { FavoriteButton } from "./FavoriteButton";

interface ITwitterProfileFavoriteState {
  is_saving: boolean;
}

@observer
class TwitterProfileFavorite extends React.Component<any, ITwitterProfileFavoriteState> {
  state = {
    is_saving: false
  };

  setProfileType = (is_favorite: boolean) => {
    const { twitterStore } = mainStore;
    const { twitter_profile_store } = twitterStore;
    const { active_profile } = twitter_profile_store;
    const { id } = active_profile;

    this.setState({ is_saving: true });

    twitterStore.twitter_profile_store
      .setProfileIsFavorite(id, is_favorite)
      .then(() => {
        twitterStore.twitter_profile_store.updateProfileIsFavorite(id, is_favorite);
        ToastrManager.successRight("Profile was updated");
      })
      .catch(errorData => {
        let message = errorData.Message;
        if (!errorData.Message) {
          message = errorData.message;
        }
        if (!message) {
          message = errorData;
        }
        ToastrManager.error(message);
      })
      .finally(() => {
        this.setState({ is_saving: false });
      });
  };

  render() {
    const { is_saving } = this.state;
    const { twitterStore } = mainStore;
    const { twitter_profile_store } = twitterStore;
    const { active_profile } = twitter_profile_store;
    if (!active_profile) {
      return null;
    }

    const current_is_favorite = active_profile.is_favorite;

    return (
      <Repeller style={{ marginBottom: 10 }}>
        <FavoriteButton
          is_saving={is_saving}
          current_profile_favorite={current_is_favorite}
          buttonText="Mark as Favorite"
          is_favorite={true}
          handleFavoriteClick={this.setProfileType}
        />
        <FavoriteButton
          is_saving={is_saving}
          current_profile_favorite={current_is_favorite}
          buttonText="Remove Favorite mark"
          is_favorite={false}
          handleFavoriteClick={this.setProfileType}
        />
      </Repeller>
    );
  }
}

export { TwitterProfileFavorite };
