import { Searcher } from "components/common";
import { Cluster } from "components/common/Cluster";
import { OrderingButton } from "components/common/OrderingButton";
import { observer } from "mobx-react";
import * as React from "react";
import { Component } from "react";
import { mainStore } from "store";

interface ITwitterProfileListFilterProps {
  resetScroll: () => void;
}

@observer
class TwitterProfileListFilter extends Component<ITwitterProfileListFilterProps> {
  filterProfiles = (newInputText: string) => {
    const { twitterStore } = mainStore;
    twitterStore.twitter_profile_store.profileSearchFilter = newInputText;
  };

  filterProfilesByLocation = (newInputText: string) => {
    const { twitterStore } = mainStore;
    twitterStore.twitter_profile_store.profileLocationSearchFilter = newInputText;
  };

  // tslint:disable-next-line:prefer-function-over-method
  render() {
    const { twitterStore } = mainStore;
    const { resetScroll } = this.props;

    const { twitter_profile_store } = twitterStore;

    const {
      toggleProfileUnwatchedVideoCountFilter,
      profileUnwatchedVideoCountFilterIsActive,
      profileMostWatchedVideoCountFilterIsActive,
      toggleProfileMostWatchedVideoCountFilter,
      profileIsFavoriteFilter,
      toggleProfileIsFavoriteFilter,
      profileIsCallGirlFilter,
      toggleProfileIsCallGirlFilter,
      toggleProfileIsInactive,
      profileIsInactiveFilter,
      profileMissingTypeFilter,
      toggleProfileMissingType,
      toggleProfileIsPromoterFilter,
      profileIsPromoterGirlFilter,
      toggleProfileHasMoreVideos,
      profileHasMoreVideosFilter,
      profileSearchFilter,
      profileLocationSearchFilter
    } = twitter_profile_store;

    return (
      <Cluster>
        <OrderingButton
          atleastOne={true}
          resetScroll={resetScroll}
          toggle={toggleProfileUnwatchedVideoCountFilter}
          isActive={profileUnwatchedVideoCountFilterIsActive}
          label={"Most unwatched"}
        />
        <OrderingButton
          atleastOne={true}
          resetScroll={resetScroll}
          toggle={toggleProfileMostWatchedVideoCountFilter}
          isActive={profileMostWatchedVideoCountFilterIsActive}
          label={"Most watched"}
        />
        <OrderingButton
          atleastOne={true}
          resetScroll={resetScroll}
          toggle={toggleProfileIsFavoriteFilter}
          isActive={profileIsFavoriteFilter}
          label={"Only favorites"}
        />

        <OrderingButton
          atleastOne={true}
          resetScroll={resetScroll}
          toggle={toggleProfileIsCallGirlFilter}
          isActive={profileIsCallGirlFilter}
          label={"Call girls"}
        />

        <OrderingButton
          atleastOne={true}
          resetScroll={resetScroll}
          toggle={toggleProfileIsPromoterFilter}
          isActive={profileIsPromoterGirlFilter}
          label={"Promoters"}
        />

        <OrderingButton
          atleastOne={true}
          resetScroll={resetScroll}
          toggle={toggleProfileIsInactive}
          isActive={profileIsInactiveFilter}
          label={"Inactive"}
        />

        <OrderingButton
          atleastOne={true}
          resetScroll={resetScroll}
          toggle={toggleProfileMissingType}
          isActive={profileMissingTypeFilter}
          label={"Missing type"}
        />

        <OrderingButton
          atleastOne={true}
          resetScroll={resetScroll}
          toggle={toggleProfileHasMoreVideos}
          isActive={profileHasMoreVideosFilter}
          label={"Has more videos"}
        />

        <Searcher handleChange={this.filterProfiles} width={125} searchText={profileSearchFilter} />
        <Searcher handleChange={this.filterProfilesByLocation} width={125} searchText={profileLocationSearchFilter} />
      </Cluster>
    );
  }
}

export { TwitterProfileListFilter };
