import { observer } from "mobx-react";
import * as React from "react";
import { Component } from "react";
import { mainStore } from "store";
import { Panel } from "components/common";
import { MomentDate } from "library";
import { TwitterVideoIsFavorite } from "./TwitterVideoIsFavorite";
import { TwitterVideoIsExcluded } from "./TwitterVideoIsExcluded";
import { TweetText } from "./TweetText";
import { TwitterVideoIsChecked } from "./TwitterVideoIsChecked";

@observer
class TweetVideo extends Component {
  // tslint:disable-next-line:prefer-function-over-method
  render() {
    const { twitterStore } = mainStore;
    const { hasVideos, twitter_video_store, twitter_profile_store } = twitterStore;
    const { active_profile } = twitter_profile_store;

    const { current_active_video } = twitter_video_store;

    if (!current_active_video) {
      return null;
    }

    const { created_date } = current_active_video;
    const created_date_text = MomentDate.getDate(created_date);
    const hasTweetText = current_active_video && current_active_video.tweet_text && hasVideos;

    return (
      <Panel headerText={`Video (${created_date_text})`}>
        {active_profile && (
          <>
            <TwitterVideoIsFavorite />
            <TwitterVideoIsExcluded />
            <TwitterVideoIsChecked />
          </>
        )}
        {hasTweetText && <TweetText />}
      </Panel>
    );
  }
}

export { TweetVideo };
