import { Component } from "react";
import * as React from "react";

interface IScrollToAnchorProps {
  href: string;
  className?: string;
  id?: string;
}

class ScrollToAnchor extends Component<IScrollToAnchorProps, any> {
  anchorElement: HTMLAnchorElement;

  handleClick = (event: React.SyntheticEvent<any>) => {
    event.preventDefault();
    const { href } = this.props;
    const top = $("#" + href).offset().top;
    $("html, body").animate(
      {
        scrollTop: top
      },
      500
    );
    $("#" + href + " form:first *:input[type!=hidden]:first").focus();
  };

  render() {
    const { className } = this.props;
    const classNameString = className || "";
    return (
      <a
        ref={element => (this.anchorElement = element)}
        {...(this.props.id ? { id: this.props.id } : {})}
        className={classNameString}
        href={"#" + this.props.href}
        role="button"
        onClick={this.handleClick}
      >
        {this.props.children}
      </a>
    );
  }
}

export default ScrollToAnchor;
