import { Component } from "react";
import * as React from "react";

interface IFeatureItemProps {
  title: string;
}

class FeatureItem extends Component<IFeatureItemProps, any> {
  render() {
    return (
      <div className="single-feature">
        <h5>{this.props.title}</h5>

        {this.props.children}
      </div>
    );
  }
}

export default FeatureItem;
