import { Stack } from "components/common";
import { observer } from "mobx-react";
import { Profile } from "models/Profile";
import { Component } from "react";
import * as React from "react";

import { ProfileLabel } from "./ProfileLabel";

interface IProfileMissingPictureLabelsProps {
  profile: Profile;
}

@observer
class ProfileMissingPictureLabels extends Component<IProfileMissingPictureLabelsProps, any> {
  handleOpenRemovedPicturesClick = (event: React.SyntheticEvent<any>) => {
    event.preventDefault();
    const profile = this.props.profile;
    const { profileData } = profile;
    profileData.shouldShowRemovedPictures = !profileData.shouldShowRemovedPictures;
    this.setState({ firstIndex: 0 });
  };

  render() {
    const profile = this.props.profile;
    const { profileData } = profile;
    const { excludedPicturesCount, removedPicturesCount } = profileData;

    const openCloseRemovedPicturesButtonText = profile.profileData.shouldShowRemovedPictures ? "Close" : "Open";

    return (
      <div style={{ float: "right", zIndex: 10, top: 15, left: 0 }}>
        <div style={{ position: "absolute", right: 6, zIndex: 100, textAlign: "right" }}>
          <Stack size="small">
            <div>
              <ProfileLabel is_visible={excludedPicturesCount != 0} labelText={`${excludedPicturesCount.toString()} excluded`} />
            </div>
            <div>
              {removedPicturesCount != 0 && (
                <button
                  className="btn btn-xs btn-default"
                  style={{
                    fontSize: 10,
                    lineHeight: 1.3
                  }}
                  onClick={this.handleOpenRemovedPicturesClick}
                >
                  {openCloseRemovedPicturesButtonText}
                </button>
              )}
              <ProfileLabel is_visible={removedPicturesCount != 0} labelText={`${removedPicturesCount.toString()} removed`} />
            </div>
          </Stack>
        </div>
      </div>
    );
  }
}

export { ProfileMissingPictureLabels };
