import { Component } from "react";
import * as React from "react";
import { colors } from "styles";

interface IBadgeProps {
  title?: string;
  label: string | number;
}

class DarkGreenBadge extends Component<IBadgeProps, any> {
  render() {
    const { label, title } = this.props;
    return (
      <span className="badge" title={title} style={{ verticalAlign: "top", marginLeft: 5, background: colors.DarkGreen }}>
        {label}
      </span>
    );
  }
}

export { DarkGreenBadge };
