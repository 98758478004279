import { ToastrManager } from "components/common";
import { observer, inject } from "mobx-react";
import * as React from "react";
import { mainStore } from "store";
import { ProfileTypeButton } from "./ProfileTypeButton";
import { ModalStore } from "store/ModalStore";

interface IProfileTypeButtonsComponentState {
  is_saving: boolean;
}

interface IProfileTypeButtonsComponentProps {
  modalStore?: ModalStore;
}

@inject((stores: { modalStore: ModalStore }, nextProps: IProfileTypeButtonsComponentProps) => (nextProps.modalStore = stores.modalStore))
@observer
class ProfileTypeButtonsComponent extends React.Component<IProfileTypeButtonsComponentProps, IProfileTypeButtonsComponentState> {
  state = {
    is_saving: false
  };

  setProfileType = (profile_type: number) => {
    const { modalStore } = this.props;
    const { twitterStore } = mainStore;
    const { twitter_profile_store } = twitterStore;
    const { active_profile } = twitter_profile_store;
    const { id } = active_profile;

    this.setState({ is_saving: true });

    twitterStore.twitter_profile_store
      .setProfileType(id, profile_type)
      .then(() => {
        twitterStore.twitter_profile_store.updateProfileType(id, profile_type);
        modalStore.handleCloseModal();
        ToastrManager.successRight("Profile was updated");
      })
      .catch(errorData => {
        let message = errorData.Message;
        if (!errorData.Message) {
          message = errorData.message;
        }
        if (!message) {
          message = errorData;
        }
        ToastrManager.error(message);
      })
      .finally(() => {
        this.setState({ is_saving: false });
      });
  };

  render() {
    const { is_saving } = this.state;
    const { twitterStore, misc_data } = mainStore;
    const { twitter_profile_store } = twitterStore;
    const { active_profile } = twitter_profile_store;
    if (!active_profile || !misc_data) {
      return null;
    }
    const { profile_types } = misc_data;

    const current_profile_type = active_profile.type;

    return (
      <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-around", alignContent: "space-around", height: 200 }}>
        <ProfileTypeButton
          is_saving={is_saving}
          current_profile_type={current_profile_type}
          buttonText="Mark as Call girl"
          profile_type={profile_types.call_girl.id}
          handleTypeClick={this.setProfileType}
        />
        <ProfileTypeButton
          is_saving={is_saving}
          current_profile_type={current_profile_type}
          buttonText="Mark as Porn star"
          profile_type={profile_types.porn_star.id}
          handleTypeClick={this.setProfileType}
        />
        <ProfileTypeButton
          is_saving={is_saving}
          current_profile_type={current_profile_type}
          buttonText="Mark as Promoter"
          profile_type={profile_types.promoter.id}
          handleTypeClick={this.setProfileType}
        />
        <ProfileTypeButton
          is_saving={is_saving}
          current_profile_type={current_profile_type}
          buttonText="Mark as Model"
          profile_type={profile_types.model.id}
          handleTypeClick={this.setProfileType}
        />
        <ProfileTypeButton
          is_saving={is_saving}
          current_profile_type={current_profile_type}
          buttonText="Mark as Cam girl"
          profile_type={profile_types.cam_girl.id}
          handleTypeClick={this.setProfileType}
        />
      </div>
    );
  }
}

export { ProfileTypeButtonsComponent };
