import loader from "library/loader";
import { observer } from "mobx-react";
import * as React from "react";
import { Component } from "react";
import { NavLink, RouteComponentProps, withRouter } from "react-router-dom";
import { mainStore } from "store";

import ListItemNavLink from "./ListItemNavLink";
import { OpenMenuButton } from "./OpenMenuButton";
import { OpenOverviewButton } from "./OpenOverviewButton";
import { OpenSearchButton } from "./OpenSearchButton";

const { profileStore, loginStore } = mainStore;

@observer
class TopMenu extends Component<RouteComponentProps<any>, any> {
  collapseElement: HTMLDivElement;
  openMenuButton: OpenMenuButton;
  openMenuButtonSmallDisplay: OpenMenuButton;
  search_input: HTMLInputElement;
  navbar: HTMLElement;

  constructor(props: any, context: any) {
    super(props, context);

    this.state = {
      search_term: ""
    };
  }

  closeCollapse = () => {
    const collapse_enabled = $(this.openMenuButton.button).is(":visible") && $(this.collapseElement).hasClass("in");
    if (collapse_enabled) {
      $(this.collapseElement).toggleClass("in");
    }
  };

  SetListItemAction = () => {
    this.DeactivateNavLinks();
  };

  updateSearchTerm = (event: React.SyntheticEvent<any>) => {
    const search_term = (event.target as HTMLInputElement).value;
    return this.setState({ search_term: search_term });
  };

  Search = (event: React.SyntheticEvent<any>) => {
    event.preventDefault();
    this.DeactivateNavLinks();
    if (this.state.search_term) {
      this.deactivateSearch();
      loader.startProfileLoad();
      const encoded_search_term = encodeURIComponent(this.state.search_term);

      const to_link = mainStore.toLink;

      this.props.history.push(`${to_link}/search?search_term=${encoded_search_term}`);
      $(window).scrollTop(0);
      $(this.search_input).blur();
    }
  };

  DeactivateNavLinks = () => {
    $("ul.nav.navbar-nav > li").removeClass("active");
    this.closeCollapse();
    $(".collapse.profile-collapse").removeClass("in");
  };

  onHomeClick = () => {
    this.resetMenu();
  };

  activateSearch = () => {
    this.closeCollapse();
    $(this.navbar).addClass("search-active");
    $(this.search_input).focus();
  };

  resetMenu = () => {
    this.DeactivateNavLinks();
    this.deactivateSearch();
    this.setState({ search_term: "" });
    $(this.navbar).removeClass("search-active");
    profileStore.resetBottomSpin();
  };

  handleOverViewClick = () => {
    this.resetMenu();
  };

  deactivateSearch = () => {
    this.setState({ search_term: "" });
    $(this.navbar).removeClass("search-active");
  };

  deactivateSearchClick = (event: React.SyntheticEvent<any>) => {
    event.preventDefault();
    this.deactivateSearch();
  };

  handleMenuCollapseClick = (event: React.SyntheticEvent<any>) => {
    event.preventDefault();
    $(this.collapseElement).toggleClass("in");
  };

  render() {
    const { brandName } = mainStore;

    let count = undefined;
    if (profileStore.current) {
      count = profileStore.count(profileStore.profile_type);
    }

    return (
      <nav ref={element => (this.navbar = element)} className="navbar navbar-default navbar-fixed-top">
        <div className="container">
          <div className="navbar-header">
            {loginStore.is_logged_in && <OpenSearchButton onClick={this.activateSearch} cssClass="shrink" />}
            {loginStore.is_logged_in && <OpenOverviewButton onClick={this.handleOverViewClick} cssClass="shrink pull-right" />}

            {loginStore.is_logged_in && (
              <>
                <OpenMenuButton ref={element => (this.openMenuButton = element)} onClick={this.handleMenuCollapseClick} />
                {count != 0 && count && (
                  <span className="navbar-text mobile profile-count-badge" style={{ float: "right" }}>
                    <span className="badge">{count}</span>
                  </span>
                )}
              </>
            )}

            <NavLink to="/" className="navbar-brand" onClick={this.onHomeClick}>
              {brandName}
            </NavLink>

            <form className="search-form" onSubmit={this.Search}>
              <div className="nav-search-inner">
                <input
                  ref={element => (this.search_input = element)}
                  type="search"
                  name="search"
                  placeholder="Indtast og tryk ENTER"
                  value={this.state.search_term}
                  onChange={this.updateSearchTerm}
                />
              </div>
              <button type="button" className="close-search btn-link" onClick={this.deactivateSearchClick}>
                <i className="fa fa-remove" />
              </button>
            </form>
          </div>

          {loginStore.is_logged_in && (
            <div ref={element => (this.collapseElement = element)} className="collapse navbar-collapse ">
              {!profileStore.isAllStoreActive && (
                <ul className="nav navbar-nav shrink">
                  <ListItemNavLink profile_type="like" onClick={this.SetListItemAction} />
                  <ListItemNavLink profile_type="new" onClick={this.SetListItemAction} />
                  <ListItemNavLink profile_type="updated" onClick={this.SetListItemAction} />
                  <ListItemNavLink profile_type="comeback" onClick={this.SetListItemAction} />
                </ul>
              )}

              {!profileStore.isAllStoreActive && (
                <ul className="nav navbar-nav shrink">
                  <ListItemNavLink profile_type="maybe" onClick={this.SetListItemAction} />
                  <ListItemNavLink profile_type="exclude" onClick={this.SetListItemAction} />
                </ul>
              )}
              <OpenSearchButton onClick={this.activateSearch} cssClass="pull-right" />
              <OpenOverviewButton onClick={this.handleOverViewClick} cssClass="pull-right" />
              <OpenMenuButton
                cssClass="shrink pull-right"
                ref={element => (this.openMenuButtonSmallDisplay = element)}
                onClick={this.handleMenuCollapseClick}
              />
            </div>
          )}
        </div>
      </nav>
    );
  }
}

const TopMenuWithRouter = withRouter<RouteComponentProps<any>>(TopMenu);
export { TopMenuWithRouter as TopMenu };
