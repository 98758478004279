import { observer } from "mobx-react";
import * as React from "react";
import { Component } from "react";

import { Picture, Profile } from "models/Profile";
import { mainStore } from "store";
import { AnchorButton } from "components/common";
import { PictureManager } from "library/picture";
import { MomentDate } from "library";

function getPictureRatioMediaQuery(picture: Picture) {
  let result = "";

  const picture_width_ratio = (picture.width / picture.height) * 100;
  const picture_height_ratio = (picture.height / picture.width) * 100;
  const picture_ratio_text = picture.width.toString() + "/" + picture.height.toString();

  result = ".modal-picture-" + picture.id + " {";
  result += "width: 100vw;";
  result += "height: " + picture_height_ratio + "vw;";
  result += "}";

  result += "@media (min-aspect-ratio: " + picture_ratio_text + ") {";
  result += ".modal-picture-" + picture.id + "  {";
  result += "width: " + (Math.floor(picture_width_ratio) - 0.3) + "vh;";
  result += "height: 100vh;";
  result += "}}";
  return result;
}

interface IProfilePictureProps {
  profile: Profile;
  picture: Picture;
  isThumb?: boolean;
  cssClass: string;
  index: number;
  setModalProfile?: (profile: Profile, pictureId: number) => void;
  offset?: number;
}

@observer
class ProfilePicture extends Component<IProfilePictureProps, any> {
  pictureDiv: HTMLDivElement;

  constructor(props: IProfilePictureProps, context: any) {
    super(props, context);

    this.state = {
      lastClick: new Date().getTime()
    };
  }

  handlePictureClick = () => {
    if (this.props.isThumb && this.props.setModalProfile) {
      const now = new Date().getTime();
      const lastClick = this.state.lastClick;
      const delta = now - lastClick;
      if (delta < 500 && delta > 0) {
        const pictureId = this.props.picture.id;
        this.props.setModalProfile(this.props.profile, pictureId);
      } else {
        this.setState({ lastClick: now });
      }
    }
  };

  render() {
    const {index, profile, isThumb, picture, offset} = this.props;
    let cssClass = this.props.cssClass;
    const { profileData } = profile;
    const { shouldShowRemovedPictures } = profileData;
    const {created_date} = picture;
    const { loginStore, static_prefix, pictureSettingStore } = mainStore;
    const website = mainStore.getWebsite(profile.website_id.toString());

    let thumb_class = "";
    if (isThumb) {
      thumb_class = "thumbnail";
    }

    cssClass += " " + thumb_class;
    const thumb_offset = -index * 144;

    const showImageFakeCount = picture.fake_image_count != undefined && picture.fake_image_count != 0;
    const showPageFakeCount = picture.fake_page_count != undefined && picture.fake_page_count != 0;
    const showFakeCount = showImageFakeCount || showPageFakeCount;
    const onlyOneFakeCount = (showImageFakeCount && !showPageFakeCount) || (!showImageFakeCount && showPageFakeCount);
    let fakeCount = Math.ceil((picture.fake_image_count + picture.fake_page_count) / 2);
    if (onlyOneFakeCount) {
      fakeCount = Math.max(picture.fake_image_count, picture.fake_page_count);
    }

    const isOld = MomentDate.isOlderThanMonths(created_date, 3);

    const is_staff = loginStore.is_staff;
    const showBlurred = picture.blurred && is_staff;

    const isDropdownOpen = pictureSettingStore.isOpenForPicture(picture.id);
    const baseContentUrl = PictureManager.baseContentUrl(static_prefix);
    return (
      <div className={"img-container " + cssClass}>
        {!isThumb && !shouldShowRemovedPictures && (
          <div className={"modal-picture-" + picture.id}>
            <div
              className="full-picture"
              style={{
                backgroundPosition: offset + "% 0%",
                width: "100%",
                height: "100%",
                backgroundSize: "cover"
              }}
            />
            <style dangerouslySetInnerHTML={{ __html: getPictureRatioMediaQuery(picture) }} />
          </div>
        )}

        {!isThumb && shouldShowRemovedPictures && (
          <div className={"modal-picture-" + picture.id}>
            <div
              style={{
                width: "100%",
                height: "100%",
                backgroundImage: "url('" + baseContentUrl + "all_images/" + picture.file_name + "?v=" + profile.version + "')",
                backgroundSize: "cover"
              }}
            />
            <style dangerouslySetInnerHTML={{ __html: getPictureRatioMediaQuery(picture) }} />
          </div>
        )}

        {isThumb && shouldShowRemovedPictures && (
          <div
            onClick={this.handlePictureClick}
            style={{
              width: 144,
              height: 180,
              backgroundImage: "url('" + baseContentUrl + "all_thumb_images/" + picture.file_name + "?v=" + profile.version + "')"
            }}
          />
        )}

        {isThumb && !shouldShowRemovedPictures && (
          <div
            onClick={this.handlePictureClick}
            style={{
              backgroundImage:
                "url('" + baseContentUrl + website.site_name + "/" + profile.profile_id + "/sprite/thumbs.jpg?v=" + profile.version + "')",
              backgroundPosition: thumb_offset + "px 0px",
              width: "144px",
              height: "180px"
            }}
          />
        )}

        {showBlurred && (
          <span
            className={"blurred badge progress-bar-danger"}
            data-placement="top"
            data-toggle="tooltip"
            title={"Dette billede er sløret."}
          >
            s
          </span>
        )}

        {isOld &&
          <span
          className={"old-image badge progress-bar-danger"}
          data-placement="top"
          data-toggle="tooltip"
          title={"Dette billede er gammelt."}
          >
            o
          </span>
        }

        {showFakeCount && (
          <span
            className={"fake-image badge progress-bar-danger"}
            data-placement="top"
            data-toggle="tooltip"
            title={"Dette billede blev fundet " + fakeCount + " andre steder."}
          >
            {fakeCount}
          </span>
        )}

        {is_staff && isThumb && (
          <>
            <span
              onClick={() => pictureSettingStore.handleOpenSettingClick(picture.id)}
              className="hoverable"
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                padding: "5px 10px",
                cursor: "pointer",
                color: "#777",
                borderRadius: 4,
                backgroundColor: "#f8f8f8",
                borderBottom: "1px solid #ddd",
                borderLeft: "1px solid #ddd"
              }}
            >
              <i className="fa fa-ellipsis-v" style={{ verticalAlign: "middle" }} />
            </span>
            <ul
              className="dropdown-menu"
              style={{
                display: isDropdownOpen ? "block" : "none",
                top: 32,
                right: 0,
                width: 90,
                minWidth: "inherit",
                left: "inherit"
              }}
            >
              <li>
                <AnchorButton handleClick={() => pictureSettingStore.handleExcludePictureClick(picture)}>Exclude</AnchorButton>
              </li>
            </ul>
          </>
        )}
      </div>
    );
  }
}

export default ProfilePicture;
