import { MomentDate } from "library";
import { observer } from "mobx-react";
import { Component } from "react";
import * as React from "react";
import { mainStore } from "store";

import ProfileListRow from "./ProfileListRow";

@observer
class ProfileListWithDateDivider extends Component {
  // tslint:disable-next-line:prefer-function-over-method
  render() {
    const { profileStore } = mainStore;
    const statuses = profileStore.current.statuses;
    const page_list_type = profileStore.profile_type;
    let previousStatusUpdatedDate = "";
    let previousStatusComebackDate = "";
    return (
      <>
        {statuses.map((status, index) => {
          if (!status.profile.profileData.hasPictures && !status.profile.profileData.shouldShowRemovedPictures) {
            return null;
          }
          const currentUpdatedDate = status.profile.updated_date;
          const currentComebackDate = status.profile.comeback_date;
          const profileListRow = (
            <ProfileListRow key={status.id} status={status} page_list_type={page_list_type} profileData={status.profile.profileData} />
          );
          if (index == 0) {
            previousStatusUpdatedDate = currentUpdatedDate;
            previousStatusComebackDate = currentComebackDate;
          }
          const is_new_updated_date = previousStatusUpdatedDate != currentUpdatedDate && page_list_type == "updated";
          const is_new_comeback_date = previousStatusComebackDate != currentComebackDate && page_list_type == "comeback";
          if (is_new_updated_date || is_new_comeback_date) {
            previousStatusComebackDate = currentComebackDate;
            previousStatusUpdatedDate = currentUpdatedDate;
            let newDateText = MomentDate.getDatePretty(currentUpdatedDate);
            if (page_list_type == "comeback") {
              newDateText = MomentDate.getDatePretty(currentComebackDate);
            }

            return (
              <React.Fragment key={status.id}>
                <li
                  className="divider"
                  style={{
                    backgroundColor: "#9CC1C1",
                    textAlign: "center",
                    paddingTop: 10,
                    paddingBottom: 10
                  }}
                >
                  <div style={{ marginLeft: 5 }}>{newDateText}</div>
                </li>
                {profileListRow}
              </React.Fragment>
            );
          }

          return profileListRow;
        })}
      </>
    );
  }
}

export { ProfileListWithDateDivider };
