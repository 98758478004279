import * as punycode from "punycode";
import * as uts46 from "idna-uts46";

class PunyEncoder {
  convertToASCII = (text: string): string => {
    let retval = text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    try {
      retval = uts46.toAscii(retval, { transitional: true, useStd3ASCII: false });
    } catch (error) {
      const text_parts = retval.split(" ");
      if (text_parts.length == 1) {
        retval = punycode.toASCII(retval);
      } else {
        retval = text_parts.map(text_part => this.convertToASCII(text_part)).join(" ");
      }
    }
    return retval;
  };

  convertToASCII2 = (text: string): string => {
    return uts46.toAscii(text, { transitional: false });
  };
}

const punyEncoder = new PunyEncoder();

export { punyEncoder };
