import { ToastrManager, Button } from "components/common";
import { observer } from "mobx-react";
import { DeleteReason } from "models/twitter";
import * as React from "react";
import { render } from "react-dom";
import { mainStore } from "store";

import { VideoRemoveButton } from "./VideoRemoveButton";

interface IVideoRemoveButtonsComponentProps {
  video_id: number;
  pauseVideo: () => void;
}

interface IVideoRemoveButtonsComponentState {
  is_saving: boolean;
}

@observer
class VideoRemoveButtonsComponent extends React.Component<IVideoRemoveButtonsComponentProps, IVideoRemoveButtonsComponentState> {
  state = {
    is_saving: false
  };

  moveVideo = (moveType: DeleteReason) => {
    const { video_id, pauseVideo } = this.props;
    const { twitterStore } = mainStore;
    const { twitter_video_store } = twitterStore;
    const { regretVideoDelete, IsVideoFavorited } = twitter_video_store;

    const is_favorite = IsVideoFavorited(video_id);
    if (is_favorite) {
      ToastrManager.error("Cannot delete a video that is a favorite");
      return;
    }

    const RegretButton = (
      <>
        <div>
          <span>Video was removed</span>
        </div>
        <div style={{ marginTop: 10 }}>
          <Button buttonSize="small" handleClick={() => regretVideoDelete(video_id)}>
            Regret
          </Button>
        </div>
      </>
    );

    this.setState({ is_saving: true });
    pauseVideo();
    twitterStore
      .deleteVideoWithReason(video_id, moveType)
      .then(() => {
        ToastrManager.successRightWithRegret(RegretButton, video_id);
      })
      .catch(errorData => {
        ToastrManager.error(errorData.Message);
      });
    this.setState({ is_saving: false });
  };

  render() {
    const { is_saving } = this.state;
    return (
      <>
        <div>
          <VideoRemoveButton
            is_saving={is_saving}
            moveType={"duplicate"}
            buttonText="Remove as Duplicate"
            handleMoveClick={this.moveVideo}
          />
        </div>
        <div>
          <VideoRemoveButton is_saving={is_saving} moveType={"bad"} buttonText="Remove as Bad" handleMoveClick={this.moveVideo} />
        </div>
      </>
    );
  }
}

export { VideoRemoveButtonsComponent };
