import { observer } from "mobx-react";
import { ITwitterProfile } from "models/twitter";
import * as React from "react";

interface IDropdownListItemProps {
  handleClick: (value: string) => void;
  profile: ITwitterProfile;
  templateAttributes?: (profile: ITwitterProfile) => React.ReactNode;
}

@observer
class DropdownListItem extends React.Component<IDropdownListItemProps, any> {
  static tagSelectedColor: string = "#D69E6F";
  static tagBaseColor: string = "#FFE4CD";

  handleOptionClick = (event: React.SyntheticEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    this.props.handleClick(this.props.profile.screen_name);
  };

  render() {
    const { profile, templateAttributes } = this.props;
    const { screen_name } = profile;

    let Label: React.ReactNode = <span>{screen_name}</span>;

    if (templateAttributes) {
      Label = templateAttributes(profile);
    }

    return (
      <li>
        <a href="#" onClick={this.handleOptionClick}>
          {Label}
        </a>
      </li>
    );
  }
}

export { DropdownListItem };
