import { Cluster } from "components/common/Cluster";
import { MomentDate } from "library";
import { NumberFormatter } from "library/numeral";
import { observer } from "mobx-react";
import { ITwitterProfile } from "models/twitter";
import { Component } from "react";
import * as React from "react";
import { mainStore } from "store";

import { AttributeLabel } from "./AttributeLabel";
import { TextOverflowWrapper } from "./TextOverflowWrapper";
import { TwitterVideo } from "./TwitterVideo";
import { ProfileIsSuspended } from "./ProfileIsSuspended";
import { ProfileIsMerged } from "./ProfileIsMerged";
import { ProfileHasMoreVideos } from "./ProfileHasMoreVideos";

interface IActiveProfileSectionProps {
  active_profile: ITwitterProfile;
}

@observer
class ActiveProfileSection extends Component<IActiveProfileSectionProps, any> {
  render() {
    const { active_profile } = this.props;
    if (!active_profile) {
      return null;
    }
    const { twitterStore } = mainStore;
    const { twitter_video_store } = twitterStore;
    const { current_active_videos: active_videos } = twitter_video_store;
    const has_videos = Boolean(active_videos) && active_videos.count > 0;

    const {
      name,
      screen_name,
      tweet_count,
      description,
      followers_count,
      created_date,
      video_count,
      last_activity,
      is_suspended,
      is_merged,
      api_has_more_videos,
      twint_has_more_videos
    } = active_profile;

    const DescriptionText = () => {
      if (description) {
        return <>{description}</>;
      }
      return <>&nbsp;</>;
    };

    return (
      <div className="row">
        <div>
          <TextOverflowWrapper>
            <h3 style={{ marginTop: 15 }}>
              {name}{" "}
              <small>
                <a href={`https://twitter.com/${screen_name}`} target="_BLANK">{`@${screen_name}`}</a>
              </small>
            </h3>
          </TextOverflowWrapper>
          <TextOverflowWrapper>
            <p title={description}>{DescriptionText()}</p>
          </TextOverflowWrapper>
          <Cluster alignment="center">
            <AttributeLabel text={"Tweets"} count={NumberFormatter.getPrettyNumberByTenThousand(tweet_count)} />
            <AttributeLabel text={"Followers"} count={NumberFormatter.getPrettyNumberByTenThousand(followers_count)} />
            <AttributeLabel text={"Videos"} count={video_count} />
            <AttributeLabel text={`Joined: ${MomentDate.getDatePretty(created_date)}`} />
            {last_activity && <AttributeLabel text={`Last activity: ${MomentDate.getDatePretty(last_activity)}`} />}
            <ProfileIsSuspended is_suspended={is_suspended} />
            <ProfileIsMerged is_merged={is_merged} />
            <ProfileHasMoreVideos api_has_more_videos={api_has_more_videos} twint_has_more_videos={twint_has_more_videos} />
          </Cluster>
        </div>

        <div>{has_videos && <TwitterVideo active_profile={active_profile} />}</div>
      </div>
    );
  }
}

export { ActiveProfileSection };
