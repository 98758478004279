import { TwitterApi } from "api";
import { Sorter } from "library/lodash";
import * as _ from "lodash";
import { action, computed, observable } from "mobx";
import { ITwitterProfile } from "models/twitter";

import { mainStore } from "./MainStore";
import { punyEncoder } from "library/puny";

class TwitterProfileStore {
  @observable public profiles: Array<ITwitterProfile>;
  @observable active_profile: ITwitterProfile;
  @observable profileUnwatchedVideoCountFilterIsActive: boolean;
  @observable profileMostWatchedVideoCountFilterIsActive: boolean;
  @observable profileIsFavoriteFilter: boolean;
  @observable profileIsCallGirlFilter: boolean;
  @observable profileIsPromoterGirlFilter: boolean;
  @observable profileIsInactiveFilter: boolean;
  @observable profileMissingTypeFilter: boolean;
  @observable profileHasMoreVideosFilter: boolean;
  @observable profileSearchFilter: string;
  @observable profileLocationSearchFilter: string;

  @observable profilePageIndex: number;
  @observable profilePageSize: number;

  @observable private profile_url_id?: number;

  private twitter_api: TwitterApi;
  constructor() {
    this.twitter_api = new TwitterApi();
    this.profiles = [];
    this.profileUnwatchedVideoCountFilterIsActive = false;
    this.profileMostWatchedVideoCountFilterIsActive = false;
    this.profileIsCallGirlFilter = false;
    this.profileIsFavoriteFilter = false;
    this.profileIsInactiveFilter = false;
    this.profileMissingTypeFilter = false;
    this.profileHasMoreVideosFilter = false;
    this.profileSearchFilter = undefined;
    this.profileLocationSearchFilter = undefined;
    this.profilePageSize = 25;
    this.resetProfilePaging();
  }

  @action resetProfilePaging = () => {
    this.profilePageIndex = 1;
  };

  getAllProfiles = (): Promise<void> => {
    return this.twitter_api.getAllProfiles().then(profiles => {
      this.profiles = profiles;
    });
  };

  setProfileUrlParameter = (profile_id?: string | number) => {
    if (profile_id && typeof profile_id == "string") {
      this.profile_url_id = parseInt(profile_id);
    } else if (profile_id && typeof profile_id == "number") {
      this.profile_url_id = profile_id;
    } else {
      this.profile_url_id = undefined;
    }
    return this.profile_url_id;
  };

  getProfileById = (profile_id: number) => {
    return this.profiles.find(profile => profile.id == profile_id);
  };

  setProfileType = (profile_id: number, profile_type: number) => {
    return this.twitter_api.setProfileType(profile_id, profile_type);
  };

  setProfileIsFavorite = (profile_id: number, is_favorite: boolean) => {
    if (is_favorite) {
      return this.twitter_api.setIsFavorite(profile_id);
    } else {
      return this.twitter_api.unsetIsFavorite(profile_id);
    }
  };

  setIsFollowed = (profile_id: number, is_followed: boolean) => {
    if (is_followed) {
      return this.twitter_api.setIsFollowed(profile_id);
    } else {
      return this.twitter_api.unsetIsFollowed(profile_id);
    }
  };

  @action updateProfileType = (profile_id: number, profile_type: number) => {
    const profile = this.profiles.find(profile_item => profile_item.id == profile_id);
    profile.type = profile_type;
  };

  @action updateProfileIsFavorite = (profile_id: number, is_favorite: boolean) => {
    const my_profile = this.profiles.find(profile => profile.id == profile_id);
    my_profile.is_favorite = is_favorite;
  };

  @action updateProfileIsFollowed = (profile_id: number, is_followed: boolean) => {
    const current_profile = this.profiles.find(profile => profile.id == profile_id);
    current_profile.is_followed = is_followed;
  };

  moveVideoToProfile = (video_id: number, profile_id: number) => {
    return this.twitter_api.moveToProfile(video_id, profile_id);
  };

  @action mergeToProfile = (profile_id: number, destination_profile_id: number) => {
    return this.twitter_api.mergeToProfile(profile_id, destination_profile_id).then(() => {
      const mergee = this.profiles.find(profile => profile.id == profile_id);
      mergee.is_merged = true;
      const destination_profile = this.profiles.find(profile => profile.id == destination_profile_id);
      if (mergee.is_favorite && !destination_profile.is_favorite) {
        destination_profile.is_favorite = true;
      }

      if (mergee.type && !destination_profile.type) {
        destination_profile.type = mergee.type;
      }
    });
  };

  @action toggleProfileUnwatchedVideoCountFilter = () => {
    this.resetProfilePaging();
    this.profileUnwatchedVideoCountFilterIsActive = !this.profileUnwatchedVideoCountFilterIsActive;
  };

  @action toggleProfileMostWatchedVideoCountFilter = () => {
    this.resetProfilePaging();
    this.profileMostWatchedVideoCountFilterIsActive = !this.profileMostWatchedVideoCountFilterIsActive;
  };

  @action toggleProfileIsFavoriteFilter = () => {
    this.resetProfilePaging();
    this.profileIsFavoriteFilter = !this.profileIsFavoriteFilter;
  };

  @action toggleProfileIsCallGirlFilter = () => {
    this.resetProfilePaging();
    this.profileIsCallGirlFilter = !this.profileIsCallGirlFilter;
  };

  @action toggleProfileIsPromoterFilter = () => {
    this.resetProfilePaging();
    this.profileIsPromoterGirlFilter = !this.profileIsPromoterGirlFilter;
  };

  @action toggleProfileIsInactive = () => {
    this.resetProfilePaging();
    this.profileIsInactiveFilter = !this.profileIsInactiveFilter;
  };

  @action toggleProfileMissingType = () => {
    this.resetProfilePaging();
    this.profileMissingTypeFilter = !this.profileMissingTypeFilter;
  };

  @action toggleProfileHasMoreVideos = () => {
    this.resetProfilePaging();
    this.profileHasMoreVideosFilter = !this.profileHasMoreVideosFilter;
  };

  @computed get totalVideosCount() {
    return _.sum(this.profiles.map(t => t.video_count));
  }

  @computed get allProfiles() {
    return this.profiles;
  }

  @computed get sortedProfiles() {
    let my_profiles = this.profiles;
    if (this.profileIsFavoriteFilter) {
      my_profiles = my_profiles.filter(profile => profile.is_favorite);
    }
    if (this.profileIsCallGirlFilter) {
      my_profiles = my_profiles.filter(profile => profile.type == mainStore.misc_data.profile_types.call_girl.id);
    }

    if (this.profileIsPromoterGirlFilter) {
      my_profiles = my_profiles.filter(profile => profile.type == mainStore.misc_data.profile_types.promoter.id);
    }

    if (this.profileIsInactiveFilter) {
      my_profiles = my_profiles.filter(profile => profile.is_suspended);
    }

    if (this.profileMissingTypeFilter) {
      my_profiles = my_profiles.filter(profile => !profile.type);
    }

    if (this.profileHasMoreVideosFilter) {
      my_profiles = my_profiles.filter(profile => profile.twint_has_more_videos || profile.api_has_more_videos);
    }

    if (this.profileSearchFilter) {
      my_profiles = my_profiles.filter(
        profile =>
          profile.screen_name.toLowerCase().includes(this.profileSearchFilter.toLowerCase()) ||
          profile.name.toLowerCase().includes(this.profileSearchFilter.toLowerCase()) ||
          punyEncoder
            .convertToASCII(profile.name)
            .toLowerCase()
            .includes(this.profileSearchFilter.toLowerCase())
      );
    }

    if (this.profileLocationSearchFilter) {
      my_profiles = my_profiles.filter(
        profile =>
          (profile.location && profile.location.toLowerCase().includes(this.profileLocationSearchFilter.toLowerCase())) ||
          profile.name.toLowerCase().includes(this.profileLocationSearchFilter.toLowerCase()) ||
          profile.description.toLowerCase().includes(this.profileLocationSearchFilter.toLowerCase())
      );
    }

    if (this.profileUnwatchedVideoCountFilterIsActive) {
      my_profiles = Sorter.sort(my_profiles, item => item.watched_video_count - item.video_count);
    } else if (this.profileMostWatchedVideoCountFilterIsActive) {
      my_profiles = Sorter.sortDesc(my_profiles, item => item.watched_video_count);
    }
    return { items: my_profiles.slice(0, this.profilePageIndex * this.profilePageSize), count: my_profiles.length };
  }

  @computed get hasProfileQuery() {
    return Boolean(this.profile_url_id);
  }

  @computed get profileHasWatchedVideos() {
    return this.active_profile && this.active_profile.watched_video_count > 0;
  }
}

export { TwitterProfileStore };
