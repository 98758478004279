import profileApi from "api/profileApi";
import constants from "constants/constants";
import loader from "library/loader";
import * as _ from "lodash";
import { action, computed, observable, ObservableMap } from "mobx";
import { createTransformer } from "mobx-utils";
import { IWebsite } from "models";
import { Profile, Status } from "models/Profile";

import { ProfileBaseStore } from "./ProfileBaseStore";
import { ProfileData } from "./ProfileData";

function getPageTitleText(sectionText: string) {
  const baseText = "Callgirl index";
  return baseText + " - " + sectionText;
}

class ProfileStore {
  @observable spider_id: string;
  @observable city_id?: number;

  @observable profile_type = "";
  @observable pageTitle = "";
  @observable stores = {
    like: new ProfileBaseStore("like"),
    new: new ProfileBaseStore("new"),
    maybe: new ProfileBaseStore("maybe"),
    updated: new ProfileBaseStore("updated"),
    comeback: new ProfileBaseStore("comeback"),
    exclude: new ProfileBaseStore("exclude"),
    search: new ProfileBaseStore("search")
  };

  @observable allStore = new ProfileBaseStore("");
  @observable isAllStoreActive = false;

  @observable modalProfile?: Profile;

  @observable isLoaded = false;
  @observable isPrintMode = false;

  @observable isMovingProfile: ObservableMap<number, boolean>;

  constructor() {
    this.modalProfile = undefined;
    this.isMovingProfile = new ObservableMap<number, boolean>();
  }

  @action setWebsites = (websites: Array<IWebsite>) => {
    Object.keys(this.stores).forEach(key => {
      const store = this.stores[key] as ProfileBaseStore;
      store.websites = websites;
    });
  };

  @action setSpiderId = (spider_id: string) => {
    this.spider_id = spider_id;
    if (spider_id) {
      this.setCityId();
    }

    Object.keys(this.stores).forEach(key => {
      const store = this.stores[key] as ProfileBaseStore;
      store.spider_id = this.spider_id;
      store.search_term = "";
    });

    this.allStore.spider_id = this.spider_id;
    this.allStore.search_term = "";
  };

  @action setCityId = (city_id?: number) => {
    if (city_id) {
      this.setSpiderId("");
    }
    this.city_id = city_id;
    Object.keys(this.stores).forEach(key => {
      const store = this.stores[key] as ProfileBaseStore;
      store.city_id = this.city_id;
      store.search_term = "";
    });
  };

  @action
  resetBottomSpin() {
    if (this.current) {
      this.current.current_page_offset = 9999999;
    }
  }

  @action set_page_title(text: string) {
    this.pageTitle = getPageTitleText(text);
  }

  @action
  set_profile_type(profile_type: string) {
    this.profile_type = profile_type;
    if (this.profile_type != "search") {
      this.stores.search.resetItems();
    }

    if (this.profile_type === "new") {
      this.set_page_title("Nye profiler");
    } else if (this.profile_type === "maybe") {
      this.set_page_title("Måske profiler");
    } else if (this.profile_type === "updated") {
      this.set_page_title("Opdaterede profiler");
    } else if (this.profile_type === "comeback") {
      this.set_page_title("Tilbagekomne profiler");
    } else if (this.profile_type === "exclude") {
      this.set_page_title("Udelukkede profiler");
    } else if (this.profile_type === "search") {
      this.set_page_title("Fremsøgte profiler");
    } else if (this.profile_type === "like") {
      this.set_page_title("Synes godt om profiler");
    }
  }

  @computed get hasExtraProfileTypeLabel() {
    const page_list_type = this.profile_type;
    return (
      page_list_type === "updated" ||
      page_list_type === "comeback" ||
      page_list_type === "search" ||
      page_list_type === "new" ||
      this.isAllStoreActive
    );
  }

  @action setAllStoreItems = () => {
    this.isAllStoreActive = true;
    this.allStore.items = [];
    this.allStore.max_profiles_count = this.allStore.default_max_profiles_count;
    this.allStore.count = 0;
    this.allStore.setItems();
    this.isLoaded = true;
  };

  @action setPrintMode = () => {
    this.isPrintMode = true;
    this.current.max_profiles_count = 9999999999;
  };

  @action
  resetItems(excludeSearch: boolean = false) {
    this.isAllStoreActive = false;

    // tslint:disable-next-line:no-loop-statement
    for (const key of Object.keys(this.stores)) {
      if (excludeSearch && key === "search") {
        continue;
      }
      if (!this.isLoaded && key === "search") {
        continue;
      }
      const store = this.stores[key] as ProfileBaseStore;
      store.resetItems();
      store.setItems();
    }
    this.isLoaded = true;
  }

  @action loadAllProfiles = () => {
    loader.startProfileLoad();
    this.current.getAllPageItems();
    this.current.loadLongPromise.then(loader.stopProfileLoad);
  };

  @action
  move_to_type(profile_id: number, profile_type: string) {
    const attention_list_names = ["list", "maybe", "excluded"];
    const remove_list_names = ["improper", "thai", "mature"];
    const extractedItem = this.current.items.find(item => item.id === profile_id);

    let movedStatus: Status | undefined = undefined;
    // Move profile from its main store (new, like, maybe, or excluded)
    if (extractedItem && extractedItem.type in this.stores) {
      const profileTypeStore = this.stores[extractedItem.type];
      movedStatus = profileTypeStore.extractItem(profile_id);
      if (movedStatus) {
        movedStatus.type = profile_type;
      }
    }
    if (profile_type in this.stores && movedStatus) {
      const movedToStore = this.stores[profile_type];
      movedToStore.items.push(movedStatus as any);
      movedToStore.count = movedToStore.count + 1;
    }

    if (_.includes(attention_list_names, this.current.page_type)) {
      // We're in new, like, maybe, or excluded
      // only update the comeback and updated stores
      this.updateTypeInProfile("comeback", profile_type, profile_id);
      this.updateTypeInProfile("updated", profile_type, profile_id);
    } else {
      // We're in the updated or comeback lists
      // don't remove the item from the list
      // update the type in all other stores
      Object.keys(this.stores).forEach(key => {
        this.updateTypeInProfile(key, profile_type, profile_id);
      });
    }

    if (_.includes(remove_list_names, profile_type)) {
      Object.keys(this.stores).forEach(key => {
        const store = this.stores[key] as ProfileBaseStore;
        store.extractItem(profile_id);
      });
    }
  }

  updateTypeInProfile = (store_type: string, profile_type: string, profile_id: number) => {
    const profileToUpdate = this.stores[store_type].items.find((element: Profile) => element.id === profile_id);
    if (profileToUpdate) {
      profileToUpdate.type = profile_type;
    }
  };

  @action setModalProfile(profile?: Profile) {
    this.modalProfile = profile;
    if (this.is_profile_modal_open) {
      $("body").css("overflow", "hidden");
    } else {
      $("body").css("overflow", "initial");
    }
  }

  @computed
  get is_profile_modal_open() {
    return this.modalProfile != undefined;
  }

  @computed
  get profile_type_text() {
    return constants.page_types_labels[this.profile_type];
  }

  @computed
  get current(): ProfileBaseStore {
    if (this.isAllStoreActive) {
      return this.allStore;
    }
    return this.stores[this.profile_type];
  }

  count = createTransformer((profile_type: string) => {
    if (profile_type == constants.profiles_types.new || profile_type == constants.profiles_types.like) {
      const store = this.stores[profile_type] as ProfileBaseStore;
      if (store.count < store.max_profiles_count && !store.loading && !store.longLoading) {
        return store.statuses.filter(status => status.profile.profileData.hasPictures).length;
      }
    }
    return this.stores[profile_type].count;
  });

  getProfile(profileId: number) {
    let status: Status | undefined = undefined;

    // tslint:disable-next-line:no-loop-statement
    for (const key of Object.keys(this.stores)) {
      const store: ProfileBaseStore = this.stores[key];
      status = store.items.find(x => x.profile.id == profileId);
      if (status) {
        break;
      }
    }
    if (status) {
      return Promise.resolve(status.profile);
    }
    return profileApi.getById(profileId).then(profileStatus => {
      if (!profileStatus.profile.profileData) {
        profileStatus.profile.profileData = observable(new ProfileData(profileStatus.profile));
      }
      return profileStatus.profile;
    });
  }
}

export { ProfileStore };
