import { OrderingButton } from "components/common/OrderingButton";
import { observer } from "mobx-react";
import { Component } from "react";
import * as React from "react";

interface IProfileAttributeLabelProps {
  is_visible: boolean;
  labelText: string;
  marginRight?: number;
  isTagActive: boolean;
  title?: string;
}

@observer
class ProfileAttributeLabel extends Component<IProfileAttributeLabelProps, any> {
  render() {
    const { is_visible, labelText, marginRight, isTagActive, title } = this.props;

    if (!is_visible) {
      return null;
    }

    let labelStyle: React.CSSProperties = { backgroundColor: OrderingButton.tagBaseColor };
    if (marginRight) {
      labelStyle = Object.assign({}, labelStyle, { marginRight });
    }

    if (isTagActive) {
      labelStyle = Object.assign({}, labelStyle, { backgroundColor: OrderingButton.tagSelectedColor });
    }

    return (
      <div className={"label label-default"} style={labelStyle} title={title}>
        {labelText}
      </div>
    );
  }
}

export { ProfileAttributeLabel };
