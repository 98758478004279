import { observer } from "mobx-react";
import { Component } from "react";
import * as React from "react";
import { mainStore } from "store";

import ProfileModal from "./ProfileModal";

@observer
class ProfilePictureModal extends Component<any, any> {
  imageModal: HTMLDivElement;

  componentDidMount() {
    const { profilePictureModalStore } = mainStore;
    profilePictureModalStore.imageModal = this.imageModal;
  }

  render() {
    return (
      <div>
        <div className="modal" id="largeImageModal" ref={element => (this.imageModal = element)} tabIndex={-1} role="dialog">
          <ProfileModal />
        </div>
        <div className="extra modal-backdrop in" />
        <div className="modal-backdrop in" />
      </div>
    );
  }
}

export { ProfilePictureModal };
