import * as React from "react";
import { Component } from "react";

interface IOpenMenuButtonProps {
  cssClass?: string;
  onClick: (event: React.SyntheticEvent<any>) => void;
}

class OpenMenuButton extends Component<IOpenMenuButtonProps, any> {
  button: HTMLButtonElement;

  render() {
    const { onClick, cssClass } = this.props;
    const buttonClass = cssClass || "";
    return (
      <button
        ref={element => (this.button = element)}
        type="button"
        className={"navbar-toggle collapsed open-menu-btn " + buttonClass}
        onClick={onClick}
      >
        <span className="fa fa-bars" />
      </button>
    );
  }
}

export { OpenMenuButton };
