import * as React from "react";
import { Component } from "react";
import { colors } from "styles";

interface IProfilePictureListPaginationProps {
  totalCount: number;
  index: number;
  style?: React.CSSProperties;
}

class ProfilePictureListPagination extends Component<IProfilePictureListPaginationProps, any> {
  static DarkGreen = colors.DarkGreen;
  containerDiv: HTMLDivElement;

  render() {
    const { style, totalCount, index } = this.props;
    const divStyle = Object.assign({}, style, { backgroundColor: ProfilePictureListPagination.DarkGreen });
    return (
      <div ref={element => (this.containerDiv = element)} className={"swiper-pagination label label-default"} style={divStyle}>
        <span> {index + 1} </span>/ <span>{totalCount}</span>
      </div>
    );
  }
}

export default ProfilePictureListPagination;
