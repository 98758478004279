import { Component } from "react";
import * as React from "react";
import { ScrollToAnchor } from "components/common";
import { mainStore } from "store";
import { TryOutButton, LogoutButtonSection } from "components/login";
import { NavLink } from "react-router-dom";

class WelcomePane extends Component {
  // tslint:disable-next-line:prefer-function-over-method
  render() {
    const { loginStore } = mainStore;
    const logged_in = loginStore.is_logged_in;

    return (
      <section className="full screen-page grey">
        <div className="container">
          <div className={"welcome " + (!logged_in ? "four" : "")}>
            <h1>Velkommen</h1>
            <div className="front-buttons">
              <div className={logged_in ? "three" : ""}>
                <ScrollToAnchor className="btn btn-primary btn-lg" href="read_more">
                  Læs mere
                </ScrollToAnchor>
              </div>

              {!logged_in && (
                <div>
                  <ScrollToAnchor className="btn btn-primary btn-lg" href="login">
                    Log ind
                  </ScrollToAnchor>
                </div>
              )}

              {!logged_in && (
                <div>
                  <ScrollToAnchor className="btn btn-primary btn-lg" href="signup">
                    Opret bruger
                  </ScrollToAnchor>
                </div>
              )}

              {!logged_in && (
                <div>
                  <TryOutButton />
                </div>
              )}
              {logged_in && (
                <div className={logged_in ? "three" : ""}>
                  <NavLink to={"/overview"} className="btn btn-primary btn-lg">
                    Overblik
                  </NavLink>
                </div>
              )}
              {logged_in && <LogoutButtonSection />}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export { WelcomePane };
