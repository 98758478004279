import { observer } from "mobx-react";
import * as React from "react";
import { Component } from "react";

interface IAnchorButtonProps {
  handleClick: () => void;
}

@observer
class AnchorButton extends Component<IAnchorButtonProps, any> {
  listItem: HTMLLIElement;

  handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    const { handleClick } = this.props;
    handleClick();
  };

  render() {
    return (
      <a role="button" onClick={this.handleClick}>
        {this.props.children}
      </a>
    );
  }
}

export { AnchorButton };
