import * as React from "react";
import { observer } from "mobx-react";
import { mainStore } from "store";
import { Status } from "models/Profile";
import profileApi from "api/profileApi";
import { ToastrManager, Button } from "components/common";
import { action } from "mobx";

interface IProfileMoveButtonProps {
  status: Status;
  outerClassName: string;
  buttonText: string;
  profile_type: string;
  page_type: string;
}

@observer
class ProfileMoveButton extends React.Component<IProfileMoveButtonProps, any> {
  @action moveProfile = () => {
    const { profile_type } = this.props;
    const { profileStore } = mainStore;
    const profile = this.props.status.profile;
    const url = profile[profile_type];

    profileStore.isMovingProfile.set(profile.id, true);
    profileApi
      .postUrl(url)
      .then(() => {
        profileStore.move_to_type(profile.id, profile_type);
      })
      .catch(errorData => {
        let message = errorData.Message;
        if (!errorData.Message) {
          message = errorData.message;
        }
        if (!message) {
          message = errorData;
        }
        ToastrManager.error(message);
      })
      .finally(() => {
        profileStore.isMovingProfile.set(profile.id, false);
      });
  };

  render() {
    const { profileStore } = mainStore;
    const { outerClassName, buttonText, profile_type, page_type, status } = this.props;
    const { isMovingProfile } = profileStore;
    const isMovingThisProfile = isMovingProfile.get(status.profile.id);
    const isDisabled = profile_type === page_type || isMovingThisProfile;
    return (
      <div className={outerClassName}>
        <Button className="btn btn-primary btn-block" handleClick={this.moveProfile} disabled={isDisabled}>
          {buttonText}
        </Button>
      </div>
    );
  }
}

export { ProfileMoveButton };
