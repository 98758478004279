import { LinkItem } from "components/common";
import { observer } from "mobx-react";
import * as React from "react";
import { Component } from "react";
import Helmet from "react-helmet";
import { match } from "react-router";
import { mainStore } from "store";

import { ActiveProfileSection } from "./ActiveProfileSection";
import { ButtonAndModal } from "./ButtonAndModal";
import { ProfileMergerComponent } from "./ProfileMergerComponent";
import { TweetVideo } from "./TweetVideo";
import { TwitterProfileDetail } from "./TwitterProfileDetail";
import { TwitterProfileFavorite } from "./TwitterProfileFavorite";
import { TwitterProfileFollowed } from "./TwitterProfileFollowed";
import { TwitterProfileListOverview } from "./TwitterProfileListOverview";
import { TwitterProfileType } from "./TwitterProfileType";
import { TwitterVideoListOverview } from "./TwitterVideoListOverview";
import { TwitterAllVideosDownloadButton } from "./TwitterAllVideosDownloadButton";

interface ITwitterPageProps {
  match: match<{ profile_id: string }>;
}

interface ITwitterPageState {
  profile_id: number;
}

@observer
class TwitterPage extends Component<ITwitterPageProps, ITwitterPageState> {
  imageModal: HTMLDivElement;

  constructor(props: ITwitterPageProps) {
    super(props);
    const profile_id = this.props.match.params.profile_id;

    this.state = {
      profile_id: Number(profile_id)
    };

    mainStore.twitterStore.twitter_video_store.getVideoCounts();
  }

  componentDidUpdate(prevProps: ITwitterPageProps) {
    const newProfileId = this.props.match.params.profile_id;
    const oldProfileId = prevProps.match.params.profile_id;
    const { loginStore, twitterStore } = mainStore;

    if (newProfileId && newProfileId != oldProfileId && loginStore.is_logged_in) {
      const { setActiveProfile } = twitterStore;
      setActiveProfile(parseInt(newProfileId));
    }

    if (!newProfileId && !twitterStore.twitter_video_store.hasOverviewVideos) {
      twitterStore.getAllVideos();
    }

    if (newProfileId != oldProfileId && loginStore.is_logged_in) {
      const { setProfileUrlParameter } = twitterStore;
      setProfileUrlParameter(newProfileId);
    }
  }

  // tslint:disable-next-line:prefer-function-over-method
  componentDidMount() {
    const { loginStore, twitterStore } = mainStore;
    const { twitter_profile_store } = twitterStore;
    const { active_profile } = twitter_profile_store;
    const { profile_id } = this.state;
    if (!profile_id && active_profile) {
      const { resetActiveProfile } = twitterStore;
      resetActiveProfile();
    }
    if (loginStore.is_logged_in) {
      twitterStore.setProfileUrlParameter(profile_id);

      twitterStore.getAllProfilesAndVideos().then(() => {
        if (profile_id) {
          const { setActiveProfile } = twitterStore;
          setActiveProfile(profile_id);
        }
      });
    }
  }

  // tslint:disable-next-line:prefer-function-over-method
  render() {
    const { profile_id } = this.state;
    const { twitterStore } = mainStore;
    const { resetActiveProfile, twitter_profile_store } = twitterStore;
    const { active_profile } = twitter_profile_store;
    const page_title =
      profile_id && active_profile ? `Callgirl index - Twitter profile ${active_profile.screen_name}` : `Callgirl index - Twitter videos`;
    return (
      <div className="container-fluid">
        <Helmet>
          <title>{page_title}</title>
        </Helmet>
        <div className="row">
          <div className="col-lg-3 ">
            <TwitterVideoListOverview />
          </div>
          <div className="col-lg-6">
            {/* <Switch>
              <Route path={`/twitter/:profile_id`} component={() => <>"test2"</>} />
              <Route exact={true} path={`/twitter/`} component={() => <>"test2123"</>} />
            </Switch> */}

            {!active_profile && <TwitterProfileListOverview />}

            <ActiveProfileSection active_profile={active_profile} />
          </div>
          <div className="col-lg-3">
            <TwitterProfileDetail />
            <TwitterProfileType />
            <TwitterProfileFavorite />
            <TwitterProfileFollowed />
            {active_profile && <ProfileMergerComponent profile_id={active_profile.id} />}
            <div style={{ marginTop: 10, marginBottom: 10 }}>
              {active_profile && (
                <ButtonAndModal position="left" buttonText="Profiles" modalWidth={500} modalTopExtra={-100}>
                  <TwitterProfileListOverview />
                </ButtonAndModal>
              )}
            </div>
            {active_profile && (
              <LinkItem onClick={() => resetActiveProfile()} to_link={`/twitter/`}>
                Back to Overview
              </LinkItem>
            )}

            <div style={{ marginTop: 10 }}>
              <TweetVideo />
            </div>
            <div style={{ marginTop: 10 }}>
              <TwitterAllVideosDownloadButton />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export { TwitterPage };
