import LocalizedStrings from "react-localization";

const strings = new LocalizedStrings({
  da: {
    profileHeader: "Profiler"
  },
  es: {
    profileHeader: "Anuncios"
  }
});

export { strings };
