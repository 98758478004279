import * as toastr from "toastr";
import { render } from "react-dom";
import { Button } from "./Button";

toastr.options.positionClass = "toast-bottom-left";

class ToastrManager {
  static success(text: string) {
    toastr.success(text);
  }

  static successRight(text: string) {
    toastr.success(text, undefined, { positionClass: "toast-bottom-right" });
  }

  static successRightWithRegret(reactElement: JSX.Element, entity_id: number) {
    const id = `regret_${entity_id}`;

    toastr.success(`<div id="${id}" ></div>`, undefined, {
      positionClass: "toast-bottom-right",
      timeOut: 7000
    });
    const container = $(`#${id}`).get(0);
    render(reactElement, container);
  }

  static error(text: string) {
    toastr.error(text);
  }
}

export { ToastrManager };
