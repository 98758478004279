import { observer } from "mobx-react";
import * as React from "react";
import { Component } from "react";

interface IProfileHasMoreVideosProps {
  api_has_more_videos: boolean;
  twint_has_more_videos: boolean;
}

@observer
class ProfileHasMoreVideos extends Component<IProfileHasMoreVideosProps> {
  // tslint:disable-next-line:prefer-function-over-method
  render() {
    const { api_has_more_videos, twint_has_more_videos } = this.props;

    const style = { lineHeight: 1.45, backgroundColor: "#3c763d" };
    return (
      <>
        {twint_has_more_videos && (
          <span style={style} className="label label-default">
            T&nbsp;
            <i className="fa fa-plus" aria-hidden="true" />
          </span>
        )}
        {api_has_more_videos && (
          <span style={style} className="label label-default">
            A&nbsp;
            <i className="fa fa-plus" aria-hidden="true" />
          </span>
        )}
      </>
    );
  }
}

export { ProfileHasMoreVideos };
