import { Stack } from "components/common";
import { MomentDate } from "library";
import { observer } from "mobx-react";
import { Profile } from "models/Profile";
import { Component } from "react";
import * as React from "react";

import { ProfileAttributeLabel } from "./ProfileAttributeLabel";

interface IProfileCoreProfileAttributeProps {
  profile: Profile;
}

@observer
class ProfileCoreProfileAttribute extends Component<IProfileCoreProfileAttributeProps, any> {
  render() {
    const profile = this.props.profile;
    const { updated_date, import_date, age, overview_price } = profile;

    const last_update_date = updated_date || import_date;

    return (
      <div style={{ position: "absolute" }} className="profile-attributes">
        <div style={{ position: "absolute", zIndex: 10, top: -10, left: 0 }}>
          <Stack size={"small"}>
            <div>
              <ProfileAttributeLabel
                is_visible={true}
                labelText={MomentDate.getDatePretty(last_update_date)}
                title="Date of when the profile was last updated."
                isTagActive={false}
              />
            </div>

            {age && (
              <div>
                <ProfileAttributeLabel
                  is_visible={true}
                  labelText={age.toString() + " years"}
                  title="The age of the girl."
                  isTagActive={false}
                />
              </div>
            )}
            {overview_price && (
              <div>
                <ProfileAttributeLabel is_visible={true} labelText={overview_price} isTagActive={false} />
              </div>
            )}
          </Stack>
        </div>
      </div>
    );
  }
}

export { ProfileCoreProfileAttribute };
