import { DarkGreenBadge } from "components/common";
import { observer } from "mobx-react";
import { Component } from "react";
import * as React from "react";
import { mainStore } from "store";

interface ITwitterVideoTopTagsProps {
  handleLinkClick: (event: React.MouseEvent<HTMLAnchorElement>) => void;
}

@observer
class TwitterVideoTopTags extends Component<ITwitterVideoTopTagsProps, any> {
  render() {
    const { handleLinkClick } = this.props;
    const { twitterStore } = mainStore;
    const { activeVideoOtherScreenName, isFollowingActiveScreenName, twitter_video_store } = twitterStore;
    const { current_active_video } = twitter_video_store;
    if (!current_active_video) {
      return null;
    }
    const { is_watched, bitrate } = current_active_video;

    return (
      <div style={{ position: "absolute" }}>
        <div style={{ position: "relative", zIndex: 10, top: 5 }}>
          <DarkGreenBadge label={`Bitrate: ${bitrate}`} />
          {is_watched && <DarkGreenBadge label="W" />}

          {activeVideoOtherScreenName && (
            <a
              href={`https://twitter.com/${activeVideoOtherScreenName}`}
              onClick={handleLinkClick}
              target="_blank"
              style={{ marginLeft: 10, background: "white", borderRadius: 4, padding: "4px 8px" }}
            >
              {`@${activeVideoOtherScreenName}`} <i className="fa fa-external-link" />
            </a>
          )}
          {!isFollowingActiveScreenName && <DarkGreenBadge label="UF" />}
        </div>
      </div>
    );
  }
}

export { TwitterVideoTopTags };
