import CommonRestApi from "./CommonRestApi";
import { ITwitterVideo, DeleteReason, ITwitterVideoCounts, ITwitterProfile } from "models/twitter";

class TwitterVideoApi extends CommonRestApi {
  constructor() {
    super("twitter_videos");
  }

  deleteVideoWithReason = (video_id: number, reason: DeleteReason) => {
    const url = `${this.list_action}${video_id}/${reason}/`;
    return this.postUrl(url);
  };

  setDownloadedToClientDate = (video_ids: Array<number>) => {
    const url = `${this.list_action}set_downloaded_to_client/`;
    return this.postUrl(url, { video_ids });
  };

  setVideoType = (video_id: number, video_type: number) => {
    const url = `${this.list_action}${video_id}/type/`;
    return this.postUrl(url, { video_type });
  };

  moveToProfile = (video_id: number, profile_id: number) => {
    const url = `${this.list_action}${video_id}/move_to_profile/`;
    return this.postUrl(url, { profile_id });
  };

  setIsWatched = (video_id: number) => {
    const url = `${this.list_action}${video_id}/watched/`;
    return this.postUrl(url);
  };

  setIsFavorite = (video_id: number, favorite_select: number) => {
    const url = `${this.list_action}${video_id}/favorite/`;
    return this.postUrl(url, { favorite_select });
  };

  setIsExcluded = (video_id: number, is_excluded: boolean) => {
    const url = `${this.list_action}${video_id}/exclude/`;
    return this.postUrl(url, { is_excluded });
  };

  setIsChecked = (video_id: number, checked_select: number) => {
    const url = `${this.list_action}${video_id}/check/`;
    return this.postUrl(url, { checked_select });
  };

  regretDelete = (video_id: number) => {
    const url = `${this.list_action}${video_id}/regret_delete/`;
    return this.postUrl(url);
  };

  getVideoCounts = (): Promise<{ counts: ITwitterVideoCounts }> => {
    return this.getApiUrl("counts");
  };
}

class TwitterApi extends CommonRestApi {
  twitter_video_api: TwitterVideoApi;
  constructor() {
    super("twitter_profiles");
    this.twitter_video_api = new TwitterVideoApi();
  }

  getVideos = (profile_id: number): Promise<Array<ITwitterVideo>> => {
    return this.getSubDetail(profile_id, "videos");
  };

  getAllRecursively = (url: string, allArray: Array<any>): Promise<Array<any>> => {
    return this.getUrl(url).then(videos => {
      allArray = allArray.concat(videos.results);
      if (videos.next) {
        return this.getAllRecursively(videos.next, allArray);
      } else {
        return allArray;
      }
    });
  };

  getAllVideos = (): Promise<Array<ITwitterVideo>> => {
    let allVideos: Array<ITwitterVideo> = [];
    return this.twitter_video_api.getAll().then(videos => {
      allVideos = allVideos.concat(videos.results);
      if (videos.next) {
        return this.getAllRecursively(videos.next, allVideos);
      } else {
        return allVideos;
      }
    });
  };

  getAllProfiles = (): Promise<Array<ITwitterProfile>> => {
    let allProfiles: Array<ITwitterProfile> = [];
    return this.getAll().then(profiles => {
      allProfiles = allProfiles.concat(profiles.results);
      if (profiles.next) {
        return this.getAllRecursively(profiles.next, allProfiles);
      } else {
        return allProfiles;
      }
    });
  };

  setProfileType = (profile_id: number, profile_type: number) => {
    const url = `${this.list_action}${profile_id}/type/`;
    return this.postUrl(url, { profile_type });
  };

  setIsFavorite = (profile_id: number) => {
    const url = `${this.list_action}${profile_id}/favorite/`;
    return this.postUrl(url);
  };

  unsetIsFavorite = (profile_id: number) => {
    const url = `${this.list_action}${profile_id}/unfavorite/`;
    return this.postUrl(url);
  };

  setIsFollowed = (profile_id: number) => {
    const url = `${this.list_action}${profile_id}/follow/`;
    return this.postUrl(url);
  };

  setDownloadedToClientDate = (video_ids: Array<number>) => {
    return this.twitter_video_api.setDownloadedToClientDate(video_ids);
  };

  unsetIsFollowed = (profile_id: number) => {
    const url = `${this.list_action}${profile_id}/unfollow/`;
    return this.postUrl(url);
  };

  mergeToProfile = (profile_id: number, destination_profile_id: number) => {
    const url = `${this.list_action}${profile_id}/merge/`;
    return this.postUrl(url, { merge_to_profile_id: destination_profile_id });
  };

  deleteVideoWithReason = (video_id: number, reason: DeleteReason) => {
    return this.twitter_video_api.deleteVideoWithReason(video_id, reason);
  };

  setVideoType = (video_id: number, video_type: number) => {
    return this.twitter_video_api.setVideoType(video_id, video_type);
  };

  moveToProfile = (video_id: number, profile_id: number) => {
    return this.twitter_video_api.moveToProfile(video_id, profile_id);
  };

  setIsWatched = (video_id: number) => {
    return this.twitter_video_api.setIsWatched(video_id);
  };

  setVideoIsFavorite = (video_id: number, favorite_select: number) => {
    return this.twitter_video_api.setIsFavorite(video_id, favorite_select);
  };

  setVideoIsExcluded = (video_id: number, is_excluded: boolean) => {
    return this.twitter_video_api.setIsExcluded(video_id, is_excluded);
  };

  setVideoIsChecked = (video_id: number, checked_select: number) => {
    return this.twitter_video_api.setIsChecked(video_id, checked_select);
  };

  regretVideoDelete = (video_id: number) => {
    return this.twitter_video_api.regretDelete(video_id);
  };

  getVideoCounts = () => {
    return this.twitter_video_api.getVideoCounts();
  };
}

export { TwitterApi };
