import { Button } from "components/common";
import { hashIdManager } from "library/hashids";
import loader from "library/loader";
import { strings } from "library/localization";
import { queryParser } from "library/query-parser";
import { UrlCreator } from "library/url-creator";
import * as _ from "lodash";
import { when } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import { Component } from "react";
import { Helmet } from "react-helmet";
import { match } from "react-router";
import { mainStore } from "store";
import { colors } from "styles";

import ProfileList from "./ProfileList";
import { ProfileOrderingTags } from "./ProfileOrderingTags";
import { ProfilePictureModal } from "./ProfilePictureModal";

interface IProfilePageProps {
  location?: { query: { search_term: string; print?: string }; search: string };
  match: match<{ type: string; spider_hash: string }>;
  history?: any;
}

interface IProfilePageState {
  isLoading: boolean;
}

@observer
class ProfilePage extends Component<IProfilePageProps, IProfilePageState> {
  imageModal: HTMLDivElement;

  constructor(props: IProfilePageProps) {
    super(props);
    this.state = {
      isLoading: false
    };
    const { loginStore, profileStore } = mainStore;
    const page_type = this.getPageType(this.props);
    const print = this.props.location.query && this.props.location.query.print;

    let promise: Promise<void> | undefined = undefined;
    if (!loginStore.is_logged_in) {
      this.props.history.push("/");
    } else if (!profileStore.isLoaded) {
      const spider_hash = this.props.match.params.spider_hash;
      if (spider_hash) {
        promise = when(() => mainStore.misc_data && mainStore.misc_data.cities && mainStore.misc_data.cities.length > 0);
        promise.then(() => {
          const city = UrlCreator.getCityFromLink(mainStore.misc_data.cities, spider_hash);

          if (city) {
            mainStore.setCityId(city);
          } else {
            const spider_id = hashIdManager.getSiteIdFromHash(spider_hash);
            mainStore.setSpiderId(spider_id, page_type);
          }
        });
      }
    }

    const parsedPrevQuery = queryParser.parse(this.props.location.search);
    const search_term = parsedPrevQuery.search_term;

    $(window).scrollTop(0);

    profileStore.set_profile_type(page_type);
    if (promise) {
      promise.then(() => {
        this.EnsureSearchItems(search_term);
      });
    } else {
      this.EnsureSearchItems(search_term);
    }

    if (print) {
      mainStore.setPrintMode();
    }
  }

  getPageType = (props: IProfilePageProps) => {
    let type = props.match.params.type;

    const parsedQuery = queryParser.parse(props.location.search);
    if (parsedQuery.search_term) {
      type = "search";
    }
    return type;
  };

  EnsureSearchItems = (search_term: string) => {
    const { profileStore } = mainStore;
    if (profileStore.current) {
      if (search_term) {
        profileStore.current.setItems(search_term);
      }
      profileStore.current.resetPageSizeAndOffsets();
    }
  };

  // tslint:disable-next-line:prefer-function-over-method
  componentDidMount() {
    let hasAdded = false;
    const { profileStore, isOpeningNewSite } = mainStore;
    if (!profileStore.current) {
      return;
    }

    if (!profileStore.isLoaded || isOpeningNewSite) {
      loader.startProfileLoad();
    }
    loader.startBottomLoad();

    $(window).scroll(
      _.debounce(function() {
        const scrollPosition = $(window).scrollTop();
        const bodyHeight = $(document).height() - $(window).height();

        if (scrollPosition > bodyHeight - 1000) {
          if (!profileStore.current.longLoading) {
            profileStore.current.increasePageSize();
          } else {
            if (!hasAdded) {
              hasAdded = true;
              profileStore.current.loadLongPromise.then(() => {
                profileStore.current.increasePageSize();
              });
            }
          }
        } else if (scrollPosition < 1300) {
          // if (scrollPosition < 300 && profileStore.current.current_page_offset > 0) {
          //   $(window).scrollTop(300);
          // }
          // profileStore.current.decreasePageSize();
        }
      }, 150)
    );
  }

  // tslint:disable-next-line:prefer-function-over-method
  componentWillUnmount() {
    $(window).off("scroll");
  }

  // tslint:disable-next-line:prefer-function-over-method
  componentDidUpdate(prevProps: IProfilePageProps, prevState: IProfilePageState) {
    const { profileStore, isOpeningNewSite } = mainStore;

    if (profileStore.current && profileStore.current.isLoading && this.state.isLoading == false) {
      this.setState({ isLoading: true });
    }

    if (profileStore.current && !profileStore.current.isLoading && this.state.isLoading) {
      this.setState({ isLoading: false });
    }

    if (!this.state.isLoading && prevState.isLoading) {
      loader.stopProfileLoad();
    }

    if (isOpeningNewSite) {
      mainStore.isOpeningNewSite = false;
      loader.stopProfileLoad();
    }

    const nextProps = this.props;
    const type = nextProps.match.params.type;
    const parsedQuery = queryParser.parse(nextProps.location.search);
    const search_term = parsedQuery.search_term;
    const page_type = this.getPageType(nextProps);
    const previous_page_type = this.getPageType(prevProps);
    if (page_type != previous_page_type) {
      profileStore.set_profile_type(page_type);
    }
    const parsedPrevQuery = queryParser.parse(prevProps.location.search);
    const prev_search_term = parsedPrevQuery.search_term;
    if (prevProps.match.params.type !== type && page_type != "search") {
      loader.stopProfileLoad();
    }
    if (prevProps.match.params.type !== type || prev_search_term !== search_term) {
      this.EnsureSearchItems(search_term);
    }
  }

  // tslint:disable-next-line:prefer-function-over-method
  render() {
    const { profileStore, loginStore } = mainStore;
    const { profile_type_text, loadAllProfiles } = profileStore;
    const isSearchProfileType = profileStore.profile_type === "search";
    const currentProfileStore = profileStore.current;
    if (!currentProfileStore) {
      return null;
    }
    const hasProfiles = currentProfileStore.count !== 0;
    const noSearchResults = isSearchProfileType && !hasProfiles && !currentProfileStore.isLoading;
    const pageTitleText = profileStore.pageTitle;

    let activeClass = "";
    if (currentProfileStore && currentProfileStore.IsBottomLoaderVisible) {
      activeClass = "active";
    }
    const profileCount = currentProfileStore.count;
    const {
      atleastOneProfileHasVideo,
      atleastOneProfileDoesOutcall,
      atleastOneProfileWorksAfterMidnight,
      atleastOneProfileWhatsApp,
      atleastOneProfileBrazilian,
      atleastOneProfileIndependent,
      atleastOneProfileDoesAnal,
      atleastOneProfileHasReviews,
      atleastOneProfileHasViews,
      atleastOneProfileIsFavorite,
      atleastOneProfileHasAge
    } = currentProfileStore;

    let loadAllDisplayStyle =
      loginStore.is_staff && !currentProfileStore.longLoading && currentProfileStore.canLoadMore
        ? { display: "inline-block" }
        : { display: "none" };
    loadAllDisplayStyle = Object.assign({}, loadAllDisplayStyle, { fontSize: 12, marginLeft: 10, verticalAlign: "top" });

    return (
      <div className="container-fluid outer">
        <Helmet>
          <title>{pageTitleText}</title>
        </Helmet>
        <h3 className="header" style={{ marginLeft: 5 }}>
          {strings.profileHeader}
          <span className="label label-default" style={{ verticalAlign: "middle", backgroundColor: "#f2a1a1" }}>
            {profile_type_text}
          </span>
          {isSearchProfileType && hasProfiles && (
            <span
              title={`Der findes ${profileCount} profiler i resultatet for denne søgning`}
              className="badge"
              style={{ fontSize: 19, marginLeft: 5, background: colors.DarkGreen }}
            >
              {profileCount}
            </span>
          )}
          <ProfileOrderingTags
            atleastOneProfileHasVideo={atleastOneProfileHasVideo}
            atleastOneProfileDoesOutcall={atleastOneProfileDoesOutcall}
            atleastOneProfileWorksAfterMidnight={atleastOneProfileWorksAfterMidnight}
            atleastOneProfileWhatsApp={atleastOneProfileWhatsApp}
            atleastOneProfileBrazilian={atleastOneProfileBrazilian}
            atleastOneProfileIndependent={atleastOneProfileIndependent}
            atleastOneProfileDoesAnal={atleastOneProfileDoesAnal}
            atleastOneProfileHasReviews={atleastOneProfileHasReviews}
            atleastOneProfileHasViews={atleastOneProfileHasViews}
            atleastOneProfileIsFavorite={atleastOneProfileIsFavorite}
            atleastOneProfileHasAge={atleastOneProfileHasAge}
          />
          <div style={loadAllDisplayStyle}>
            <div className="get_all">
              <Button buttonSize="small" handleClick={loadAllProfiles}>
                Load all
              </Button>
            </div>
          </div>
          <div id="profile_loader" />
        </h3>

        {noSearchResults && "Søgningen fandt ingen profiler."}
        <ProfileList />
        {profileStore.current.isLoading && <div />}
        {mainStore.isOpeningNewSite && <div />}
        <div id="bottom_loader" className={activeClass} style={{ marginTop: 70 }} />
        <ProfilePictureModal />
      </div>
    );
  }
}

export default ProfilePage;
