import Axios, { AxiosInstance } from "axios";
import { action, observable } from "mobx";

class CommonRestApi {
  @observable csrftoken = "";
  axios: AxiosInstance;
  list_action: string;

  @action
  SetToken(token: string) {
    this.csrftoken = token;
    this.axios.defaults.headers.common["X-CSRFTOKEN"] = this.csrftoken;
  }

  constructor(module: string) {
    this.list_action = "/api/" + module + "/";
    this.axios = Axios.create();
    this.axios.defaults.headers.post["Content-Type"] = "application/json";
  }

  getAll(params?: { query?: string; url?: string }) {
    let fetchUrl = this.list_action;
    if (params) {
      const { query, url } = params;
      if (query) {
        fetchUrl += query;
      }
      if (url) {
        fetchUrl = url;
      }
    }
    return this.axios.get(fetchUrl).then(response => {
      response.data.last_url = fetchUrl;
      return response.data;
    });
  }

  save(data: any) {
    return this.axios.put(data.url, data).then(response => {
      return response.data;
    });
  }

  insert(data: any) {
    return this.axios.post(this.list_action, data).then(response => {
      return response.data;
    });
  }

  delete(data: any) {
    return this.axios
      .delete(data.url, data)
      .then(response => {
        return (response.status >= 200 && response.status < 300) || response.status === 404;
      })
      .catch(error => {
        const response = error.response;
        if (response.status === 500) {
          throw new Error(response.data);
        } else if (response === 404) {
          return true;
        }
        return response;
      });
  }

  postUrl(url: string, data?: any) {
    return this.axios
      .post(url, data)
      .then(response => {
        return response.data;
      })
      .catch(() => {
        throw { Message: "Operation blev ikke gennemførst pga. ukendt fejl, eller manglende internet forbindelse." };
      });
  }

  getUrl(url: string) {
    return this.axios.get(url).then(response => {
      return response.data;
    });
  }

  getApiUrl(url: string) {
    return this.axios.get(this.list_action + url + "/").then(response => {
      return response.data;
    });
  }

  getSubDetail(entity_id: number, detail_url: string) {
    return this.axios.get(`${this.list_action}${entity_id}/${detail_url}/`).then(response => {
      return response.data;
    });
  }
}

export default CommonRestApi;
