import { Component } from "react";
import * as React from "react";

interface IPanelProps {
  headerText?: string;
}

export class Panel extends Component<IPanelProps> {
  render() {
    const { headerText } = this.props;
    return (
      <div className="panel panel-default">
        {headerText && <div className="panel-heading">{headerText}</div>}
        <div className="panel-body">{this.props.children}</div>
      </div>
    );
  }
}
