import Hashids from "hashids";
import * as _ from "lodash";

class HashIdManager {
  siteHashIds = new Hashids("912FJsvk342", 3);
  profileHashIds = new Hashids("912FJsvk342097ad0jjj9mnmAMcmmVDMhd62", 8);

  getHashFromSiteId = (siteId: number | string) => {
    let id = -1;
    if (typeof siteId == "string") {
      id = Number(siteId);
    } else {
      id = siteId;
    }
    return this.siteHashIds.encode(id);
  };

  getSiteIdFromHash = (siteHash: string) => {
    let actualSiteHash = siteHash;
    if (_.includes(siteHash, "-")) {
      actualSiteHash = siteHash.split("-")[0];
    }
    return this.siteHashIds.decode(actualSiteHash)[0];
  };

  getHashFromProfileId = (profileId: number) => {
    return this.profileHashIds.encode(profileId);
  };

  getProfileIdFromHash = (profileHash: string) => {
    let actualHash = profileHash;
    if (_.includes(profileHash, "-")) {
      actualHash = profileHash.split("-")[0];
    }
    return this.profileHashIds.decode(actualHash)[0];
  };
}

const hashIdManager = new HashIdManager();

export { hashIdManager };
