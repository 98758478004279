import { DarkGreenBadge, Repeller } from "components/common";
import { observer } from "mobx-react";
import { Component } from "react";
import * as React from "react";
import { mainStore } from "store";

@observer
class TwitterProfileDetail extends Component {
  // tslint:disable-next-line:prefer-function-over-method
  render() {
    const { twitterStore } = mainStore;
    const { twitter_profile_store } = twitterStore;
    const { allProfiles, totalVideosCount, active_profile } = twitter_profile_store;

    return (
      <div style={{ paddingTop: 15 }}>
        <Repeller style={{ marginBottom: 5 }}>
          <div>
            <span>Total profiles</span>
            <DarkGreenBadge label={allProfiles.length} />
          </div>
          <div>
            <span>Total videos</span>
            <DarkGreenBadge label={totalVideosCount} />
          </div>
          {active_profile && <span style={{ display: "none" }} />}
        </Repeller>
      </div>
    );
  }
}

export { TwitterProfileDetail };
