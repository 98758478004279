import { Button } from "components/common";
import { observer, Provider } from "mobx-react";
import * as React from "react";
import { Component } from "react";
import { ModalStore } from "store/ModalStore";

import { Modal, ModalPosition } from "./Modal";

interface IButtonAndModalProps {
  buttonText: string;
  position: ModalPosition;
  modalWidth: number;
  modalTopExtra?: number;
}

interface IButtonAndModalState {
  buttonLeftOffset: number;
  buttonTopOffset: number;
}

@observer
class ButtonAndModal extends Component<IButtonAndModalProps, IButtonAndModalState> {
  modalStore: ModalStore;
  buttonComponent: Button;

  constructor(props: any) {
    super(props);

    this.modalStore = new ModalStore();
    this.state = {
      buttonLeftOffset: -1500,
      buttonTopOffset: -1500
    };
  }

  componentDidUpdate() {
    const offset = $(this.buttonComponent.button).offset();
    const hasNewOffset = this.state.buttonLeftOffset != offset.left || this.state.buttonTopOffset != offset.top;
    if (document.documentElement.scrollHeight != $(window).height() || hasNewOffset) {
      this.setButtonOffset();
    }
  }

  componentDidMount() {
    this.setButtonOffset();
  }

  setButtonOffset = () => {
    const offset = $(this.buttonComponent.button).offset();
    if (
      this.buttonComponent &&
      this.buttonComponent.button &&
      (offset.left != this.state.buttonLeftOffset || this.state.buttonTopOffset != offset.top)
    ) {
      this.setState({ buttonLeftOffset: offset.left, buttonTopOffset: offset.top });
    }
  };

  // tslint:disable-next-line:prefer-function-over-method
  render() {
    const { buttonText, position, modalWidth, modalTopExtra } = this.props;
    const isVisible = this.modalStore.isOpen;

    const top = this.state.buttonTopOffset;
    const left = this.state.buttonLeftOffset;
    let buttonHeight = 0;
    let buttonWidth = 0;
    if (this.buttonComponent && this.buttonComponent.button) {
      buttonHeight = $(this.buttonComponent.button).outerHeight();
      buttonWidth = $(this.buttonComponent.button).outerWidth();
    }

    return (
      <>
        <Button ref={element => (this.buttonComponent = element)} handleClick={this.modalStore.handleOpenModal}>
          {buttonText}
        </Button>
        <Provider modalStore={this.modalStore}>
          <Modal
            width={modalWidth}
            buttonHeight={buttonHeight}
            buttonWidth={buttonWidth}
            position={position}
            buttonOffsetLeft={left}
            buttonOffsetTop={top}
            modalTopExtra={modalTopExtra}
            isOpen={isVisible}
          >
            {this.props.children}
          </Modal>
        </Provider>
      </>
    );
  }
}

export { ButtonAndModal };
