import { observer } from "mobx-react";
import * as React from "react";

import { mainStore } from "store";
import SignUpFormStore from "store/SignUpFormStore";
import LabeledInput from "components/common/LabeledInput";
import TextInput from "components/common/TextInput";

@observer
export default class SignUpForm extends React.Component {
  signUpFormStore: SignUpFormStore;
  constructor(props: any, context: any) {
    super(props, context);
    const { loginStore } = mainStore;
    this.signUpFormStore = new SignUpFormStore(loginStore);
  }

  render() {
    return (
      <form>
        <h3>Opret ny bruger</h3>
        <TextInput validatedInput={this.signUpFormStore.username} />

        <LabeledInput validatedInput={this.signUpFormStore.password} input_type="password" />

        <input
          type="submit"
          disabled={this.signUpFormStore.saving}
          value={this.signUpFormStore.saving ? "Opretter..." : "Opret"}
          onClick={this.signUpFormStore.signUpUser}
          className="btn btn-primary"
        />
      </form>
    );
  }
}
