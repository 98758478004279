import { observer } from "mobx-react";
import { Status } from "models/Profile";
import { Component } from "react";
import * as React from "react";
import { mainStore } from "store";
import { ProfileData } from "store/ProfileData";

import { MoveButtonsCollapseButton } from "./MoveButtonsCollapseButton";
import ProfileDisplay from "./ProfileDisplay";
import ProfileHeader from "./ProfileHeader";
import ProfileMoveButtons from "./ProfileMoveButtons";
import ProfilePictureList from "./ProfilePictureList";
import profileApi from "api/profileApi";
import { IVideo } from "models";

interface IProfileListRowProps {
  status: Status;
  profileData: ProfileData;
  page_list_type: string;
}

interface IProfileListRowState {
  videos: Array<IVideo>;
  has_fetched_videos: boolean;
}

@observer
class ProfileListRow extends Component<IProfileListRowProps, IProfileListRowState> {
  collapseElement: HTMLDivElement;
  moveButtonsCollapseElement: HTMLDivElement;
  list_item: HTMLLIElement;

  constructor(props: IProfileListRowProps) {
    super(props);

    this.state = {
      videos: [],
      has_fetched_videos: false
    };
  }

  componentDidMount() {
    const tooltips: any = $('[data-toggle="tooltip"]');
    tooltips.tooltip();
    if (mainStore.profileStore.isPrintMode) {
      $(this.collapseElement).addClass("in");
    }
  }

  handleCollapseClick = (event: React.SyntheticEvent<any>) => {
    event.preventDefault();
    const { status } = this.props;
    const { profile } = status;
    $(this.collapseElement).toggleClass("in");
    if (profile.has_video && !this.state.has_fetched_videos) {
      profileApi.getVideos(profile.id).then(videos => {
        this.setState({ videos, has_fetched_videos: true });
      });
    }
  };

  handleMoveButtonsCollapseClick = (event: React.SyntheticEvent<any>) => {
    event.preventDefault();
    $(this.moveButtonsCollapseElement).toggleClass("in");
  };

  render() {
    const { profileData, status } = this.props;
    const { profile, type } = status;
    const { videos } = this.state;
    const website = mainStore.getWebsite(profile.website_id.toString());
    const { country } = website.city;
    const isNewProfile = type === "new";

    return (
      <li className="list-group-item" ref={element => (this.list_item = element)}>
        <div className="profile">
          <ProfileHeader
            status={this.props.status}
            page_list_type={this.props.page_list_type}
            handleCollapseClick={this.handleCollapseClick}
          />

          <div ref={element => (this.collapseElement = element)} className="collapse profile-collapse" style={{ marginBottom: 10 }}>
            <MoveButtonsCollapseButton handleCollapseClick={this.handleMoveButtonsCollapseClick} />
            <div ref={element => (this.moveButtonsCollapseElement = element)} className="collapse move-buttons-collapse">
              <ProfileMoveButtons status={this.props.status} />
            </div>
            <ProfileDisplay
              profileData={profileData}
              videos={videos}
              spider_name={website.spider_name}
              phoneNumberCountryCode={country.phone_number_code}
            />
          </div>

          {profile.profileData.filteredPictures.length !== 0 && <ProfilePictureList profile={profile} />}

          {isNewProfile && <ProfileMoveButtons status={this.props.status} cssClass="new-profile" />}
        </div>
      </li>
    );
  }
}

export default ProfileListRow;
