import { action } from "mobx";

import FormStore from "./FormStore";
import { LoginStore } from "./LoginStore";
import { ToastrManager } from "components/common";
import { ValidatedInput } from "library";
import { ILoginFormData } from "models";

export default class SignupFormStore extends FormStore<ILoginFormData> {
  loginStore: LoginStore;
  password: ValidatedInput;
  username: ValidatedInput;

  constructor(loginStore: LoginStore) {
    super();
    this.loginStore = loginStore;
    this.password = new ValidatedInput("password", "Kodeord");
    this.username = new ValidatedInput("username", "Brugernavn");
    this.validatedInputs = [this.password, this.username];
  }

  @action
  resetState() {
    this.password.value = "";
    this.saving = false;
  }

  @action
  signUpUser = (event: React.SyntheticEvent<any>) => {
    this.resetErrors();
    this.saving = true;
    event.preventDefault();

    this.validate(4);

    if (this.hasErrors) {
      this.resetState();
      return;
    }

    this.loginStore.SignUp(this.data).then(response => {
      if (response.IsSuccessful) {
        ToastrManager.success("Brugeroprettelse gennemført");
        window.scrollTo(0, 0);
        // TODO MOVE HISTORY PUSH TO A REACT COMPONENT
        // tslint:disable-next-line
        // this.props.history.push("/profiles/new/");
      } else {
        if (response.message === "UNIQUE_USERNAME") {
          ToastrManager.error("Brugeroprettelse fejlede fordi brugernavnet allerede findes.");
        } else {
          ToastrManager.error("Brugeroprettelse fejlede pga. ugyldigt brugernavn eller kodeord.");
        }
      }
      this.resetState();
    });
  };
}
