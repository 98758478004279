import { ToastrManager } from "components/common";
import LabeledInput from "components/common/LabeledInput";
import TextInput from "components/common/TextInput";
import { observer } from "mobx-react";
import { Component } from "react";
import * as React from "react";
import { mainStore } from "store";
import LoginFormStore from "store/LoginFormStore";
import { RouteComponentProps, withRouter } from "react-router-dom";

@observer
class LoginForm extends Component<any, any> {
  loginFormStore: LoginFormStore;

  constructor(props: any, context: any) {
    super(props, context);
    const { loginStore } = mainStore;
    this.loginFormStore = new LoginFormStore(loginStore);
  }

  loginUser = (event: React.SyntheticEvent<any>) => {
    event.preventDefault();
    this.loginFormStore.loginUser().then(isSuccessful => {
      if (isSuccessful) {
        window.scrollTo(0, 0);
        ToastrManager.success("Login gennemført");

        mainStore.getMiscData();
        this.props.history.push("/overview/");
      } else {
        ToastrManager.error("Login fejlede pga. ugyldigt brugernavn eller kodeord.");
      }
    });
  };

  render() {
    return (
      <form>
        <h3>Login</h3>
        <TextInput validatedInput={this.loginFormStore.username} />

        <LabeledInput validatedInput={this.loginFormStore.password} input_type="password" />

        <input
          type="submit"
          disabled={this.loginFormStore.saving}
          value={this.loginFormStore.saving ? "Logger ind..." : "Log ind"}
          onClick={this.loginUser}
          className="btn btn-primary"
        />
      </form>
    );
  }
}

const LoginFormWithRouter = withRouter<RouteComponentProps<any>>(LoginForm);

export { LoginFormWithRouter as LoginForm };
