import * as React from "react";
import { MomentDate } from "library";

interface IAvailableDateProps {
  is_available_date: string;
}

class AvailableDate extends React.Component<IAvailableDateProps, any> {
  render() {
    const { is_available_date } = this.props;

    if (!is_available_date) {
      return null;
    }
    const is_available_date_formatted = MomentDate.getDateAndTimePretty(is_available_date);

    return (
      <p>
        <strong>Tilføjet: </strong>d. {is_available_date_formatted}
      </p>
    );
  }
}

export default AvailableDate;
