import { observable, action } from "mobx";
import { createTransformer } from "mobx-utils";
import { PictureApi } from "api";
import { Picture } from "models/Profile";

class PictureSettingStore {
  @observable isOpenMap: Map<number, boolean>;

  pictureApi: PictureApi;

  constructor() {
    this.isOpenMap = new Map<number, boolean>();
    this.pictureApi = new PictureApi();
  }

  @action
  resetIsOpenMap = () => {
    // tslint:disable-next-line:no-unused
    this.isOpenMap.forEach((isOpen, pictureId) => {
      this.isOpenMap.set(pictureId, false);
    });
  };

  @action
  handleExcludePictureClick = (picture: Picture) => {
    this.pictureApi.excludePicture(picture.id).then(() => {
      this.resetIsOpenMap();
      picture.is_excluded = true;
    });
  };

  @action
  handleOpenSettingClick = (pictureId: number) => {
    const isOpen = this.isOpenForPicture(pictureId);
    if (!isOpen) {
      this.resetIsOpenMap();
    }
    this.isOpenMap.set(pictureId, !isOpen);
  };

  isOpenForPicture = createTransformer((pictureId: number) => {
    return this.isOpenMap.get(pictureId);
  });
}

export { PictureSettingStore };
