import { TwitterApi } from "api";
import { action, computed, observable } from "mobx";
import { DeleteReason, ITwitterVideo, ITwitterProfile } from "models/twitter";

import { TwitterProfileStore } from "./TwitterProfileStore";
import { TwitterVideoStore } from "./TwitterVideoStore";

class TwitterStore {
  private twitter_api: TwitterApi;

  @observable twitter_video_store: TwitterVideoStore;
  @observable twitter_profile_store: TwitterProfileStore;

  constructor() {
    this.twitter_api = new TwitterApi();
    this.twitter_video_store = new TwitterVideoStore();
    this.twitter_profile_store = new TwitterProfileStore();

    this.twitter_profile_store.resetProfilePaging();
    this.twitter_video_store.resetVideoPaging();
  }

  getAllProfilesAndVideos = (): Promise<void> => {
    return this.twitter_profile_store.getAllProfiles().then(() => {
      if (!this.twitter_profile_store.hasProfileQuery) {
        this.getAllVideos();
      }
    });
  };

  getAllVideos = () => {
    this.twitter_video_store.getAllVideos(this.setProfilesOnVideosFromApi);
  };

  @action setProfilesOnVideosFromApi = (videos: Array<ITwitterVideo>) => {
    videos.forEach(video => {
      const my_profile = this.twitter_profile_store.getProfileById(video.profile_id);
      video.profile = my_profile;
    });
  };

  setProfileUrlParameter = (profile_id?: string | number) => {
    const profile_url_id = this.twitter_profile_store.setProfileUrlParameter(profile_id);
    this.twitter_video_store.profile_url_id = profile_url_id;
  };

  setActiveProfile = (profile_id: number) => {
    this.twitter_profile_store.active_profile = this.twitter_profile_store.getProfileById(profile_id);
    this.twitter_video_store.emptyActiveVideos();
    this.twitter_api.getVideos(profile_id).then(videos => {
      this.setVideosFromApi(videos, profile_id);
    });
    this.twitter_video_store.resetAllFilters();
  };

  resetActiveProfile = () => {
    this.twitter_video_store.emptyActiveVideos();
    this.twitter_profile_store.active_profile = undefined;
    this.twitter_video_store.newVideoFilterIsActive = false;
    this.twitter_video_store.resetActiveVideo();
  };

  private resetActiveProfileIfInOverview = () => {
    if (this.twitter_video_store.hasOverviewVideos) {
      this.twitter_profile_store.active_profile = undefined;
    }
  };

  @action setVideosFromApi = (videos: Array<ITwitterVideo>, profile_id: number) => {
    if (this.twitter_profile_store.active_profile.id == profile_id) {
      this.twitter_video_store.setVideosFromApi(videos);

      if (this.twitter_video_store.current_active_videos.items.length > 0) {
        const video = this.twitter_video_store.current_active_videos.items[0];
        this.setActiveVideo(video);
      }
    }
  };

  @action private setActiveProfileFromVideo = (video: ITwitterVideo) => {
    if (video.profile) {
      this.twitter_profile_store.active_profile = video.profile;
    }
  };

  @action setActiveVideo = (video: ITwitterVideo) => {
    this.twitter_video_store.setActiveVideo(video);
    this.setActiveProfileFromVideo(video);
  };

  @action setActiveVideoFromId = (video_id: number) => {
    const video = this.twitter_video_store.setActiveVideoFromId(video_id);
    this.setActiveProfileFromVideo(video);
  };

  @action deleteVideoWithReason = (video_id: number, reason: DeleteReason) => {
    return this.twitter_video_store.deleteVideoWithReason(video_id, reason, this.handleDeleteVideoForProfile);
  };

  @action private handleDeleteVideoForProfile = (videoToBeRemoved: ITwitterVideo, profile?: ITwitterProfile) => {
    this.twitter_profile_store.active_profile.video_count -= 1;
    if (videoToBeRemoved.is_watched) {
      this.twitter_profile_store.active_profile.watched_video_count -= 1;
    }
    if (profile) {
      this.twitter_profile_store.active_profile = profile;
    }
  };

  setVideoType = (video_id: number, video_type: number) => {
    this.setIsWatched(video_id);
    return this.twitter_api.setVideoType(video_id, video_type);
  };

  @action removeAndSetActiveVideo = (video_id: number) => {
    const video = this.twitter_video_store.getVideoById(video_id);
    if (video.is_watched) {
      this.twitter_profile_store.active_profile.watched_video_count -= 1;
    }
    const new_active_video = this.twitter_video_store.removeAndSetActiveVideo(video_id);
    if (new_active_video.profile) {
      this.twitter_profile_store.active_profile = new_active_video.profile;
    }
    return video;
  };

  @action setIsWatched = (video_id: number) => {
    this.twitter_video_store.setIsWatched(video_id).then(is_watched_was_set => {
      if (is_watched_was_set) {
        this.twitter_profile_store.active_profile.watched_video_count += 1;
      }
    });
  };

  @action toggleVideoIsFrontFilter = () => {
    this.twitter_video_store.toggleVideoIsFrontFilter();
    this.resetActiveProfileIfInOverview();
  };

  @action toggleVideoIsCallGirlFilter = () => {
    this.twitter_video_store.toggleVideoIsCallGirlFilter();
    this.resetActiveProfileIfInOverview();
  };

  @action toggleVideoProfileIsFavoriteFilter = () => {
    this.twitter_video_store.toggleVideoProfileIsFavoriteFilter();
    this.resetActiveProfileIfInOverview();
  };

  @action toggleVideoProfileUnfavoriteFirstFilter = () => {
    this.twitter_video_store.toggleVideoProfileUnfavoriteFirstFilter();
    this.resetActiveProfileIfInOverview();
  };

  @action toggleVideoIsCheckedFilter = () => {
    this.twitter_video_store.toggleVideoIsCheckedFilter();
    this.resetActiveProfileIfInOverview();
  };

  @action toggleVideoNotYetCheckedFilter = () => {
    this.twitter_video_store.toggleVideoNotYetCheckedFilter();
    this.resetActiveProfileIfInOverview();
  };

  @action toggleVideoIsUnratedFilter = () => {
    this.twitter_video_store.toggleVideoIsUnratedFilter();
    if (this.twitter_video_store.videoIsUnratedFilter) {
      this.setActiveProfileFromVideo(this.twitter_video_store.new_active_videos[0]);
    } else {
      this.resetActiveProfileIfInOverview();
    }
  };

  @action toggleVideoIsAssFilter = () => {
    this.twitter_video_store.toggleVideoIsAssFilter();
    this.resetActiveProfileIfInOverview();
  };

  @action toggleVideoIsBreastsFilter = () => {
    this.twitter_video_store.toggleVideoIsBreastsFilter();
    this.resetActiveProfileIfInOverview();
  };

  @action toggleNewVideoFilter = () => {
    this.twitter_video_store.toggleNewVideoFilter();
    this.resetActiveProfileIfInOverview();
  };

  @computed get activeVideoOtherScreenName() {
    const video = this.twitter_video_store.active_video;

    const source_screen_name = video ? video.source_screen_name : undefined;
    const user_screen_name = video ? video.user_screen_name : undefined;
    const screen_name = this.twitter_profile_store.active_profile.screen_name;

    const shouldShowSourceScreenName = source_screen_name && source_screen_name != screen_name;
    const shouldShowAlternativeScreenName = user_screen_name && user_screen_name != screen_name;
    const shouldShowOtherScreenName = shouldShowSourceScreenName || shouldShowAlternativeScreenName;
    if (!shouldShowOtherScreenName) {
      return undefined;
    }

    return shouldShowSourceScreenName ? source_screen_name : user_screen_name;
  }

  @computed get isFollowingActiveScreenName() {
    if (!this.activeVideoOtherScreenName) {
      return true;
    }
    if (this.activeVideoOtherScreenName) {
      return this.twitter_profile_store.profiles.some(
        profile => profile.screen_name.toLowerCase() == this.activeVideoOtherScreenName.toLowerCase()
      );
    }
    return false;
  }

  @computed get hasVideos() {
    return this.twitter_video_store.hasVideos || this.twitter_profile_store.profileHasWatchedVideos;
  }
}

export { TwitterStore };
