import { Cluster } from "components/common";
import { NumberFormatter } from "library/numeral";
import { observer } from "mobx-react";
import { Profile } from "models/Profile";
import { Component } from "react";
import * as React from "react";
import { mainStore } from "store";

import { ProfileAttributeLabel } from "./ProfileAttributeLabel";

interface IProfileAttributeListProps {
  profile: Profile;
}

@observer
class ProfileAttributeList extends Component<IProfileAttributeListProps, any> {
  render() {
    const profile = this.props.profile;
    const {
      has_video,
      does_outcall,
      works_after_midnight,
      has_whatsapp,
      is_brazilian,
      is_independent,
      does_anal,
      reviews_count,
      is_agency,
      views_count
    } = profile;

    const { profileStore } = mainStore;
    const currentProfileStore = profileStore.current;
    const isTagActive = Boolean(currentProfileStore);

    return (
      <div className="profile-attributes" style={{ position: "absolute", zIndex: 10, height: 0 }}>
        <div style={{ position: "relative", bottom: 50 }}>
          <Cluster>
            <ProfileAttributeLabel is_visible={has_video} labelText="Video" isTagActive={isTagActive && currentProfileStore.has_video} />
            <ProfileAttributeLabel
              is_visible={does_outcall}
              labelText="Outcall"
              isTagActive={isTagActive && currentProfileStore.does_outcall}
            />

            <ProfileAttributeLabel
              is_visible={works_after_midnight}
              labelText="After midnight"
              isTagActive={isTagActive && currentProfileStore.works_after_midnight}
            />

            <ProfileAttributeLabel
              is_visible={Boolean(reviews_count)}
              labelText={"Reviews: " + reviews_count}
              isTagActive={isTagActive && currentProfileStore.has_reviews}
            />

            <ProfileAttributeLabel
              is_visible={Boolean(views_count)}
              labelText={"Views: " + NumberFormatter.getPrettyNumberByTenThousand(views_count)}
              isTagActive={isTagActive && currentProfileStore.has_views}
            />

            <ProfileAttributeLabel is_visible={is_agency} labelText={"Agency"} isTagActive={false} />

            <ProfileAttributeLabel
              is_visible={has_whatsapp}
              labelText="WhatsApp"
              isTagActive={isTagActive && currentProfileStore.has_whatsapp}
            />

            <ProfileAttributeLabel
              is_visible={is_independent}
              labelText="Independent"
              isTagActive={isTagActive && currentProfileStore.is_independent}
            />

            <ProfileAttributeLabel
              is_visible={is_brazilian}
              labelText="Brazilian"
              isTagActive={isTagActive && currentProfileStore.is_brazilian}
            />

            <ProfileAttributeLabel
              is_visible={Boolean(does_anal)}
              labelText="Greek"
              isTagActive={isTagActive && currentProfileStore.does_anal}
            />
          </Cluster>
        </div>
      </div>
    );
  }
}

export { ProfileAttributeList };
