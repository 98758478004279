import * as _ from "lodash";

class Sorter {
  public static sort<T>(items: Array<T>, selector: (item: T) => String | Number | Boolean) {
    return _.orderBy(items, [selector], ["asc"]);
  }

  public static sortDesc<T>(items: Array<T>, selector: (item: T) => String | Number | Boolean) {
    return _.orderBy(items, [selector], ["desc"]);
  }
}

export { Sorter };
