import { Button } from "components/common";
import { observer } from "mobx-react";
import * as React from "react";

interface IProfileTypeButtonProps {
  buttonText: string;
  handleTypeClick: (Profile_type: number) => void;
  is_saving: boolean;
  current_profile_type: number;
  profile_type: number;
}

@observer
class ProfileTypeButton extends React.Component<IProfileTypeButtonProps> {
  handleClick = () => {
    const { handleTypeClick, profile_type } = this.props;
    handleTypeClick(profile_type);
  };

  render() {
    const { buttonText, is_saving, current_profile_type, profile_type } = this.props;
    const disabled = current_profile_type == profile_type;
    return (
      <Button className="btn btn-primary" handleClick={this.handleClick} disabled={is_saving || disabled}>
        {buttonText}
      </Button>
    );
  }
}

export { ProfileTypeButton };
