import { action, computed, observable } from "mobx";

import { ToastrManager } from "components/common";
import constants from "constants/constants";
import { CommonRestApi } from "api";
import { IDatabaseObject, IPagedDatabaseResult, IWebsite } from "models";
import { Status } from "models/Profile";

class BaseStore<TObject extends IDatabaseObject> {
  defaultItem: TObject;
  default_max_profiles_count = 25;
  @observable items: Array<TObject> = [];
  @observable count = 0;
  @observable item = this.defaultItem;
  @observable current_page = 1;
  @observable page_type = "";
  @observable spider_id = "";
  @observable city_id?: number;
  @observable websites: Array<IWebsite>;
  @observable search_term: string;
  @observable loading = false;
  @observable longLoading = false;
  @observable loadLongPromise: Promise<any>;
  @observable current_page_offset = 0;
  @observable error_count = 0;
  @observable load_offset = 0;
  max_profiles_count = this.default_max_profiles_count;

  loadPromise: Promise<any>;
  reset = false;

  page_size = 10;

  api: CommonRestApi;

  constructor(api: CommonRestApi, page_type: string) {
    this.api = api;
    this.page_type = page_type;
    this.defaultItem = { id: -1 } as TObject;
    this.spider_id = "";
    this.websites = [];
  }

  @computed get hasError() {
    return this.error_count > 0;
  }

  @computed get isLoading() {
    if (this.hasError) {
      return false;
    }

    if (this.current_page === 1) {
      return this.loading;
    } else {
      return this.longLoading;
    }
  }

  @computed
  get statuses() {
    return this.items.slice(this.current_page_offset, this.current_page_offset + this.max_profiles_count);
  }

  @computed
  get IsBottomLoaderVisible() {
    const lastShownProfile = this.current_page_offset + this.max_profiles_count;
    const totalCountGreaterThanMaxCount = this.count > this.max_profiles_count;
    return lastShownProfile < this.count && totalCountGreaterThanMaxCount;
  }

  createQuery = (search_term: string) => {
    let query = "";
    if (this.city_id) {
      query = "city/" + this.city_id + "/";
    } else if (this.spider_id) {
      query = "site/" + this.spider_id + "/";
    }

    if (this.page_type) {
      query += this.page_type + "/";
    }

    const seperator = "?";

    if (search_term) {
      query += seperator + "search_term=" + search_term;
    }
    return query;
  };

  @action resetPageSizeAndOffsets() {
    this.current_page_offset = 0;
  }

  @action increasePageSize() {
    if (this.count > this.max_profiles_count + this.current_page_offset) {
      this.current_page_offset += 10;
      this.getNextPageItems();
    }
  }

  @action decreasePageSize() {
    if (this.current_page_offset >= 10) {
      this.current_page_offset -= 10;
    }
  }

  @action setCountAndItems(count: number, items: Array<any>) {
    this.count = count;
    this.items = items;
    this.reset = false;
    this.loading = false;
    this.load_offset += this.items.length;
    this.setExtraItemsData();
  }

  @action setOffsetCountAndItems(count: number, items: Array<any>) {
    let isCorrectProfiles = false;
    if (this.city_id) {
      const websites = this.websites.filter(item => item.city.id == this.city_id);
      isCorrectProfiles = websites.map(website => website.id).some(id => id == (items[0] as Status).profile.website_id);
    } else {
      isCorrectProfiles = (items[0] as Status).profile.website_id.toString() == this.spider_id;
    }

    if (items.length > 0 && isCorrectProfiles) {
      this.load_offset += items.length;
      this.count = count;
      this.items = this.items.concat(items);
      this.longLoading = false;
      this.setExtraItemsData();
    }
  }

  @action
  setItems(search_term?: string) {
    this.resetPageSizeAndOffsets();
    const is_search = Boolean(search_term);
    if (is_search) {
      if (this.search_term !== search_term) {
        this.reset = true;
      }
      this.search_term = search_term;
    }
    this.error_count = 0;
    const query = this.createQuery(this.search_term);

    const profiles = this.items;
    const startIndex = 0;
    const endIndex = this.page_size;

    const elementsNotLoaded = profiles.length < endIndex || profiles.slice(startIndex, endIndex).some(element => element === undefined);

    if ((elementsNotLoaded && (this.count === 0 || endIndex <= this.count)) || this.reset) {
      this.loading = true;
      let seperator = "?";
      if (query.includes("?")) {
        seperator = "&";
      }

      const shortStartParams = { query: query + seperator + "limit=" + this.page_size };

      this.loadPromise = this.api
        .getAll(shortStartParams)
        .then((itemsResults: IPagedDatabaseResult<TObject>) => {
          this.setCountAndItems(itemsResults.count, itemsResults.results);
        })
        .catch(() => {
          this.error_count += 1;
        });

      this.loadPromise.then(() => {
        if (this.count > this.page_size) {
          this.longLoading = true;

          let longStartParams = { query: `${query}${seperator}offset=${this.page_size}&limit=${this.max_profiles_count}` };
          if (is_search) {
            longStartParams = { query: `${query}${seperator}offset=${this.page_size}` };
          }
          this.loadLongPromise = this.api
            .getAll(longStartParams)
            .then((itemsResults: IPagedDatabaseResult<TObject>) => {
              this.setOffsetCountAndItems(itemsResults.count, itemsResults.results);
            })
            .catch(() => {
              this.error_count += 1;
            });
        }
      });
    }
  }

  @action getNextPageItems = () => {
    if (this.canLoadMore) {
      this.longLoading = true;

      const query = this.createQuery(undefined);
      const query_params = { query: `${query}?offset=${this.load_offset}&limit=${this.max_profiles_count}` };

      this.loadLongPromise = this.api
        .getAll(query_params)
        .then((itemsResults: IPagedDatabaseResult<TObject>) => {
          this.setOffsetCountAndItems(itemsResults.count, itemsResults.results);
        })
        .catch(() => {
          this.error_count += 1;
        });
    }
  };

  @action getAllPageItems = () => {
    if (this.canLoadMore) {
      this.longLoading = true;

      const query = this.createQuery(undefined);
      const query_params = { query: `${query}?offset=${this.load_offset}` };

      this.loadLongPromise = this.api
        .getAll(query_params)
        .then((itemsResults: IPagedDatabaseResult<TObject>) => {
          this.setOffsetCountAndItems(itemsResults.count, itemsResults.results);
        })
        .catch(() => {
          this.error_count += 1;
        });
    }
  };

  @action
  extractItem(itemId: number) {
    const otherItems = this.items.filter(item => item.id !== itemId);
    const extractedItem = this.items.find(item => item.id === itemId);
    if (extractedItem) {
      this.count = this.count - 1;
      this.items = otherItems;
    }
    return extractedItem;
  }

  @action
  setItem(itemId: number) {
    this.loadPromise.then(() => {
      const item = this.items.filter(itemObject => itemObject.id === itemId);
      if (item.length) {
        this.item = item[0];
      } else {
        this.item = this.defaultItem;
      }
    });
  }

  @action
  saveItem() {
    let promise = undefined;
    if (this.item.id) {
      promise = this.api.save(this.item).then((savedDataItem: TObject) => {
        const index = this.items.findIndex(item => item.id === savedDataItem.id);
        this.items[index] = savedDataItem;
      });
    } else {
      promise = this.api.insert(this.item).then((savedDataItem: TObject) => {
        this.items.push(savedDataItem);
      });
    }
    return promise
      .then(() => {
        return true;
      })
      .catch((error: any) => {
        ToastrManager.error(error);
        return false;
      });
  }

  @action
  deleteItem(itemId: number) {
    const item = this.items.filter(itemObject => itemObject.id === itemId)[0];

    this.api
      .delete(item)
      .then((success: boolean) => {
        if (success) {
          toastr.success("item deleted");
          this.items = this.items.filter(author => author.id !== itemId);
        }
      })
      .catch((error: any) => {
        if (error.message === constants.DELETE_FAILED) {
          ToastrManager.error("Cannot delete, because item is active.");
        } else {
          ToastrManager.error(error);
        }
      });
  }

  @computed get canLoadMore() {
    return this.load_offset < this.count;
  }

  // tslint:disable-next-line:no-empty
  @action setExtraItemsData = () => {};
}

export { BaseStore };
