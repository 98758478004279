import { Button, ButtonSize } from "components/common";
import { observer } from "mobx-react";
import * as React from "react";
import { computed } from "mobx";

interface IOrderingButtonProps {
  atleastOne: boolean;
  isActive: boolean;
  toggle: () => void;
  label: string;
  resetScroll?: () => void;
  buttonSize?: ButtonSize;
  badge?: string | number;
}

@observer
class OrderingButton extends React.Component<IOrderingButtonProps, any> {
  static tagSelectedColor: string = "#D69E6F";
  static tagBaseColor: string = "#FFE4CD";

  handleClick = () => {
    const { resetScroll } = this.props;
    if (resetScroll) {
      resetScroll();
    }
    this.props.toggle();
  };

  @computed get color() {
    const { isActive } = this.props;

    const selectedColor = OrderingButton.tagSelectedColor;
    const baseColor = OrderingButton.tagBaseColor;
    return isActive ? selectedColor : baseColor;
  }

  Badge = () => {
    const { badge } = this.props;
    return (
      <span className="badge" style={{ color: this.color }}>
        {badge}
      </span>
    );
  };

  render() {
    const { atleastOne, isActive, label, buttonSize } = this.props;
    if (!atleastOne) {
      return null;
    }

    const selectedStyle: React.CSSProperties = { background: this.color, textShadow: "none" };
    const baseStyle: React.CSSProperties = { background: this.color };
    const buttonStyle = isActive ? selectedStyle : baseStyle;

    return (
      <Button buttonSize={buttonSize} badge={this.Badge()} handleClick={this.handleClick} style={buttonStyle}>
        {label}
      </Button>
    );
  }
}

export { OrderingButton };
