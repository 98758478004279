import { Button } from "components/common";
import { observer } from "mobx-react";
import * as React from "react";
import * as _ from "lodash";

interface ISetAttributeButtonProps {
  buttonText: string;
  handleClick: (is_followed: boolean) => void;
  is_saving: boolean;
  current_attribute_value: boolean;
  current_attribute_number?: number;
  button_attribute_value: boolean;

  button_attribute_number?: number;

  handleClickSelect?: (select_option: number) => void;

  // button_attribute_values?: {
  //   values: Array<number>;
  //   true_values: Array<number>;
  // };
}

@observer
class SetAttributeButton extends React.Component<ISetAttributeButtonProps> {
  handleButtonClick = () => {
    const { handleClick, button_attribute_value, current_attribute_number, handleClickSelect, button_attribute_number } = this.props;
    if (current_attribute_number) {
      handleClickSelect(button_attribute_number);
    } else {
      handleClick(button_attribute_value);
    }
  };

  render() {
    const {
      buttonText,
      is_saving,
      current_attribute_value,
      button_attribute_value,
      button_attribute_number,
      // button_attribute_values,
      current_attribute_number
    } = this.props;
    let disabled = current_attribute_value == button_attribute_value;

    if (current_attribute_number) {
      disabled = current_attribute_number == button_attribute_number;
    }

    return (
      <Button className="btn btn-primary" handleClick={this.handleButtonClick} disabled={is_saving || disabled}>
        {buttonText}
      </Button>
    );
  }
}

export { SetAttributeButton };
