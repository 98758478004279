import * as numeral from "numeral";

class NumberFormatter {
  public static getPrettyNumberByTenThousand = (value?: number) => {
    if (!value) {
      return 0;
    }
    const category = 10000;
    const rounded_number = Math.round(value / category) * category;
    if (rounded_number < category) {
      return numeral(value).format("0 a");
    } else {
      return numeral(rounded_number).format("0 a");
    }
  };

  public static getNumberWithNoDecimals = (value?: number) => {
    if (!value) {
      return "00";
    }

    const rounded_number = Math.floor(value);
    return numeral(rounded_number).format("00 a");
  };
}

export { NumberFormatter };
