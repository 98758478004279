class PictureManager {
  static baseContentUrl = (static_prefix: string) => {
    return static_prefix + "profiles/content/";
  };
}

const getVideoAddress = (static_prefix: string, screen_name: string, file_name: string) => {
  return PictureManager.baseContentUrl(static_prefix) + "twitter/" + screen_name + "/" + file_name;
};

export { PictureManager, getVideoAddress };
