import "./styles/bootstrap/css/bootstrap.min.css";
import "./styles/bootstrap/css/bootstrap-theme.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.min.js";
import "../node_modules/font-awesome/css/font-awesome.min.css";
import "../node_modules/swiper/dist/css/swiper.min.css";
import "../node_modules/toastr/build/toastr.min.css";
import "../node_modules/spin.js/spin.css";
import "./styles/styles.css";
import "./styles/every-layout.css";

import * as React from "react";
import { render } from "react-dom";
import { BrowserRouter } from "react-router-dom";

import profileApi from "api/profileApi";

import { mainStore } from "store";
import { RouterSwitch } from "router";

const { loginStore } = mainStore;

const is_logged_in = $("[name='is_logged_in']").val();
const is_staff = $("#root").data("is-staff");
if (is_logged_in === "True") {
  loginStore.SetLogin(true, is_staff === "True");
  mainStore.getMiscData();
  loginStore.setToken();
}
const tokenFromInput = $("[name='csrfmiddlewaretoken']").val() as string;
if (tokenFromInput) {
  profileApi.SetToken(tokenFromInput);
}

render(
  // tslint:disable-next-line
  <BrowserRouter>
    <RouterSwitch />
  </BrowserRouter>,
  document.getElementById("root")
);
