import { computed, observable } from "mobx";
import { Profile, ProfilePictureRelation } from "models/Profile";

class ProfileData {
  @observable profile: Profile;

  @observable picture_relations: Array<ProfilePictureRelation>;

  @observable shouldShowRemovedPictures = false;

  constructor(profile: Profile) {
    this.profile = profile;
    this.picture_relations = this.profile.picture_relations;
  }

  @computed get hasPhoneNumber() {
    const phone_number = this.profile.phone_number;
    return Boolean(phone_number) && phone_number.length > 4;
  }

  @computed get pictures() {
    return this.picture_relations.filter(relation => !relation.is_removed).map(relation => relation.picture);
  }

  @computed get removedPictures() {
    return this.picture_relations.filter(relation => relation.is_removed);
  }

  @computed get removedPicturesCount() {
    return this.removedPictures.length;
  }

  @computed get excludedPicturesCount() {
    return this.pictures.filter(picture => picture.is_excluded).length;
  }

  @computed get filteredPictures() {
    if (this.shouldShowRemovedPictures) {
      return this.picture_relations.filter(relation => relation.is_removed).map(relation => relation.picture);
    } else {
      return this.pictures.filter(picture => !picture.is_excluded);
    }
  }

  @computed get hasPictures() {
    return this.filteredPictures.length > 0;
  }
}

export { ProfileData };
