import * as React from "react";
import { Component } from "react";
import { NavLink } from "react-router-dom";

interface IOpenOverviewButtonProps {
  cssClass: string;
  onClick: () => void;
}

class OpenOverviewButton extends Component<IOpenOverviewButtonProps, any> {
  render() {
    return (
      <NavLink to="/overview" className={"search-btn btn-link " + this.props.cssClass} onClick={this.props.onClick}>
        <i className="fa fa-list" />
      </NavLink>
    );
  }
}

export { OpenOverviewButton };
