import { observer } from "mobx-react";
import * as React from "react";
import { ValidatedInput } from "library";

interface ILabeledInputProps {
  validatedInput: ValidatedInput;
  input_type: string;
}

@observer
class LabeledInput extends React.Component<ILabeledInputProps, any> {
  render() {
    const { input_type, validatedInput } = this.props;
    let wrapperClass = "form-group";
    if (validatedInput.error && validatedInput.error.length > 0) {
      wrapperClass += " " + "has-error";
    }

    return (
      <div className={wrapperClass}>
        <label htmlFor={validatedInput.name}>{validatedInput.label}</label>
        <div className="field">
          <input
            type={input_type}
            name={validatedInput.name}
            className="form-control"
            value={validatedInput.value}
            onChange={validatedInput.onChange}
          />
          {validatedInput.error && <div className="alert alert-danger"> {validatedInput.error} </div>}
        </div>
      </div>
    );
  }
}

export default LabeledInput;
