import { observer } from "mobx-react";
import { Component } from "react";
import * as React from "react";
import { mainStore } from "store";

import { MenuItem } from "./MenuItem";

interface INavLinkProps {
  profile_type: string;
  onClick: () => void;
}

@observer
class ListItemNavLink extends Component<INavLinkProps, any> {
  listItem: HTMLLIElement;

  handleClick = () => {
    this.props.onClick();
    $(this.listItem).addClass("active");
    $(window).scrollTop(0);
  };

  render() {
    const { toLink } = mainStore;
    const { profile_type } = this.props;

    const to_link = `${toLink}/${profile_type}/`;
    const isActive = location.pathname.includes(to_link);
    const className = isActive ? "active" : "";
    return (
      <li className={className} ref={element => (this.listItem = element)}>
        <MenuItem onClick={this.handleClick} profile_type={profile_type} to_link={to_link} />
      </li>
    );
  }
}

export default ListItemNavLink;
