import loader from "library/loader";
import { observer } from "mobx-react";
import * as React from "react";
import { Component } from "react";
import { Helmet } from "react-helmet";
import { mainStore } from "store";
import { Route, withRouter, RouteComponentProps } from "react-router";
import { Switch } from "react-router-dom";

import { TopMenu } from "./common/TopMenu";
import HomePage from "./home/HomePage";
import { OverviewPage } from "./overview";
import { routeNames } from "router/routes";
import ProfilePage from "./profiles/ProfilePage";
import { SingleProfilePage } from "./profiles";
import { TwitterPage } from "./profiles/twitter";

@observer
class App extends Component<RouteComponentProps<any>, any> {
  constructor(props: any) {
    super(props);
    loader.setLoader();
  }

  // tslint:disable-next-line:prefer-function-over-method
  componentDidMount() {
    loader.stopPageLoad();
    mainStore.setStaticPrefix();
  }

  // tslint:disable-next-line:prefer-function-over-method
  render() {
    const { metaDescription, faviconHref } = mainStore;

    return (
      <div className="root">
        <Helmet>
          <meta name="description" content={metaDescription} />
          <link rel="shortcut icon" type="image/png" href={faviconHref} />
        </Helmet>
        <TopMenu />

        <Switch>
          <Route path={`/overview`} component={OverviewPage} />
          <Route path={`/twitter/:profile_id`} component={TwitterPage} />
          <Route path={`/twitter`} component={TwitterPage} />
          <Route path={`/${routeNames.danishSingleProfile}/:spider_hash/:profile_hash`} component={SingleProfilePage} />
          <Route path={`/${routeNames.spanishSingleProfile}/:spider_hash/:profile_hash`} component={SingleProfilePage} />
          <Route path={`/${routeNames.danishProfileList}/:spider_hash/search`} component={ProfilePage} />
          <Route path={`/${routeNames.danishProfileList}/:spider_hash/:type`} component={ProfilePage} />
          <Route path={`/${routeNames.danishProfileList}/:spider_hash/`} component={ProfilePage} />
          <Route path={`/${routeNames.spanishProfileList}/:spider_hash/search`} component={ProfilePage} />
          <Route path={`/${routeNames.spanishProfileList}/:spider_hash/:type`} component={ProfilePage} />
          <Route path={`/${routeNames.spanishProfileList}/:spider_hash/`} component={ProfilePage} />

          <Route path={`/`} component={HomePage} />
        </Switch>
      </div>
    );
  }
}

const AppWithRouter = withRouter<RouteComponentProps<any>>(App);
export { AppWithRouter as App };
