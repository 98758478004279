import { PictureManager } from "library/picture";
import { observer } from "mobx-react";
import { Component } from "react";
import * as React from "react";
import { mainStore } from "store";
import { ITwitterProfile } from "models/twitter";

interface ITwitterVideoListThumbnailProps {
  tweet_id: string;
  media_id: string;
  profile?: ITwitterProfile;
}

@observer
class TwitterVideoListThumbnail extends Component<ITwitterVideoListThumbnailProps> {
  divContainer: HTMLDivElement;

  render() {
    const { tweet_id, media_id, profile } = this.props;
    const { twitterStore, static_prefix } = mainStore;
    const { twitter_profile_store } = twitterStore;
    const { active_profile } = twitter_profile_store;

    let profileWithScreenName = active_profile;
    if (profile) {
      profileWithScreenName = profile;
    }

    const thumbnail_id = media_id ? media_id : tweet_id;
    const thumbnail_dir = PictureManager.baseContentUrl(static_prefix) + "twitter/" + profileWithScreenName.screen_name + "/images/";
    const thumbnail_path = `${thumbnail_dir}${thumbnail_id}/video_thumbnail.jpg`;
    return (
      <div
        ref={element => (this.divContainer = element)}
        style={{ paddingLeft: 4, paddingRight: 4, borderRadius: 4, height: 70, backgroundColor: "black", textAlign: "center" }}
      >
        <img src={thumbnail_path} alt="Video thumbnail" />
      </div>
    );
  }
}

export { TwitterVideoListThumbnail };
