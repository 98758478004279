import constants from "constants/constants";
import { observer } from "mobx-react";
import { Status } from "models/Profile";
import { Component } from "react";
import * as React from "react";
import { mainStore } from "store";

import { ProfileMoveButton } from "./ProfileMoveButton";

interface IProfileMoveButtonsProps {
  status: Status;
  cssClass?: string;
}

@observer
class ProfileMoveButtons extends Component<IProfileMoveButtonsProps, any> {
  render() {
    const { status } = this.props;
    const { loginStore } = mainStore;
    const is_staff = loginStore.is_staff;
    const { type } = status;
    const { thai, mature, improper, like, maybe, exclude } = constants.profiles_types;
    const cssClass = this.props.cssClass || "";
    return (
      <div className={"move-buttons " + cssClass}>
        {is_staff && (
          <div className="row extra-functions">
            <ProfileMoveButton buttonText="Thai" profile_type={thai} page_type={type} outerClassName="col-xs-4 column" status={status} />
            <ProfileMoveButton
              buttonText="Modne"
              profile_type={mature}
              page_type={type}
              status={status}
              outerClassName="col-xs-4 text-center column"
            />
            <ProfileMoveButton
              buttonText="Upassende"
              profile_type={improper}
              status={status}
              page_type={type}
              outerClassName="col-xs-4 text-center column"
            />
          </div>
        )}
        <div className="row">
          <ProfileMoveButton
            buttonText="Synes godt om"
            profile_type={like}
            status={status}
            page_type={type}
            outerClassName="col-xs-4 column"
          />
          <ProfileMoveButton
            buttonText="Måske"
            profile_type={maybe}
            status={status}
            page_type={type}
            outerClassName="col-xs-4 text-center column"
          />
          <ProfileMoveButton
            buttonText="Udeluk"
            profile_type={exclude}
            status={status}
            page_type={type}
            outerClassName="col-xs-4 text-center column"
          />
        </div>
      </div>
    );
  }
}

export default ProfileMoveButtons;
