import { Component } from "react";
import * as React from "react";

type ClusterSize = "small" | "default";
type ClusterAlignment = "center" | "default";

interface IClusterProps {
  size?: ClusterSize;
  style?: React.CSSProperties;
  alignment?: ClusterAlignment;
}

class Cluster extends Component<IClusterProps> {
  render() {
    const { size, style, alignment } = this.props;

    let className = "cluster";
    if (size == "small") {
      className = "cluster-small";
    }

    if (alignment == "center") {
      className += " center";
    }

    return (
      <div className={className} style={style}>
        <div>{this.props.children}</div>
      </div>
    );
  }
}

export { Cluster };
