import { DarkGreenBadge } from "components/common";
import { observer } from "mobx-react";
import * as React from "react";
import { Component } from "react";
import { mainStore } from "store";

import { TwitterProfileList } from "./TwitterProfileList";
import { TwitterProfileListFilter } from "./TwitterProfileListFilter";

@observer
class TwitterProfileListOverview extends Component {
  profileList: TwitterProfileList;

  resetScroll = () => {
    $((this.profileList as any).wrappedInstance.listContainer).scrollTop(0);
  };

  // tslint:disable-next-line:prefer-function-over-method
  render() {
    const { twitterStore, misc_data } = mainStore;
    if (!misc_data) {
      return null;
    }

    const { twitter_profile_store } = twitterStore;
    const { active_profile, sortedProfiles } = twitter_profile_store;

    return (
      <div style={{ paddingTop: 15 }}>
        <div style={{ marginBottom: 5 }}>
          <div style={{ marginBottom: 5 }}>
            <span>Profiles</span>
            <DarkGreenBadge label={sortedProfiles.count} />
          </div>
          <TwitterProfileListFilter resetScroll={this.resetScroll} />

          {active_profile && <span style={{ display: "none" }} />}
        </div>
        <TwitterProfileList ref={element => (this.profileList = element)} />
      </div>
    );
  }
}

export { TwitterProfileListOverview };
