import * as React from "react";

interface IServiceTypeProps {
  service: string;
}

class ServiceType extends React.Component<IServiceTypeProps, any> {
  render() {
    const { service } = this.props;
    let serviceText = "";
    if (service === "Massage") {
      serviceText = "Incall";
    } else if (service === "Escort") {
      serviceText = "Outcall";
    } else if (service === "Escort & Massage") {
      serviceText = "Incall og Outcall";
    } else {
      return null;
    }

    return (
      <p>
        <strong>Servicetype: </strong>
        {serviceText}
      </p>
    );
  }
}

export default ServiceType;
