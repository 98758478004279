import { observer } from "mobx-react";
import * as React from "react";
import { Component } from "react";
import { mainStore } from "store";

@observer
class TweetText extends Component {
  // tslint:disable-next-line:prefer-function-over-method
  render() {
    const { twitterStore } = mainStore;
    const { twitter_video_store } = twitterStore;
    const { current_active_video } = twitter_video_store;

    if (!current_active_video) {
      return null;
    }

    const { tweet_text } = current_active_video;

    return (
      <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
        <strong>Tweet:&nbsp;</strong>
        {tweet_text}
      </div>
    );
  }
}

export { TweetText };
