import { observer } from "mobx-react";
import * as React from "react";
import { ProfileData } from "store/ProfileData";

import AvailableDate from "./AvailableDate";
import ProfilePhoneNumberButton from "./ProfilePhoneNumberButton";
import ServiceType from "./ServiceType";
import { IVideo } from "models";
import { Checkbox, Repeller } from "components/common";
import { mainStore } from "store";

interface IProfileDisplayProps {
  profileData: ProfileData;
  videos: Array<IVideo>;
  spider_name: string;
  phoneNumberCountryCode: string;
}

interface IProfileDisplayState {
  isSavingProfileFavorite: boolean;
}

@observer
class ProfileDisplay extends React.Component<IProfileDisplayProps, IProfileDisplayState> {
  constructor(props: IProfileDisplayProps) {
    super(props);
    this.state = { isSavingProfileFavorite: false };
  }

  handleCheckboxClick = () => {
    const { profileData } = this.props;
    const { profileStore } = mainStore;
    const { setIsFavorite } = profileStore.current;
    const { profile } = profileData;
    const { is_favorite, id } = profile;
    this.setState({ isSavingProfileFavorite: true });
    setIsFavorite(id, !is_favorite).finally(() => {
      this.setState({ isSavingProfileFavorite: false });
    });
  };

  render() {
    const { profileData, videos, spider_name, phoneNumberCountryCode } = this.props;
    if (!profileData) {
      return null;
    }

    const has_videos = videos.length > 0;

    const { hasPhoneNumber, profile } = profileData;
    const renderLinks = (about: string) => {
      if (about.startsWith("https://www.eroguide.dk/forum")) {
        return (
          <a href={about} target="_BLANK">
            {about}
          </a>
        );
      } else {
        return about;
      }
    };

    const { is_favorite } = profile;
    const priceArray = profile.prices.trim().split("\n");
    const aboutArray = profile.about.trim().split("\n");
    // tslint:disable-next-line:no-loop-statement
    for (let i = priceArray.length - 1; i >= 0; i--) {
      if (priceArray[i] === "") {
        priceArray.splice(i, 1);
      }
    }

    const hasPrices = priceArray.length > 0;

    let aboutColumnClass = "col-sm-7";
    if (!hasPrices) {
      aboutColumnClass = "col-xs-12 col-sm-8 col-sm-offset-2";
    }
    let panelHeadingStyle: React.CSSProperties = {};
    if (hasPhoneNumber) {
      panelHeadingStyle = { paddingTop: 3, paddingBottom: 3 };
    }

    return (
      <div className="panel panel-default">
        <div className="panel-heading" style={panelHeadingStyle}>
          <Repeller>
            <div>Profilbeskrivelse</div>
            <div>
              <ProfilePhoneNumberButton
                phone_number={profile.phone_number}
                hasPhoneNumber={hasPhoneNumber}
                phoneNumberCountryCode={phoneNumberCountryCode}
              />
            </div>
          </Repeller>
        </div>
        <div className="panel-body">
          <div className="row">
            {hasPrices && (
              <div className="col-sm-5">
                <div className="well well-sm" style={{ background: "#D0E3E3" }}>
                  {priceArray.map((price, index) => (
                    <p key={index}>{price}</p>
                  ))}
                </div>
                <ServiceType service={profile.service} />
                <AvailableDate is_available_date={profile.is_available_date} />
              </div>
            )}
            <div className={"about " + aboutColumnClass}>
              {!hasPrices && <ServiceType service={profile.service} />}
              {!hasPrices && <AvailableDate is_available_date={profile.is_available_date} />}

              {has_videos &&
                videos.map((video, index) => (
                  <video key={index} style={{ width: "100%", maxHeight: 420 }} controls={true}>
                    <source
                      src={`/static/profiles/content/${spider_name}/${profile.profile_id}/videos/${video.file_name}`}
                      type="video/mp4"
                    />
                  </video>
                ))}

              {aboutArray.map((about, index) => (
                <p key={index}>{renderLinks(about)}</p>
              ))}
              <Checkbox
                isSaving={this.state.isSavingProfileFavorite}
                value={is_favorite}
                labelText="Is favorite profile?"
                handleClick={this.handleCheckboxClick}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProfileDisplay;
