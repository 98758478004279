import { observer } from "mobx-react";
import * as React from "react";
import { Component } from "react";
import { mainStore } from "store";
import { Panel, Button } from "components/common";
import { MomentDate } from "library";
import { TwitterVideoIsFavorite } from "./TwitterVideoIsFavorite";
import { TwitterVideoIsExcluded } from "./TwitterVideoIsExcluded";
import { TweetText } from "./TweetText";
import { TwitterVideoIsChecked } from "./TwitterVideoIsChecked";

@observer
class TwitterAllVideosDownloadButton extends Component {
  // tslint:disable-next-line:prefer-function-over-method

  handleClick = () => {
    const { twitterStore } = mainStore;
    const { twitter_video_store } = twitterStore;
    twitter_video_store.setDownloadedToClientDate();
  };
  render() {
    const { twitterStore } = mainStore;
    const { hasVideos } = twitterStore;

    if (!hasVideos) {
      return null;
    }

    return <Button handleClick={this.handleClick}>Download all videos</Button>;
  }
}

export { TwitterAllVideosDownloadButton };
