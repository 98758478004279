import { TwitterApi } from "api";
import { Sorter } from "library/lodash";
import { action, computed, observable, when } from "mobx";
import { ITwitterVideo, ITwitterVideoCounts, DeleteReason, ITwitterProfile } from "models/twitter";

import { mainStore } from "./MainStore";
import { getVideoAddress } from "library/picture";
declare var window;

class TwitterVideoStore {
  @observable public videos: Array<ITwitterVideo>;
  @observable public active_videos: Array<ITwitterVideo>;
  @observable public active_video: ITwitterVideo;
  @observable newVideoFilterIsActive: boolean;
  @observable private videoCounts: ITwitterVideoCounts;
  @observable allVideosIsLoading: boolean;

  @observable videoIsAssFilter: boolean;
  @observable videoIsBreastsFilter: boolean;
  @observable videoIsFrontFilter: boolean;
  @observable videoIsUnratedFilter: boolean;
  @observable videoIsCallGirlFilter: boolean;
  @observable videoProfileIsFavoriteFilter: boolean;

  @observable videoProfileUnfavoriteFirstFilter: boolean;

  @observable videoIsCheckedFilter: boolean;

  @observable videoNotYetCheckedFilter: boolean;

  @observable sortVideoByDuration: boolean;

  @observable public new_active_videos: Array<ITwitterVideo>;
  @observable profile_url_id?: number;

  @observable videoPageIndex: number;
  @observable videoPageSize: number;

  private twitter_api: TwitterApi;

  constructor() {
    this.twitter_api = new TwitterApi();
    this.active_videos = [];
    this.videos = [];
    this.newVideoFilterIsActive = false;
    this.new_active_videos = [];
    this.sortVideoByDuration = false;
    this.videoCounts = undefined;
    this.videoPageSize = 25;
    this.resetVideoPaging();

    this.videoIsAssFilter = false;
    this.videoIsBreastsFilter = false;
    this.videoIsFrontFilter = false;
    this.videoIsCallGirlFilter = false;
    this.videoIsUnratedFilter = false;
    this.videoProfileIsFavoriteFilter = false;
    this.videoProfileUnfavoriteFirstFilter = false;
    this.videoIsCheckedFilter = false;
    this.videoNotYetCheckedFilter = false;
  }

  @action resetVideoPaging = () => {
    this.videoPageIndex = 1;
  };

  @action incrementVideoPaging = () => {
    const current_active_videos = this.current_active_videos;
    if (current_active_videos.count > this.videoPageIndex * this.videoPageSize) {
      this.videoPageIndex += 1;
    }
  };

  getAllVideos = (setProfilesOnVideosFromApi: (videos: Array<ITwitterVideo>) => void) => {
    if (!this.allVideosIsLoading) {
      this.allVideosIsLoading = true;
      this.twitter_api.getAllVideos().then(videos => {
        setProfilesOnVideosFromApi(videos);
        this.videos = videos;
      });
    }
  };

  removeVideo = (video_id: number) => {
    this.active_videos = this.active_videos.filter(video => video.id != video_id);
    if (this.new_active_videos && this.new_active_videos.length > 0) {
      this.new_active_videos = this.new_active_videos.filter(video => video.id != video_id);
    }
    if (this.hasOverviewVideos) {
      this.videos = this.videos.filter(video => video.id != video_id);
    }
  };

  @action resetAllFilters = () => {
    this.newVideoFilterIsActive = false;
    this.videoIsUnratedFilter = false;
    this.videoIsCallGirlFilter = false;
    this.videoProfileIsFavoriteFilter = false;
    this.videoProfileUnfavoriteFirstFilter = false;
    this.videoIsAssFilter = false;
    this.videoIsBreastsFilter = false;
    this.videoIsFrontFilter = false;
    this.videoIsCheckedFilter = false;
    this.videoNotYetCheckedFilter = false;
  };

  @action setDownloadedToClientDate = () => {
    const max_page_size = 999999999999999;
    this.videoPageSize = max_page_size;
    when(() => this.videoPageSize == max_page_size).then(() => {
      const { static_prefix } = mainStore;
      const video_links = this.current_active_videos.items.map(video => {
        const { profile } = video;
        return getVideoAddress(static_prefix, profile.screen_name, video.file_name);
      });
      // tslint:disable-next-line:no-console
      console.log(video_links);

      this.twitter_api.setDownloadedToClientDate(this.current_active_videos.items.map(video => video.id));
    });
  };

  @action resetActiveVideo = () => {
    this.active_video = undefined;
  };

  @action emptyActiveVideos = () => {
    this.active_videos = [];
  };

  @action setVideosFromApi = (videos: Array<ITwitterVideo>) => {
    this.active_videos = videos;
    if (videos.length > 0) {
      this.setActiveVideo(videos[0]);
    }
  };

  @action setActiveVideo = (video: ITwitterVideo) => {
    this.active_video = video;
  };

  @action getVideoById = (video_id: number) => {
    return this.profileVideosOrAllVideos.find(video => video.id == video_id);
  };

  @action setActiveVideoFromId = (video_id: number) => {
    const active_video = this.getVideoById(video_id);
    this.setActiveVideo(active_video);
    return active_video;
  };

  deleteVideoWithReason = (
    video_id: number,
    reason: DeleteReason,
    handleDeleteVideoForProfile: (videoToBeRemoved: ITwitterVideo, profile?: ITwitterProfile) => void
  ) => {
    const activeVideoIndex = this.activeVideoIndex;
    const videoToBeRemoved = this.handleDeleteVideoApi(video_id, activeVideoIndex);

    handleDeleteVideoForProfile(videoToBeRemoved, this.active_video.profile);
    return this.twitter_api.deleteVideoWithReason(video_id, reason);
  };

  @action handleDeleteVideoApi = (video_id: number, activeVideoIndex: number) => {
    const newActiveVideoIndex = Math.max(Math.min(activeVideoIndex + 1, this.current_active_videos.count - 1), 0);
    if (this.current_active_videos.count == 1) {
      this.active_video = undefined;
    } else {
      this.setActiveVideo(this.current_active_videos.items[newActiveVideoIndex]);
    }
    const videoToBeRemoved = this.current_active_videos.items.find(video => video.id == video_id);
    this.removeVideo(video_id);
    return videoToBeRemoved;
  };

  @action IsVideoFavorited = (video_id: number) => {
    const videoToCheck = this.getVideoById(video_id);
    return videoToCheck.favorite_select == mainStore.misc_data.favorite_selects.favorite;
  };

  setVideoIsFavorite = (video_id: number, favorite_select: number) => {
    const update_action = action(() => this.updateVideoIsFavorited(video_id, favorite_select));
    return this.twitter_api.setVideoIsFavorite(video_id, favorite_select).then(update_action);
  };

  setVideoIsExcluded = (video_id: number, is_excluded: boolean) => {
    return this.twitter_api.setVideoIsExcluded(video_id, is_excluded).then(() => this.updateVideoIsExcluded(video_id, is_excluded));
  };

  setVideoIsChecked = (video_id: number, checked_select: number) => {
    return this.twitter_api.setVideoIsChecked(video_id, checked_select).then(() => this.updateVideoIsChecked(video_id, checked_select));
  };

  @action private updateVideoIsChecked = (video_id: number, checked_select: number) => {
    const video = this.getVideoById(video_id);
    video.checked_select = checked_select;
  };

  updateVideoType = (video_id: number, video_type: number) => {
    const video = this.active_videos.find(active_video => active_video.id == video_id);
    if (video) {
      video.video_type = video_type;
    }
    if (this.hasOverviewVideos) {
      const overview_video = this.videos.find(active_video => active_video.id == video_id);
      if (overview_video) {
        overview_video.video_type = video_type;
      }
    }
  };

  @action private updateVideoIsFavorited = (video_id: number, favorite_select: number) => {
    const video = this.getVideoById(video_id);
    video.favorite_select = favorite_select;
  };

  @action private updateVideoIsExcluded = (video_id: number, is_excluded: boolean) => {
    const video = this.getVideoById(video_id);
    video.is_excluded = is_excluded;
  };

  getVideoCounts = () => {
    if (!this.videoCounts) {
      this.twitter_api.getVideoCounts().then(videoCounts => {
        this.videoCounts = videoCounts.counts;
      });
    }
  };

  @action removeAndSetActiveVideo = (video_id: number) => {
    const current_index = this.current_active_videos.items.findIndex(video => video.id == video_id);

    this.active_videos = this.active_videos.filter(x => x.id != video_id);
    if (this.new_active_videos) {
      this.new_active_videos = this.new_active_videos.filter(video => video.id != video_id);
    }

    const new_active_video = this.current_active_videos.items[Math.min(current_index, this.current_active_videos.count - 1)];
    this.setActiveVideo(new_active_video);
    return new_active_video;
  };

  @action removeActiveVideos = () => {
    this.active_videos = [];
    if (this.new_active_videos) {
      this.new_active_videos = [];
    }
  };

  @action setIsWatched = (video_id: number) => {
    const video = this.getVideoById(video_id);
    if (!video.is_watched) {
      video.is_watched = true;
      this.active_video.is_watched = true;
      return this.twitter_api.setIsWatched(video_id).then(() => {
        return true;
      });
    }
    return Promise.resolve(false);
  };

  @action regretVideoDelete = (video_id: number) => {
    return this.twitter_api.regretVideoDelete(video_id);
  };

  @action toggleNewVideoFilter = () => {
    this.newVideoFilterIsActive = !this.newVideoFilterIsActive;
    if (this.newVideoFilterIsActive) {
      this.new_active_videos = this.profileVideosOrAllVideos.filter(video => !video.is_watched);
      this.setActiveVideo(this.new_active_videos[0]);
    } else {
      if (this.active_videos.length > 0) {
        this.setActiveVideo(this.active_videos[0]);
      } else if (this.hasOverviewVideos) {
        this.resetActiveVideo();
      }
    }
  };

  @action toggleDurationVideoFilter = () => {
    this.sortVideoByDuration = !this.sortVideoByDuration;
  };

  resetVideoFilterToggles = () => {
    this.videoIsAssFilter = false;
    this.videoIsBreastsFilter = false;
    this.videoIsFrontFilter = false;
    this.videoIsUnratedFilter = false;
  };

  @action toggleVideoIsFrontFilter = () => {
    this.resetVideoPaging();
    this.resetActiveVideo();

    const newFilterValue = !this.videoIsFrontFilter;
    this.resetVideoFilterToggles();
    this.videoIsFrontFilter = newFilterValue;
  };

  @action toggleVideoIsCallGirlFilter = () => {
    this.resetVideoPaging();
    this.resetActiveVideo();
    this.videoIsCallGirlFilter = !this.videoIsCallGirlFilter;
  };

  @action toggleVideoProfileIsFavoriteFilter = () => {
    this.resetVideoPaging();
    this.resetActiveVideo();
    this.videoProfileIsFavoriteFilter = !this.videoProfileIsFavoriteFilter;
  };

  @action toggleVideoProfileUnfavoriteFirstFilter = () => {
    this.resetVideoPaging();
    this.resetActiveVideo();
    this.videoProfileUnfavoriteFirstFilter = !this.videoProfileUnfavoriteFirstFilter;
  };

  @action toggleVideoIsUnratedFilter = () => {
    this.resetVideoPaging();

    this.videoIsUnratedFilter = !this.videoIsUnratedFilter;
    if (this.videoIsUnratedFilter) {
      this.new_active_videos = this.profileVideosOrAllVideos.filter(
        video => video.favorite_select == mainStore.misc_data.favorite_selects.none && !video.is_excluded
      );
      this.setActiveVideo(this.new_active_videos[0]);
    } else {
      this.resetActiveVideo();
    }
  };

  @action toggleVideoIsAssFilter = () => {
    this.resetVideoPaging();
    this.resetActiveVideo();
    const newFilterValue = !this.videoIsAssFilter;
    this.resetVideoFilterToggles();
    this.videoIsAssFilter = newFilterValue;
  };

  @action toggleVideoIsBreastsFilter = () => {
    this.resetVideoPaging();
    this.resetActiveVideo();
    const newFilterValue = !this.videoIsBreastsFilter;
    this.resetVideoFilterToggles();
    this.videoIsBreastsFilter = newFilterValue;
  };

  @action toggleVideoIsCheckedFilter = () => {
    this.resetVideoPaging();
    this.resetActiveVideo();
    this.videoIsCheckedFilter = !this.videoIsCheckedFilter;
  };

  @action toggleVideoNotYetCheckedFilter = () => {
    this.resetVideoPaging();
    this.resetActiveVideo();
    this.videoNotYetCheckedFilter = !this.videoNotYetCheckedFilter;
  };

  @computed get activeVideoIndex() {
    if (this.current_active_videos.count == 0 || !this.active_video) {
      return 0;
    }
    return this.current_active_videos.items.findIndex(video => video.id == this.active_video.id);
  }

  @computed get current_active_videos() {
    let videos = this.profileVideosOrAllVideos;

    const isNewOrUnrated = this.newVideoFilterIsActive || this.videoIsUnratedFilter;
    if (isNewOrUnrated) {
      videos = this.new_active_videos;
    }

    if (this.videoIsAssFilter) {
      videos = videos.filter(video => video.video_type == mainStore.misc_data.video_types.ass);
    }

    if (this.videoIsBreastsFilter) {
      videos = videos.filter(video => video.video_type == mainStore.misc_data.video_types.breasts);
    }

    if (this.videoIsFrontFilter) {
      videos = videos.filter(video => video.video_type == mainStore.misc_data.video_types.front);
    }

    if ((!isNewOrUnrated && this.videoIsAssFilter) || this.videoIsBreastsFilter || this.videoIsFrontFilter) {
      videos = videos.filter(video => !video.is_excluded);
    }

    if (this.videoIsCallGirlFilter) {
      videos = videos.filter(video => video.profile && video.profile.type == mainStore.misc_data.profile_types.call_girl.id);
    }

    if (this.videoProfileIsFavoriteFilter) {
      videos = videos.filter(video => video.profile && video.profile.is_favorite);
    }

    if (this.videoProfileUnfavoriteFirstFilter) {
      videos = videos.filter(video => video.profile && !video.profile.is_favorite);
    }

    if (this.videoIsCheckedFilter) {
      videos = videos.filter(video => video.checked_select == mainStore.misc_data.checked_selects.checked);
    }

    if (this.videoNotYetCheckedFilter) {
      videos = videos.filter(video => video.checked_select == mainStore.misc_data.checked_selects.none);
    }

    if (this.sortVideoByDuration) {
      videos = Sorter.sort(videos, item => item.duration_miliseconds);
    }

    return { items: videos.slice(0, this.videoPageIndex * this.videoPageSize), count: videos.length };
  }

  @computed get current_active_video() {
    let video = this.active_video;
    if (!this.active_video && !this.hasOverviewVideos) {
      video = this.current_active_videos.items[0];
    }

    return video;
  }

  @computed get hasNewVideos() {
    return this.profileVideosOrAllVideos && this.profileVideosOrAllVideos.some(video => !video.is_watched);
  }

  @computed get hasUnratedVideos() {
    return (
      this.profileVideosOrAllVideos &&
      this.profileVideosOrAllVideos.some(video => video.favorite_select == mainStore.misc_data.favorite_selects.none && !video.is_excluded)
    );
  }

  @computed get hasOverviewVideos() {
    return !Boolean(this.profile_url_id) && this.videos.length > 0;
  }

  @computed get profileVideosOrAllVideos() {
    let videos = this.active_videos;
    if (this.hasOverviewVideos) {
      videos = this.videos;
    }
    return videos;
  }

  getVideoTypeCountFor = (video_type: number) => {
    return this.profileVideosOrAllVideos.filter(video => video.video_type == video_type && !video.is_excluded).length;
  };

  @computed get videoTypeCounts() {
    let videoCounts = this.videoCounts;

    if (!this.hasOverviewVideos && mainStore.misc_data) {
      const { video_types } = mainStore.misc_data;
      videoCounts = {
        ass: this.getVideoTypeCountFor(video_types.ass),
        call_girl: 0,
        breast: this.getVideoTypeCountFor(video_types.breasts),
        front: this.getVideoTypeCountFor(video_types.front)
      };
    }

    return videoCounts;
  }

  @computed get hasVideos() {
    const has_active_videos = this.active_videos && this.active_videos.length > 0;
    return has_active_videos || this.hasOverviewVideos;
  }
}

export { TwitterVideoStore };
