import { DropdownWithAction, ToastrManager } from "components/common";
import { observer } from "mobx-react";
import * as React from "react";
import { mainStore } from "store";
import { ITwitterProfile } from "models/twitter";
import { ProfileIsSuspended } from "./ProfileIsSuspended";
import { MomentDate } from "library";
import { ProfileIsMerged } from "./ProfileIsMerged";
import { Cluster } from "components/common/Cluster";
import { TextOverflowWrapper } from "./TextOverflowWrapper";

interface IProfileMergerComponentProps {
  profile_id: number;
}

@observer
class ProfileMergerComponent extends React.Component<IProfileMergerComponentProps> {
  setProfileOnVideo = (selectedProfile: ITwitterProfile) => {
    const { profile_id } = this.props;
    const { twitterStore } = mainStore;
    const { twitter_video_store, twitter_profile_store } = twitterStore;
    const { removeActiveVideos } = twitter_video_store;
    const { active_profile } = twitter_profile_store;

    this.setState({ is_saving: true });

    return twitterStore.twitter_profile_store.mergeToProfile(profile_id, selectedProfile.id).then(() => {
      const { video_count, watched_video_count } = active_profile;
      active_profile.video_count = 0;
      active_profile.watched_video_count = 0;
      removeActiveVideos();
      selectedProfile.video_count += video_count;
      selectedProfile.watched_video_count += watched_video_count;
      ToastrManager.successRight("Profile was merged");
    });
  };

  render() {
    const { profile_id } = this.props;
    return (
      <DropdownWithAction
        setOnVideoAction={this.setProfileOnVideo}
        templateAttributes={(profile: ITwitterProfile) => {
          const { last_activity, is_suspended, screen_name, is_merged } = profile;
          let last_activity_text = "";
          if (last_activity) {
            last_activity_text = MomentDate.getIsoDate(last_activity);
          }
          return (
            <>
              <TextOverflowWrapper>
                <div
                  style={{
                    marginRight: 5,
                    display: "inline-block",
                    maxWidth: 118
                  }}
                  title={screen_name}
                >
                  {screen_name}
                </div>
              </TextOverflowWrapper>
              <div className="pull-right">
                <Cluster size={"small"}>
                  <ProfileIsSuspended is_suspended={is_suspended} />
                  <ProfileIsMerged is_merged={is_merged} />
                  <span style={{ verticalAlign: "middle", marginLeft: 5 }}>{last_activity_text}</span>
                </Cluster>
              </div>
            </>
          );
        }}
        buttonText={"Merge profile"}
        entity_id={profile_id}
      />
    );
  }
}

export { ProfileMergerComponent };
