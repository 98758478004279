import { Status } from "models/Profile";

import CommonRestApi from "./CommonRestApi";
import { IVideo } from "models";

class ProfileApi extends CommonRestApi {
  constructor() {
    super("profiles");
  }

  getMiscData() {
    return this.getApiUrl("misc_data");
  }

  getCountData() {
    return this.getApiUrl("count_data");
  }

  getById = (profileId: number): Promise<Status> => {
    return this.getApiUrl(profileId.toString());
  };

  getVideos = (profileId: number): Promise<Array<IVideo>> => {
    return this.getSubDetail(profileId, "videos");
  };

  setIsFavorite = (profile_id: number, is_favorite: boolean) => {
    const url = `${this.list_action}${profile_id}/favorite/`;
    return this.postUrl(url, { is_favorite });
  };

}

const profileApi = new ProfileApi();

export default profileApi;
