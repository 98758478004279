import { Spinner, SpinnerOptions } from "spin.js";
import { colors } from "styles";

class Loader {
  target: HTMLElement;
  pageSpinner: Spinner;
  profileOpts: SpinnerOptions;
  baseOptions: SpinnerOptions;
  bottomTarget: HTMLElement;
  bottomSpinner: Spinner;
  profileTarget: HTMLElement;
  profileSpinner: Spinner;

  setLoader = () => {
    const color = colors.DarkGreen;
    const baseOptions = {
      lines: 13,
      length: 0,
      width: 32,
      radius: 70,
      scale: 0.25,
      corners: 1,
      color: color,
      opacity: 0,
      rotate: 40,
      direction: 1,
      speed: 0.6,
      trail: 90,
      fps: 20,
      zIndex: 100,
      className: "spinner",
      top: "-35px",
      left: "50%",
      shadow: false,
      hwaccel: false,
      position: "relative"
    };

    const target = document.getElementById("loader") as any;
    if (target.Spinner) {
      this.pageSpinner = target.Spinner;
    } else {
      const opts = {
        lines: 13,
        length: 0,
        width: 32,
        radius: 70,
        scale: 0.8,
        corners: 1,
        color: color,
        opacity: 0,
        rotate: 40,
        direction: 1,
        speed: 0.6,
        trail: 90,
        fps: 20,
        zIndex: 2e9,
        className: "spinner",
        top: "50%",
        left: "50%",
        shadow: false,
        hwaccel: false,
        position: "fixed"
      };
      this.pageSpinner = new Spinner(opts);
    }

    this.target = target;

    const profileOpts = Object.assign({}, baseOptions);
    profileOpts.top = "-12px";
    profileOpts.left = "35px";
    profileOpts.position = "relative";
    this.profileOpts = Object.assign({}, profileOpts);
    this.baseOptions = baseOptions;
  };

  _stop = (spinner: Spinner) => {
    if (spinner) {
      spinner.stop();
    }
  };

  _start = (spinner: Spinner, target: HTMLElement) => {
    spinner.spin(target);
  };

  stopPageLoad = () => {
    this._stop(this.pageSpinner);
  };

  startPageLoad = () => {
    this._start(this.pageSpinner, this.target);
  };

  startBottomLoad = () => {
    if (!this.bottomTarget) {
      this.bottomTarget = document.getElementById("bottom_loader");
      this.bottomSpinner = new Spinner(this.baseOptions);
    }
    this._start(this.bottomSpinner, this.bottomTarget);
  };

  stopProfileLoad = () => {
    this._stop(this.profileSpinner);
  };

  startProfileLoad = () => {
    this.profileTarget = document.getElementById("profile_loader");
    if (this.profileTarget) {
      const spinner = new Spinner(this.profileOpts);
      this.profileSpinner = spinner;
      this._start(this.profileSpinner, this.profileTarget);
    }
  };
}

const loader = new Loader();

export default loader;
