import { observable, action } from "mobx";

class ModalStore {
  @observable isOpen: boolean;

  constructor() {
    this.isOpen = false;
  }

  @action handleOpenModal = () => {
    this.isOpen = !this.isOpen;
  };

  @action handleCloseModal = () => {
    this.isOpen = false;
  };
}

export { ModalStore };
