import { observer } from "mobx-react";
import * as React from "react";
import { Component } from "react";
import { mainStore } from "store";

import { ButtonAndModal } from "./ButtonAndModal";
import { ProfileTypeButtonsComponent } from "./ProfileTypeButtonsComponent";
import { IProfileTypes } from "models";
import { TextOverflowWrapper } from "./TextOverflowWrapper";

const getProfileTypeText = (type: number, profile_types: IProfileTypes) => {
  let profile_type_text = "";

  if (type == profile_types.call_girl.id) {
    profile_type_text = profile_types.call_girl.name;
  } else if (type == profile_types.porn_star.id) {
    profile_type_text = profile_types.porn_star.name;
  } else if (type == profile_types.promoter.id) {
    profile_type_text = profile_types.promoter.name;
  } else if (type == profile_types.model.id) {
    profile_type_text = profile_types.model.name;
  } else if (type == profile_types.cam_girl.id) {
    profile_type_text = profile_types.cam_girl.name;
  }
  return profile_type_text;
};

@observer
class TwitterProfileType extends Component {
  // tslint:disable-next-line:prefer-function-over-method
  render() {
    const { twitterStore, misc_data } = mainStore;
    const { twitter_profile_store } = twitterStore;
    const { active_profile } = twitter_profile_store;
    if (!active_profile || !misc_data) {
      return null;
    }

    const { profile_types } = misc_data;
    const profile_type_text = getProfileTypeText(active_profile.type, profile_types);

    const { url, location } = active_profile;

    const locationText = location || "-";
    const UrlComponent = () => {
      if (url) {
        return (
          <a href={url} target="_BLANK">
            {url}
          </a>
        );
      }
      return <div>-</div>;
    };
    return (
      <div>
        <div style={{ marginBottom: 10 }}>
          <div style={{ display: "inline-block", marginRight: 20, minWidth: 90 }}>
            <span style={{ marginRight: 10 }}>Type:</span>
            <span>{profile_type_text}</span>
          </div>
          <div style={{ display: "inline-block" }}>
            <ButtonAndModal position="below-center" buttonText="Manage type" modalWidth={150}>
              <ProfileTypeButtonsComponent />
            </ButtonAndModal>
          </div>
        </div>
        <div style={{ marginBottom: 5 }}>
          <div>Location: {locationText}</div>

          <div style={{ display: "flex" }}>
            <div style={{ width: 50 }}>Link:&nbsp;</div>
            <TextOverflowWrapper>{UrlComponent()}</TextOverflowWrapper>
          </div>
        </div>
      </div>
    );
  }
}

export { TwitterProfileType, getProfileTypeText };
