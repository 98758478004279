import { debounce } from "lodash";
import { observer } from "mobx-react";
import { ITwitterProfile, ITwitterVideo } from "models/twitter";
import { Component } from "react";
import * as React from "react";

import { TwitterVideoListItems } from "./TwitterVideoListItems";
import { mainStore } from "store";

interface ITwitterVideoListContainerProps {
  active_profile: ITwitterProfile;
  active_videos: Array<ITwitterVideo>;
}

interface ITwitterVideoListContainerState {
  shouldResetScroll: boolean;
}

const listHeight = 530;

@observer
class TwitterVideoListContainer extends Component<ITwitterVideoListContainerProps, ITwitterVideoListContainerState> {
  listContainerRef: React.RefObject<HTMLDivElement>;

  constructor(props: ITwitterVideoListContainerProps) {
    super(props);

    this.state = {
      shouldResetScroll: false
    };
    this.listContainerRef = React.createRef<HTMLDivElement>();
  }

  componentDidMount() {
    this.listContainerRef.current.addEventListener("scroll", this.debouncedHandleScroll);
  }

  isBottomOfElementInViewport = (element: HTMLElement) => {
    if (!this.listContainerRef.current) {
      return true;
    }
    const rect = element.getBoundingClientRect();
    const top = this.listContainerRef.current.getBoundingClientRect().top;
    const bottom = top + listHeight;

    return rect.bottom >= top && rect.bottom < bottom;
  };

  componentWillUnmount() {
    this.listContainerRef.current.removeEventListener("scroll", this.debouncedHandleScroll);
  }

  handleScroll = () => {
    const height = $(this.listContainerRef.current).height();
    const { scrollHeight, scrollTop } = this.listContainerRef.current;
    const maximumScrollPosition = scrollHeight - height;
    if (Math.max(maximumScrollPosition / 2, maximumScrollPosition - 1000) < scrollTop) {
      mainStore.twitterStore.twitter_video_store.incrementVideoPaging();
    }
  };

  scrollToActiveItem = (topOffset: number) => {
    $(this.listContainerRef.current).scrollTop(topOffset);
  };

  debouncedHandleScroll = debounce(this.handleScroll, 250, { maxWait: 1000 });

  componentDidUpdate(prevProps: ITwitterVideoListContainerProps) {
    const { twitterStore } = mainStore;
    const { twitter_video_store } = twitterStore;
    const { hasOverviewVideos } = twitter_video_store;
    if (
      this.props.active_profile &&
      prevProps.active_profile &&
      this.props.active_profile.id != prevProps.active_profile.id &&
      !hasOverviewVideos
    ) {
      this.setState({ shouldResetScroll: true });
    }
    if (
      this.state.shouldResetScroll &&
      this.props.active_profile &&
      prevProps.active_profile &&
      this.props.active_videos != prevProps.active_videos
    ) {
      this.setState({ shouldResetScroll: false });
      $(this.listContainerRef.current).scrollTop(0);
    }
  }

  render() {
    return (
      <div
        ref={this.listContainerRef}
        className="list-group"
        style={{ overflowY: "auto", height: listHeight, marginBottom: 0, position: "relative", width: "100%" }}
      >
        <TwitterVideoListItems scrollToActiveItem={this.scrollToActiveItem} isButtonInView={this.isBottomOfElementInViewport} />
      </div>
    );
  }
}

export { TwitterVideoListContainer };
