import { Button, DropdownListItem } from "components/common";
import * as _ from "lodash";
import { observer } from "mobx-react";
import { ITwitterProfile } from "models/twitter";
import * as React from "react";
import { mainStore } from "store";

interface IDropdownWithActionProps {
  entity_id: number;
  buttonText: string;
  setOnVideoAction: (selectedProfile: ITwitterProfile) => Promise<void>;
  templateAttributes?: (profile: ITwitterProfile) => React.ReactNode;
}

interface IDropdownWithActionState {
  is_saving: boolean;
  inputText: string;
  selectedOption?: ITwitterProfile;
  profiles: Array<ITwitterProfile>;
}

@observer
class DropdownWithAction extends React.Component<IDropdownWithActionProps, IDropdownWithActionState> {
  inputElement: HTMLInputElement;

  state: IDropdownWithActionState = {
    is_saving: false,
    inputText: "",
    selectedOption: undefined,
    profiles: []
  };

  setAction = () => {
    const { setOnVideoAction } = this.props;
    this.setState({ is_saving: true });

    setOnVideoAction(this.state.selectedOption)
      .then(() => {
        this.setState({ inputText: "", selectedOption: undefined });
      })
      .finally(() => {
        this.setState({ is_saving: false });
      });
  };

  handleChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
    event.preventDefault();
    const newInputText = (event.target as any).value;
    this.setState({ inputText: newInputText });

    this.setDebouncedSelectedProfile(newInputText);
  };

  setSelectedProfile = (newInputText: string, is_exact?: boolean) => {
    const { twitterStore } = mainStore;
    const { twitter_profile_store } = twitterStore;
    const { active_profile } = twitter_profile_store;
    let profiles = [];
    if (is_exact) {
      const profile = twitter_profile_store.allProfiles.find(allProfile => allProfile.screen_name == newInputText);
      profiles = [profile];
    } else {
      profiles = twitter_profile_store.allProfiles.filter(x => x.screen_name.toLowerCase().startsWith(newInputText.toLowerCase()));
    }
    profiles = profiles.filter(profile => profile.id != active_profile.id);
    let newState = {};
    if (profiles.length == 1) {
      const profile = profiles[0];
      if (!this.state.selectedOption || this.state.selectedOption.screen_name != profile.screen_name) {
        newState = Object.assign({}, newState, { selectedOption: profile, inputText: profile.screen_name });
      }
    } else if (profiles.length > 0) {
      newState = Object.assign({}, newState, { selectedOption: undefined });
    }
    if (profiles.length > 1 && profiles.length < 10) {
      newState = Object.assign({}, newState, { profiles: profiles });
    } else {
      newState = Object.assign({}, newState, { profiles: [] });
    }
    this.setState(newState);
  };

  setDebouncedSelectedProfile = _.debounce(this.setSelectedProfile, 250);

  handleOptionClick = (value: string) => {
    this.setSelectedProfile(value, true);
  };

  handleButtonClick = () => {
    this.setAction();
  };

  handleEnterClick = (event: KeyboardEvent) => {
    event.preventDefault();
    const enterKeyCode = 13;
    if (event.which == enterKeyCode) {
      this.setAction();
    }
  };

  componentDidUpdate(prevProps: IDropdownWithActionProps) {
    const oldVideoId = prevProps.entity_id;
    const newVideoId = this.props.entity_id;
    if (oldVideoId != newVideoId) {
      this.setState({ inputText: "", selectedOption: undefined, profiles: [] });
    }
  }

  componentDidMount() {
    this.inputElement.addEventListener("keyup", this.handleEnterClick);
  }

  componentWillUnmount() {
    this.inputElement.removeEventListener("keyup", this.handleEnterClick);
  }

  render() {
    const { is_saving, inputText, selectedOption, profiles } = this.state;
    const { buttonText, templateAttributes } = this.props;

    const hasSelectedOption = Boolean(selectedOption);
    let inputGroupClass = "";
    let buttonClass = "";
    if (hasSelectedOption) {
      inputGroupClass = "has-success";
      buttonClass = "btn-success";
    }
    let dropdownIsOpenClass = "";
    if (profiles && profiles.length > 0) {
      dropdownIsOpenClass = "open";
    }

    const buttonIsDisabled = !hasSelectedOption || is_saving;
    return (
      <div className={`input-group ${inputGroupClass}`}>
        <input
          ref={element => (this.inputElement = element)}
          disabled={is_saving}
          type="text"
          className="form-control"
          value={inputText}
          onChange={this.handleChange}
        />
        <div className={`dropup ${dropdownIsOpenClass}`}>
          <ul className="dropdown-menu" style={{ padding: "2px 0" }}>
            {profiles.map((profile, index) => {
              return (
                <DropdownListItem
                  key={index}
                  templateAttributes={templateAttributes}
                  profile={profile}
                  handleClick={this.handleOptionClick}
                />
              );
            })}
          </ul>
        </div>
        <span className="input-group-btn">
          <Button className={buttonClass} handleClick={this.handleButtonClick} disabled={buttonIsDisabled}>
            {buttonText}
          </Button>
        </span>
      </div>
    );
  }
}

export { DropdownWithAction };
