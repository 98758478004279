import { observer } from "mobx-react";
import { Component } from "react";
import * as React from "react";
import { mainStore } from "store";

import { TwitterVideoListContainer } from "./TwitterVideoListContainer";
import { TwitterVideoListHeader } from "./TwitterVideoListHeader";
import { OrderingButton, Cluster } from "components/common";

@observer
class TwitterVideoListOverview extends Component {
  // tslint:disable-next-line:prefer-function-over-method
  render() {
    const { twitterStore } = mainStore;

    const {
      hasVideos,
      toggleVideoIsAssFilter,
      toggleVideoIsBreastsFilter,
      toggleVideoIsFrontFilter,
      toggleVideoIsCallGirlFilter,
      toggleVideoProfileIsFavoriteFilter,
      toggleVideoProfileUnfavoriteFirstFilter,
      toggleVideoIsCheckedFilter,
      toggleVideoNotYetCheckedFilter,
      twitter_video_store,
      twitter_profile_store
    } = twitterStore;
    const { active_profile } = twitter_profile_store;

    const {
      hasOverviewVideos,
      videoIsAssFilter,
      videoIsBreastsFilter,
      videoIsFrontFilter,
      videoIsCallGirlFilter,
      videoProfileIsFavoriteFilter,
      videoProfileUnfavoriteFirstFilter,
      videoIsCheckedFilter,
      videoNotYetCheckedFilter,
      current_active_videos: active_videos,
      videoTypeCounts
    } = twitter_video_store;

    let divContainerStyle: React.CSSProperties = { paddingTop: 15, display: "none" };
    if (hasVideos) {
      divContainerStyle = Object.assign({}, divContainerStyle, { display: "block" });
    }
    return (
      <div style={divContainerStyle}>
        <TwitterVideoListHeader active_profile={active_profile} />
        {videoTypeCounts && (
          <Cluster style={{ marginBottom: 5 }}>
            <OrderingButton
              atleastOne={true}
              buttonSize="small"
              badge={videoTypeCounts.front}
              toggle={toggleVideoIsFrontFilter}
              isActive={videoIsFrontFilter}
              label={"Front"}
            />
            <OrderingButton
              atleastOne={true}
              buttonSize="small"
              badge={videoTypeCounts.ass}
              toggle={toggleVideoIsAssFilter}
              isActive={videoIsAssFilter}
              label={"Ass"}
            />
            <OrderingButton
              atleastOne={true}
              buttonSize="small"
              badge={videoTypeCounts.breast}
              toggle={toggleVideoIsBreastsFilter}
              isActive={videoIsBreastsFilter}
              label={"Breasts"}
            />
            {hasOverviewVideos && (
              <>
                <OrderingButton
                  atleastOne={true}
                  buttonSize="small"
                  badge={videoTypeCounts.call_girl}
                  toggle={toggleVideoIsCallGirlFilter}
                  isActive={videoIsCallGirlFilter}
                  label={"Call girl"}
                />
                <OrderingButton
                  atleastOne={true}
                  buttonSize="small"
                  toggle={toggleVideoProfileIsFavoriteFilter}
                  isActive={videoProfileIsFavoriteFilter}
                  label={"Favorite profile"}
                />

                <OrderingButton
                  atleastOne={true}
                  buttonSize="small"
                  toggle={toggleVideoProfileUnfavoriteFirstFilter}
                  isActive={videoProfileUnfavoriteFirstFilter}
                  label={"Unfavorite profile"}
                />
                <OrderingButton
                  atleastOne={true}
                  buttonSize="small"
                  toggle={toggleVideoIsCheckedFilter}
                  isActive={videoIsCheckedFilter}
                  label={"Checked video"}
                />

                <OrderingButton
                  atleastOne={true}
                  buttonSize="small"
                  toggle={toggleVideoNotYetCheckedFilter}
                  isActive={videoNotYetCheckedFilter}
                  label={"Not checked video"}
                />
              </>
            )}
          </Cluster>
        )}
        <TwitterVideoListContainer active_videos={active_videos.items} active_profile={active_profile} />
      </div>
    );
  }
}

export { TwitterVideoListOverview };
