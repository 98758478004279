import { Button } from "components/common";
import { observer } from "mobx-react";
import * as React from "react";

interface IIsFollowedButtonProps {
  buttonText: string;
  handleIsFollowedClick: (is_followed: boolean) => void;
  is_saving: boolean;
  currently_is_followed: boolean;
  is_followed: boolean;
}

@observer
class IsFollowedButton extends React.Component<IIsFollowedButtonProps> {
  handleClick = () => {
    const { handleIsFollowedClick, is_followed } = this.props;
    handleIsFollowedClick(is_followed);
  };

  render() {
    const { buttonText, is_saving, currently_is_followed, is_followed } = this.props;
    const disabled = currently_is_followed == is_followed;
    return (
      <Button className="btn btn-primary" handleClick={this.handleClick} disabled={is_saving || disabled}>
        {buttonText}
      </Button>
    );
  }
}

export { IsFollowedButton };
