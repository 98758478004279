import profileApi from "api/profileApi";
import { action, observable, computed } from "mobx";
import { Status } from "models/Profile";

import { BaseStore } from "./BaseStore";
import { ProfileData } from "./ProfileData";
import * as _ from "lodash";

class ProfileBaseStore extends BaseStore<Status> {
  @observable has_video: boolean;
  @observable does_outcall: boolean;
  @observable works_after_midnight: boolean;
  @observable has_whatsapp: boolean;
  @observable is_independent: boolean;
  @observable is_brazilian: boolean;
  @observable does_anal: boolean;
  @observable has_reviews: boolean;
  @observable has_views: boolean;
  @observable is_favorite: boolean;

  @observable sort_by_age: boolean;

  constructor(page_type: string) {
    super(profileApi, page_type);
    this.has_video = false;
    this.does_outcall = false;
    this.works_after_midnight = false;
    this.has_whatsapp = false;
    this.is_brazilian = false;
    this.is_independent = false;
    this.does_anal = false;
    this.has_reviews = false;
    this.has_views = false;
    this.is_favorite = false;
    this.sort_by_age = false;
  }

  @action setExtraItemsData = () => {
    this.items.forEach(item => {
      if (!item.profile.profileData) {
        item.profile.profileData = observable(new ProfileData(item.profile));
      }
    });
  };

  @action resetItems = () => {
    this.items = [];
    this.count = 0;
    this.load_offset = 0;
  };

  @computed
  get statuses() {
    const orderedItems = _.orderBy(
      this.items,
      [
        (item: Status) => item.profile.has_video && this.has_video,
        (item: Status) => item.profile.does_outcall && this.does_outcall,
        (item: Status) => item.profile.works_after_midnight && this.works_after_midnight,
        (item: Status) => item.profile.has_whatsapp && this.has_whatsapp,
        (item: Status) => item.profile.is_brazilian && this.is_brazilian,
        (item: Status) => item.profile.is_independent && this.is_independent,
        (item: Status) => item.profile.does_anal && this.does_anal,
        (item: Status) => Boolean(item.profile.reviews_count) && this.has_reviews,
        (item: Status) => Boolean(item.profile.views_count) && this.has_views,
        (item: Status) => item.profile.is_favorite && this.is_favorite,
        (item: Status) => this.sort_by_age && Boolean(item.profile.age) && item.profile.age
      ],
      ["desc", "desc", "desc", "desc", "desc", "desc", "desc", "desc", "desc", "desc", "desc"]
    );
    return orderedItems.slice(0, this.current_page_offset + this.max_profiles_count);
  }

  @computed
  get atleastOneProfileHasVideo() {
    return this.items.some(x => x.profile.has_video);
  }

  @computed
  get atleastOneProfileDoesOutcall() {
    return this.items.some(x => x.profile.does_outcall);
  }

  @computed
  get atleastOneProfileWorksAfterMidnight() {
    return this.items.some(x => x.profile.works_after_midnight);
  }

  @computed
  get atleastOneProfileWhatsApp() {
    return this.items.some(x => x.profile.has_whatsapp);
  }

  @computed
  get atleastOneProfileBrazilian() {
    return this.items.some(x => x.profile.is_brazilian);
  }

  @computed
  get atleastOneProfileIndependent() {
    return this.items.some(x => x.profile.is_independent);
  }

  @computed
  get atleastOneProfileDoesAnal() {
    return this.items.some(x => Boolean(x.profile.does_anal));
  }

  @computed
  get atleastOneProfileHasReviews() {
    return this.items.some(x => Boolean(x.profile.reviews_count));
  }

  @computed
  get atleastOneProfileHasViews() {
    return this.items.some(x => Boolean(x.profile.views_count));
  }

  @computed
  get atleastOneProfileIsFavorite() {
    return this.items.some(x => x.profile.is_favorite);
  }

  @computed
  get atleastOneProfileHasAge() {
    return this.items.some(x => Boolean(x.profile.age));
  }

  @action setIsFavorite = (profile_id: number, is_favorite: boolean) => {
    return profileApi.setIsFavorite(profile_id, is_favorite).then(() => {
      const status = this.items.find(profile => profile.id == profile_id);
      status.profile.is_favorite = is_favorite;
    });
  };

  @action toggleOrderByHasVideo = () => {
    this.has_video = !this.has_video;
  };

  @action toggleOrderByDoesOutcall = () => {
    this.does_outcall = !this.does_outcall;
  };

  @action toggleOrderByWorksAfterMidnight = () => {
    this.works_after_midnight = !this.works_after_midnight;
  };

  @action toggleOrderByWhatsApp = () => {
    this.has_whatsapp = !this.has_whatsapp;
  };

  @action toggleOrderByBrazilian = () => {
    this.is_brazilian = !this.is_brazilian;
  };

  @action toggleOrderByIndependent = () => {
    this.is_independent = !this.is_independent;
  };

  @action toggleOrderByDoesAnal = () => {
    this.does_anal = !this.does_anal;
  };

  @action toggleOrderByHasReviews = () => {
    this.has_reviews = !this.has_reviews;
  };

  @action toggleOrderByHasViews = () => {
    this.has_views = !this.has_views;
  };

  @action toggleOrderByIsFavorite = () => {
    this.is_favorite = !this.is_favorite;
  };

  @action toggleOrderBySortByAge = () => {
    this.sort_by_age = !this.sort_by_age;
  };
}

export { ProfileBaseStore };
