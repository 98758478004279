import profileApi from "api/profileApi";
import { action, observable } from "mobx";
import { ILoginFormData } from "models";

class LoginStore {
  @observable is_logged_in = false;
  @observable is_staff = false;

  @action
  SetLogin(is_logged_in: boolean, is_staff: boolean) {
    this.is_logged_in = is_logged_in;
    this.is_staff = is_staff;
  }

  @action
  Login(login_data: ILoginFormData) {
    return profileApi
      .postUrl("/login/", login_data)
      .then(response => {
        this.setToken();
        this.SetLogin(true, response.is_staff);
        return true;
      })
      .catch(() => {
        this.SetLogin(false, false);
        return false;
      });
  }

  @action
  Logout() {
    return profileApi
      .postUrl("/logout/")
      .then(() => {
        this.setToken();
        this.SetLogin(false, false);
        return true;
      })
      .catch(() => {
        return false;
      });
  }

  @action
  SignUp(signup_data: ILoginFormData) {
    return profileApi
      .postUrl("/signup/", signup_data)
      .then(() => {
        this.setToken();
        this.SetLogin(true, false);
        return { IsSuccessful: true, message: "" };
      })
      .catch(error => {
        this.SetLogin(false, false);
        return { IsSuccessful: true, message: error.response.data.message };
      });
  }

  @action
  setToken = () => {
    const csrftoken = this.getCookie("csrftoken");
    if (csrftoken) {
      profileApi.SetToken(csrftoken);
    }
  };

  @action
  TryOut() {
    return profileApi
      .postUrl("/tryout/")
      .then(() => {
        this.setToken();
        this.SetLogin(true, false);
        return true;
      })
      .catch(() => {
        this.SetLogin(false, false);
        return false;
      });
  }

  getCookie = (name: string) => {
    let cookieValue = null;
    if (document.cookie && document.cookie !== "") {
      const cookies = document.cookie.split(";");
      // tslint:disable-next-line:no-loop-statement
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        // Does this cookie string begin with the name we want?
        if (cookie.substring(0, name.length + 1) === name + "=") {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  };
}

const loginStore = new LoginStore();

export default loginStore;

export { LoginStore };
