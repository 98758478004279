import { Component } from "react";
import * as React from "react";

type StackSize = "small" | "large" | "default";
type ClusterAlignment = "center" | "default";

interface IStackProps {
  size?: StackSize;
  style?: React.CSSProperties;
}

class Stack extends Component<IStackProps> {
  render() {
    const { size, style } = this.props;

    let className = "stack";
    if (size == "large") {
      className += " stack-large";
    } else if (size == "small") {
      className += " stack-small";
    }

    return (
      <div className={className} style={style}>
        {this.props.children}
      </div>
    );
  }
}

export { Stack };
