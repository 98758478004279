import * as queryString from "query-string";

interface QueryParameter {
  search_term: string;
  profile_hash: string;
}

class QueryParser {
  parse = (search: string) => {
    const parsed = queryString.parse(search) as any;
    return parsed as QueryParameter;
  };
}

const queryParser = new QueryParser();

export { queryParser };
