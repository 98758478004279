import { ToastrManager, Cluster } from "components/common";
import { observer } from "mobx-react";
import * as React from "react";
import { mainStore } from "store";

import { VideoTypeButton } from "./VideoTypeButton";

interface IVideoTypeButtonsComponentProps {
  video_id: number;
  video_type: number;
  setNextVideo: () => void;
  pauseVideo: () => void;
}

@observer
class VideoTypeButtonsComponent extends React.Component<IVideoTypeButtonsComponentProps> {
  setVideoType = (video_type: number) => {
    const { video_id, setNextVideo, pauseVideo } = this.props;
    const { twitterStore } = mainStore;

    pauseVideo();

    setNextVideo();
    twitterStore
      .setVideoType(video_id, video_type)
      .then(() => {
        twitterStore.twitter_video_store.updateVideoType(video_id, video_type);
        ToastrManager.successRight("Video was updated");
      })
      .catch(errorData => {
        let message = errorData.Message;
        if (!errorData.Message) {
          message = errorData.message;
        }
        if (!message) {
          message = errorData;
        }
        ToastrManager.error(message);
      });
  };

  render() {
    const { video_type } = this.props;
    const { misc_data } = mainStore;
    const { video_types } = misc_data;

    const is_saving = false;
    return (
      <Cluster size="small">
        <VideoTypeButton
          is_saving={is_saving}
          video_type={video_type}
          buttonText="Mark as Front"
          set_as_video_type={video_types.front}
          handleVideoTypeClick={this.setVideoType}
        />
        <VideoTypeButton
          is_saving={is_saving}
          video_type={video_type}
          buttonText="Mark as Ass"
          set_as_video_type={video_types.ass}
          handleVideoTypeClick={this.setVideoType}
        />
        <VideoTypeButton
          is_saving={is_saving}
          video_type={video_type}
          buttonText="Mark as Breasts"
          set_as_video_type={video_types.breasts}
          handleVideoTypeClick={this.setVideoType}
        />
        <VideoTypeButton
          is_saving={is_saving}
          video_type={video_type}
          buttonText="Mark as Kissing"
          set_as_video_type={video_types.kissing}
          handleVideoTypeClick={this.setVideoType}
        />
        <VideoTypeButton
          is_saving={is_saving}
          video_type={video_type}
          buttonText="Mark as Pussy"
          set_as_video_type={video_types.pussy}
          handleVideoTypeClick={this.setVideoType}
        />
        <VideoTypeButton
          is_saving={is_saving}
          video_type={video_type}
          buttonText="Mark as Sex"
          set_as_video_type={video_types.sex}
          handleVideoTypeClick={this.setVideoType}
        />
      </Cluster>
    );
  }
}

export { VideoTypeButtonsComponent };
