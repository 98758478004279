import { Button } from "components/common";
import { observer } from "mobx-react";
import * as React from "react";

interface IFavoriteButtonProps {
  buttonText: string;
  handleFavoriteClick: (is_favorite: boolean) => void;
  is_saving: boolean;
  current_profile_favorite: boolean;
  is_favorite: boolean;
}

@observer
class FavoriteButton extends React.Component<IFavoriteButtonProps> {
  handleClick = () => {
    const { handleFavoriteClick, is_favorite } = this.props;
    handleFavoriteClick(is_favorite);
  };

  render() {
    const { buttonText, is_saving, current_profile_favorite, is_favorite } = this.props;
    const disabled = current_profile_favorite == is_favorite;
    return (
      <Button className="btn btn-primary" handleClick={this.handleClick} disabled={is_saving || disabled}>
        {buttonText}
      </Button>
    );
  }
}

export { FavoriteButton };
